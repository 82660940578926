// function to parse ISO date string to JS date format
const parseISODate = (
    TimeISOString: string | null, // ISO date string 
    is_year?: boolean,  // flag to get year within date
    date_range?: boolean // flag to determine ISO string is from date range select
) => {

    // check if ISO string is not null
    if (TimeISOString) {

        // check if ISO string is set to beginning of the time
        if (TimeISOString === "0000-00-00 00:00:00") {
            return '-';
        }

        let parsedTime = new Date(TimeISOString);
        parsedTime = new Date(parsedTime.getTime());


        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        let dateString;

        // function to generate date format
        const generateDateFormate = (itemID: number) => {
            if (itemID) {
                const temID = [...Array((2 - ((itemID).toString().length)))].map((i) => 0);
                return `${temID}${itemID}`;
            }
        }


        if (is_year) { // check if the year flag, then return only day and month
            dateString = parsedTime.getDate() + ' ' + (monthNames[parsedTime.getMonth()]);
        } 
        // check if the date range flag, then generate date format for month and day
        else if (date_range) {
            dateString = (parsedTime.getFullYear() + '-' + generateDateFormate(parsedTime.getMonth() + 1) + '-' + generateDateFormate(parsedTime.getDate()));
        } 
        else {
            dateString = (monthNames[parsedTime.getMonth()]) + ' ' + parsedTime.getDate() + ', ' + parsedTime.getFullYear();
        }

        return dateString;

    }
    else {
        return '-'
    }
}



export default parseISODate;