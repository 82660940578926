import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { getManualAuditReportListService, uploadReportFileService } from "../../Services/ManualReportServices";
import { useDispatch } from "react-redux";
import { SET_MESSAGE } from "../../Actions/Types";

interface PropTypes {
    reportID: number, // report ID
    setReportList: Function, // report list
    limit: number, // limit per page
    offset: number, // record offset
    setTotalRecord: Function, // function to set total records
    filter: string, // filter by string
    ispopup?: boolean, // flag to define if its a popup
    handleHideFileUploadPopupPopup?: Function, // function to hide popup
    addOnID: number // add-on Id
}

// component to render upload report file form for admin user
const ReportFileUploadElement = (prop: PropTypes) => {

    const { reportID, setReportList, limit, offset, setTotalRecord, filter, ispopup, handleHideFileUploadPopupPopup, addOnID } = prop;

    const { accessToken, user, currentWebsite } = useSelector((store: StoreProptypes) => store);

    const { t } = useTranslation();

    const dispatch = useDispatch();


    const [reportFileName, setReportFileName] = useState('');
    const [reportFileValidation, setReportFileValidation] = useState(false);
    const [reportFileValidationMsg, setReportFileValidationMsg] = useState('');


    // function to handle file input change
    const changeHandler = (e: any) => {

        const target = e.target;
        const fileUploaded = target.files;

        // check if files are selected
        if (e.target.files[0] !== undefined) {

            // check if file type is PDF
            if ((e.target.files[0].type === 'application/pdf')) {


                setReportFileName(target.files[0].name);
                const fileSize = parseFloat((((e.target.files[0].size) / 1024) / 1024).toFixed(2));

                // check if file size is more than 20MB
                if ((fileSize > 20)) {
                    setReportFileValidation(true);
                    setReportFileValidationMsg('The maximum allowed file size is 20MB.')
                } else {
                    // check if uploaded files list available 
                    if (fileUploaded) {

                        setReportFileValidation(false);
                        setReportFileValidationMsg('');

                        let reader = new FileReader();
                        reader.readAsDataURL(fileUploaded[0]);

                        reader.onload = (e) => {
                            // check if access token, user data and current website is available
                            if (accessToken && user && currentWebsite) {
                                // service to upload report file
                                uploadReportFileService(accessToken, reportID, user.id, fileUploaded[0], 1, dispatch, t, handleHideFileUploadPopupPopup).then((res) => {
                                    // check if response is okay
                                    if (res.status === 200) {
                                        // service to get report list
                                        getManualAuditReportListService(accessToken, currentWebsite.id, addOnID, dispatch, limit, offset, filter, setTotalRecord, setReportList).then((listRes) => {
                                            // check if response is okay
                                            if (listRes.status === 200) {
                                                // dispatch success message
                                                dispatch({
                                                    type: SET_MESSAGE,
                                                    payload: {
                                                        "toast_message": `${t('File upload successfully!')}`,
                                                        "toast_type": "success"
                                                    }
                                                });
                                            }
                                        })
                                    } else {
                                        // dispatch failure message
                                        dispatch({
                                            type: SET_MESSAGE,
                                            payload: {
                                                "toast_message": `${t('File not upload. Please try again.')}`,
                                                "toast_type": "danger"
                                            }
                                        });
                                    }
                                })
                            }
                        }
                    }
                }

            } else {
                // set validation error message
                setReportFileValidation(true);
                setReportFileValidationMsg('Please upload PDF file.');
                setReportFileName('');
            }
        }

    }

    return (
        <>
            <div className="aioa_dashboard-upload-element">
                <div className={`logo-upload-input ${ispopup ? `` : `m-0`}`}>
                    <Form.Label htmlFor="upload_file" className="btn btn-primary mb-0">{t("Upload File")}</Form.Label>
                    <InputGroup className="input-group-outline visually-hidden-focusable">
                        <Form.Control id="upload_file" accept=".pdf" type="file" aria-describedby="file_input_description" onChange={(e) => changeHandler(e)} className="ps-0" />
                    </InputGroup>
                </div>
                {ispopup && (
                    <>
                        <div className="file-url">{reportFileName}</div>
                    </>
                )}
            </div>
            {reportFileValidation && (
                <div className="text-danger mt-1"><strong>{reportFileValidationMsg}</strong></div>
            )}
        </>
    )
}

export default ReportFileUploadElement;