import { useTranslation } from "react-i18next";
import DashboardPageTitle from "../../Components/InnerPageTitle";
import NormalUserDashboardBlock from "../../Components/NormalUserDashboard";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";

// component to render domain report page
const DomainReportPage = () => {

  // Redux Data
  const { currentWebsite } = useSelector((store: StoreProptypes) => store);

  // Functions
  const { t } = useTranslation();

  return (
    <>
      <DashboardPagesTemplate>
        <DashboardPageTitle
          pageTitle={`${t('Domain Report')}`}
          subPageTitle={`${t('dashboard page sub title', { domainName: currentWebsite?.domain })}`}
        />
        <NormalUserDashboardBlock />
      </DashboardPagesTemplate>
    </>
  )
}

export default DomainReportPage;