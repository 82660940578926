import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";
import { websiteListPropType } from "../../PropTypes/WebsitePropTypes";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import InVerified from '../../Assets/img/Inactive.png';

interface PropTypes {
    item: websiteListPropType, // website object
    srno: number, // serial number
    handleSetCurrentWebsite: Function, // function to set current website
}

// component to render website list with expired plan
const ExpiredPlanWebsiteListTableCellBlock = (props: PropTypes) => {

    // PropTypes
    const { item, srno, handleSetCurrentWebsite } = props;

    // Redux Data
    const { user } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();

    return (
        <div className="aioa_dashboard-table-tr">

            <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Sr No")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Sr No").toString()}>{srno}</div>
            </div>

            <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Domain")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                    <div className="domain-name">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip data-bs-html="true" id="button-tooltip">
                                    <span className="text-start">{t(`Current plan is expired.`)}</span>
                                </Tooltip>
                            }
                        >
                            <div className={`domain-verify-icon`}>
                                <img src={InVerified} alt='' />
                            </div>
                        </OverlayTrigger>
                        {item.domain}
                    </div>
                </div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Plan")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Plan").toString()}>{item.expired_package_detail[0].name}</div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Status")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Status").toString()}><strong className="text-danger">{t('Expired Plan Label')}</strong></div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Price(USD)")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Price(USD)").toString()}>-</div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Renew Date")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Renew Date").toString()}>-</div>
            </div>

            <div className="aioa_dashboard-table-td tcw-100 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Manage Plan")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Manage Plan").toString()}>
                    <Button variant="link" onClick={() => handleSetCurrentWebsite(item.id)}>-</Button>
                </div>
            </div>
            
        </div>
    )
}

export default ExpiredPlanWebsiteListTableCellBlock;