import { LOADING, SET_MESSAGE, UPDATE_USER } from "../../Actions/Types";
import { updateUserProfileApi } from "../../Api/UserAction/UpdateUserDetails";
import { UserPropTypes } from "../../PropTypes/UserPropTypes";

// service to handle user information update
const updateUserService = (
    user: UserPropTypes, // user data
    accessToken: string, // access token
    setLoading: Function, // function to set loading
    dispatch: Function, // dispatch function to store redux data
    t: Function, // i18n t function
    password?: string // password 
) => {

    // set loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // API to update user profile
    updateUserProfileApi(user, accessToken, password).then(response => {

        // check if response data is available
        if (response.Data) {
            // dispatch user data
            dispatch({
                type: UPDATE_USER,
                payload: { user: response.Data },
            });

            // remove loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            // dispatch profile update successfully message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Profile Update Success Msg", { returnObjects: true }),
                    "toast_type": "success"
                }
            });
        }
        else {

            // remove loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            // dispatch update profile failure message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Profile Update failed Msg", { returnObjects: true }),
                    "toast_type": "danger"
                }
            });
        }
    });
}

export { updateUserService }