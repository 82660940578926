import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, InputGroup, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import EditCompanyInformation from "./CompanyInformation";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { SET_MESSAGE } from "../../Actions/Types";
import { updateUserService } from "../../Services/UserAction/UpdateUserDetails";


// component to display edit personal information form
const EditPersonalInformation = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { user, accessToken, activeLanguage } = useSelector((store: StoreProptypes) => store);

    //const [title, setTitle] = useState('');
    const [fullName, setFullName] = useState('');
    //const [website, setWebsite] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [companyWebsiteURL, setCompanyWebsiteURL] = useState('');
    const [companyName, setCompanyName] = useState('');


   // const [message, setMessage] = useState('');
   // const [showAlert, setAlert] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        // check if user data is available
        if (user) {
            user.name && setFullName(user.name);
            user.email && setUserEmail(user.email);
            user.company_name && setCompanyName(user.company_name);
            user.phone && setPhone(user.phone);
            user.website && setCompanyWebsiteURL(user.website);
        }
    }, [user])

    
    // function to handle form submit
    const HandleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        //var httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
        //console.log("validation ==>", httpRegex.test(companyWebsiteURL))
        
        // check if user email is blank
        if (userEmail === '') {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Please enter your email address", { returnObjects: true }),
                    "toast_type": 'danger'
                }
            })
            //return;

        } 
        // check if email is invalid
        else if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(userEmail) === false) {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Please enter valid email address.", { returnObjects: true }),
                    "toast_type": 'danger'
                }
            })
            //return;
        } 
        // check if phone number is blank
        else if (phone === '') {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Please enter your phone number.", { returnObjects: true }),
                    "toast_type": 'danger'
                }
            })
            //return;
        } 
        // check if full name is blank
        else if (fullName === '') {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Please enter your full name.", { returnObjects: true }),
                    "toast_type": 'danger'
                }
            })
            //return;
        } else {
            // check if user and access token is available, then call update user service
            user && accessToken && updateUserService(
                {
                    ...user,
                    name: fullName,
                    phone: phone,
                    email: userEmail,
                    company_name: companyName,
                    website: companyWebsiteURL,
                },
                accessToken,
                setLoading,
                dispatch,
                t,
            )
        }
    }

    return (
        <>
            <Form>
                <div className="card">
                    <EditCompanyInformation
                        companyWebsiteURL={companyWebsiteURL}
                        setCompanyWebsiteURL={setCompanyWebsiteURL}
                        companyName={companyName}
                        setCompanyName={setCompanyName}
                    />
                    <div className="card-header">
                        <h2 className="mb-0 h6">{t("Personal Information")}</h2>
                    </div>
                    <div className="card-body">
                        <Form.Label htmlFor="fullName"> {t("Full Name")} <span className="text-danger" aria-hidden="true">*</span> </Form.Label>
                        <InputGroup className="mb-3 input-group-outline">
                            <Form.Control
                                id="fullName"
                                type="text"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFullName(e.target.value)} 
                                value={fullName}
                                size="lg"
                                required
                            />
                        </InputGroup>
                        <Form.Label htmlFor="email"> {t("E-Mail")} <span className="text-danger" aria-hidden="true">*</span> </Form.Label>
                        <InputGroup className="mb-3 input-group-outline">
                            <Form.Control
                                id="email"
                                type="email"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserEmail(e.target.value)} 
                                value={userEmail}
                                size="lg"
                                required
                            />
                        </InputGroup>
                        <Form.Label htmlFor="phone"> {t("Phone")} <span className="text-danger" aria-hidden="true">*</span> </Form.Label>
                        <InputGroup className="mb-3 input-group-outline phone-number">
                            <PhoneInput
                                country={'us'}
                                onChange={phone => setPhone(phone)}
                                value={phone}
                                inputProps={{id:"phone", required:"required"}}
                            />
                        </InputGroup>
                        <div className="text-center">
                            <Button type="submit" variant="primary" size="lg" className={activeLanguage?.is_rtl ? 'me-auto' : 'ms-auto'} onClick={(e) => HandleSubmit(e)} disabled={loading}>
                                <>
                                    {loading && <Spinner className={activeLanguage?.is_rtl ? 'ms-1' : 'me-1'} animation="grow" variant="light" size="sm" />}
                                    {t("Update Profile")}
                                </>
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    )
}
export default EditPersonalInformation;