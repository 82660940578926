import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { getImageAltTextApi, AllUpdateImageAltTextApi } from "../../Api/ImageAltTextApi";
import { SelectedImageAltListPropTypes } from "../../PropTypes/ImageAltPropTypes";
import { DomainDetailsService } from "../DomainService/DomainDetails";

// service to get all images
const getAllImageAltTextService = (
    accessToken: string, // access token
    websiteID: number, // website ID
    offset: number, // record offset
    limit: number, // record limit
    terms: string, // search term
    filterValue: string, // filter value
    sortByValue: string, // sorting option
    dispatch: Function, // dispatch function to store redux data
    t: Function, // i18next t function
    setImageAltTextList: Function, // function to set image array
) => {

    // Set loading
    dispatch({
        type: LOADING,
        payload: true
    });

    // API to get image list
    getImageAltTextApi(accessToken, offset, limit, websiteID, terms, filterValue, sortByValue).then(response => {
        // check if response data is available
        if (response.Data) {
            setImageAltTextList(response.Data);
        }

        // remove loading
        dispatch({
            type: LOADING,
            payload: false
        });
    })
}

// function to update image alt text
const AllUpdateImageAltTextService = async (
    accessToken: string, // access token
    websiteID: number, // website ID
    dispatch: Function, // dispatch function to store redux data
    t: Function, // i18next t function
    imageAltTextList: SelectedImageAltListPropTypes[],  // array of image object
): Promise<any> => {

    // Set loading
    dispatch({
        type: LOADING,
        payload: true
    });

    // API to update image alt text
    const AllUpdateImageAltTextServiceRes = AllUpdateImageAltTextApi(accessToken, websiteID, imageAltTextList).then(async (response) => {

        // check if response is ok
        if (response.status === 200) {
            //console.log("from image alt text service");

            // service to get domain details
            const DomainDetailsServiceRes = DomainDetailsService(accessToken, websiteID, dispatch).then(async (domainDetailsRes) => {

                // check if response is ok
                if (domainDetailsRes.status === 200) {

                    // remove loading
                    dispatch({
                        type: LOADING,
                        payload: false
                    });

                    // Show success Msg
                    dispatch({
                        type: SET_MESSAGE,
                        payload: {
                            "toast_message": `${response.updated_count ? `Total ${response.updated_count} Records updated.` : ``} ${response.not_updated_count ? `${response.not_updated_count} records not updated.` : ``}`,
                            "toast_type": "success"
                        }
                    });
                }

                return { response, status: 200 };

            });

            return DomainDetailsServiceRes;
        }
    });

    return AllUpdateImageAltTextServiceRes
}



export { AllUpdateImageAltTextService, getAllImageAltTextService }