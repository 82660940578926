import { useTranslation } from "react-i18next";

import "../../Assets/scss/components/manual-accessibility-audit-block.scss";

// component to display manual accessibility banner on dashboard
const ManualAccessibilityAuditBlock = () => {

    const { t } = useTranslation();

    return (
        <div className="aioa_dashboard-manual-audit-block">
            <div className="aioa_dashboard-manual-audit-block-inner">
                <div className="aioa_dashboard-manual-audit-block-title h5">{t('Note Title Text')}</div>
                <div className="aioa_dashboard-manual-audit-block-text">{t('Note Description Text')}</div>
                <div className="aioa_dashboard-manual-audit-block-btn">
                    <a href="https://www.skynettechnologies.com/ada-web-accessibility-quote#accessibility_audit" className="btn btn-primary w-100 btn-lg">{t('Note Button Label')}</a>
                </div>
            </div>
        </div>
    )
}

export default ManualAccessibilityAuditBlock;