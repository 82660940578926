const baseURL = `${process.env.REACT_APP_BASE_URL}/api`;


let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

const getBillingHistoryApi = async (
    token: string,
    limit: number,
    offset: number,
    domainID: number,
    orderID: string,
    startDate: string | null,
    endDate: string | null,
): Promise<any> => {

    const url = `${baseURL}/billing-history`;
    const data = new FormData();

    domainID && domainID !==0 && data.append("website_id", domainID.toString());
    data.append("offset", offset.toString());
    data.append("limit", limit.toString());
    data.append("transaction_id", orderID);
    startDate && startDate !== null  && data.append("startdate", startDate);
    endDate && endDate !== null && data.append("enddate", endDate);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

const sendInvoiceEmailApi = async (
    token: string, 
    OrderId: number,
    userEmail: string
    ): Promise<any> => {

    const url = `${baseURL}/billing-sendmail`;

    const data = new FormData();

    data.append("id", OrderId.toString());
    data.append("email", userEmail);
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}

const invoicePDFApi = async (
    token: string, 
    OrderId: number
    ): Promise<any> => {

    const url = `${baseURL}/billing-invoice/${OrderId}`;
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            }
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}


export { getBillingHistoryApi, sendInvoiceEmailApi, invoicePDFApi };