import { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap';

interface PropsTypes {
    currentPage: number, // current page
    totalRecords: number, // total records
    itemsPerPage: number, // items displaying per page
    setCurrentPage: Function, // function to set current page
    key?: number // key to update pagination component
}


// component to display pagination
const PaginationComponent = (props: PropsTypes) => {

    const { currentPage, totalRecords, itemsPerPage, setCurrentPage, key } = props;

    const totalPages = Math.ceil(totalRecords / itemsPerPage);

    const [midpoint, setMidpoint] = useState<number>(0);

    useEffect(() => {
        setMidpoint(4);
    }, [totalPages])

    const paginationItems = [];


    // function to create pagination item
    const createPaginationItem = (item: number) => {
        return <Pagination.Item key={item} active={item === currentPage} onClick={() => setCurrentPage(item)}>
            {item}
        </Pagination.Item>
    };

    
    useEffect(() => {
        // define mid point for pagination based on current page and total pages
        if (currentPage > 5 && currentPage < totalPages - 2) {
            setMidpoint(currentPage);
        }
        else if (currentPage > 6 && currentPage >= totalPages - 2) {
            setMidpoint(totalPages - 3);
        }
    }, [currentPage, totalPages])

    // function to shift mid point down
    const shiftMidpointDown = () => {
        if (midpoint - 5 <= 3) {
            setMidpoint(4);
        }
        else {
            setMidpoint(midpoint - 5);
        }
    }

    // function to shift mid point up
    const shiftMidpointUp = () => {
        if (midpoint + 5 >= totalPages - 1) {
            setMidpoint(totalPages - 3);
        }
        else {
            setMidpoint(midpoint + 5);
        }
    }

    // check if midpoint is not 0
    if (midpoint !== 0) {
        // check if total pages are less than 10
        if (totalPages < 10) {
            // loop to create pagination items
            for (let item = 1; item <= totalPages; item++) {
                paginationItems.push(
                    createPaginationItem(item)
                );

            }
        }
        else {
            // if total pages are 10 or more, create pagination component with ellipsis
            
            // push first pagination item
            paginationItems.push(createPaginationItem(1));
            
            // push ellipsis pagination item
            paginationItems.push(
                <Pagination.Ellipsis key="shiftPaginationLeft"
                    disabled={midpoint <= 4}
                    onClick={() => shiftMidpointDown()}
                    className={`${midpoint <= 4 ? 'd-none' : ''}`} />);

            // loop and create middle pagination items
            for (let item = midpoint - 2; item <= midpoint + 2; item++) {
                paginationItems.push(createPaginationItem(item));
            }
            
            // push trailing pagination item
            paginationItems.push(
                <Pagination.Ellipsis
                    key="shiftPaginationRight"
                    disabled={midpoint >= totalPages - 3}
                    onClick={() => shiftMidpointUp()}
                    className={`${midpoint >= totalPages - 3 ? 'd-none' : ''}`}
                />);

            // push last pagination item
            paginationItems.push(createPaginationItem(totalPages));
        }
    }

    return (
        <>
            {totalPages > 1 &&
                <Pagination className='justify-content-center align-items-center flex-wrap mb-0'>
                    <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
                    <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
                    {paginationItems}
                    <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages} />
                    <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />

                </Pagination>
            }
        </>
    )
}

export default PaginationComponent
