import DashboardBlankPageHeader from "../Components/Header/DashboardBlankPageHeader";
import WebsitePlanStatusMsg from "../Components/WebsitePlanStatusMsg";
import MainPageTemplate from "./MainPageTemplate";

interface PropTypes {
    children: any
}

// component to display page without user login
const DashboardBlankPagesTemplate = (prop: PropTypes) => {
    // Set Props
    const { children } = prop;

    return (
        <>
            <MainPageTemplate>
                <div className="aioa_dashboard-content-wrapper aioa_dashboard-blank-page-content-wrapper">
                    <DashboardBlankPageHeader />
                    <main id="main">
                        <WebsitePlanStatusMsg />
                        <div className="aioa_dashboard-page-content aioa_dashboard-blank-pages">
                            {children}
                        </div>
                    </main>
                </div>
            </MainPageTemplate>
        </>
    )
}

export default DashboardBlankPagesTemplate;