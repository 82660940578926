const baseURL = `${process.env.REACT_APP_BASE_URL}/api/websites`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

// API to save normal website
const saveNormalWebsiteApi = async (
    accessToken: string, // access token
    websiteURL: string, // website URL
    userID: number, // user ID
    planID: number, // plan ID
): Promise<any> => {

    const url = `${baseURL}/save`;

    const data = new FormData();
    data.append("url", websiteURL);
    data.append("user_id", userID.toString())
    data.append("package_id", planID.toString())

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}

export { saveNormalWebsiteApi }