import { useTranslation } from "react-i18next";

interface PropTypes {
    offset: number, // record offset
    limit: number, // limit per page
    totalRecord: number, // total records
}

// component to display record count
const ShowRecordItem = (prop: PropTypes) => {
    const { t } = useTranslation();
    const { offset, limit, totalRecord } = prop;
    return (
        <>
            <div className="record-count"><strong>{t('record count', { currentItem: (1 + offset), limitItem: ((offset + limit) > totalRecord) ? totalRecord : (offset + limit), totalItem: totalRecord })}</strong></div>
        </>
    )
}

export default ShowRecordItem;