const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}


// API to perform user login
const UserLoginActionApi = async (
    username: string, // username
    password: string // password 
): Promise<any> => {

    const url = `${baseURL}/login`;

    const data = new FormData();

    data.append("email", username);
    data.append("password", password);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

// API to get user details
const getUserDetailsApi = async (
    token: string // access token
): Promise<any> => {

    const url = `${baseURL}/user`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            }
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { UserLoginActionApi, getUserDetailsApi }