import { LOADING, SET_CURRENT_WEBSITE, SET_MESSAGE } from "../../Actions/Types";
import { DomainDetailsApi } from "../../Api/DomainApi/DomainDetailsApi";
import { updateWidgetSettingsApi } from "../../Api/UpdateWidgetSettingsApi";

// service to update widget settings
const updateWidgetSettingsService = (
    token: string, // access token
    websiteID: number, // website id
    dispatch: Function, // function to store redux data
    t: Function, // i18next t function
    changeWidgetIconType: number, // changed icon type
    isChangeWidgetIconCustomSize: boolean, // flag to change widget icon size to custom
    changeWidgetCustomIconSize: number, // changed custom icon size
    isShowCustomWidgetIconSizeMobile: boolean, // flag to show custom widget icon size - for mobile
    changeWidgetIconSizeMobile: number, // changed icon size - for mobile
    isChangeWidgetIconCustomSizeMobile: boolean, //flag to change widget icon size to custom - for mobile
    changeWidgetIconCustomSizeMobile: number, // changed custom icon size
    changeWidgetIconSize: number, // changed widget icon size for desktop
    changeWidgetIconColor: string, // changed widget icon color for desktop
    changeWidgetIconPosition: string, // changed widget icon position
    isChangeWidgetIconCustomPosition: boolean, // flag to use custom icon position
    changeWidgetIconCustomLeftPosition: number | null, // left position
    changeWidgetIconCustomRightPosition: number | null, // right position
    changeWidgetIconCustomTopPosition: number | null, // top position
    changeWidgetIconCustomBottomPosition: number | null, // bottom position
    changeWidgetSize: number, // widget size
    changeWidgetAccessibilityStatementLink: string, // accessibility statement link
    changeGaID: string, // GA Id
    changeAdobeID: string, // adobe Id
    defaultLanguage: string, // default language for widget
    defaultVoice: string, // default screen reader voice
    setShowSuccessModal: Function // function to show success modal

) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // api to update widget settings
    updateWidgetSettingsApi(
        token,
        websiteID,
        changeWidgetIconType,
        isChangeWidgetIconCustomSize,
        changeWidgetCustomIconSize,
        isShowCustomWidgetIconSizeMobile,
        changeWidgetIconSizeMobile,
        isChangeWidgetIconCustomSizeMobile,
        changeWidgetIconCustomSizeMobile,
        changeWidgetIconSize,
        changeWidgetIconColor,
        changeWidgetIconPosition,
        isChangeWidgetIconCustomPosition,
        changeWidgetIconCustomLeftPosition,
        changeWidgetIconCustomRightPosition,
        changeWidgetIconCustomTopPosition,
        changeWidgetIconCustomBottomPosition,
        changeWidgetSize,
        changeWidgetAccessibilityStatementLink,
        changeGaID,
        changeAdobeID,
        defaultLanguage,
        defaultVoice
    ).then((res) => {

        // check if response is ok
        if (res.status === 200) {

            // api to get domain details
            DomainDetailsApi(token, websiteID).then((domainResponse) => {

                // check if response is ok
                if (res.status === 200) {

                    // Set Current Website Data
                    dispatch({
                        type: SET_CURRENT_WEBSITE,
                        payload: domainResponse.Data[0]
                    });

                    // remove Loading
                    dispatch({
                        type: LOADING,
                        payload: false,
                    });

                    
                    
                    setTimeout(() => {
                        setShowSuccessModal(true);
                       /*  dispatch({
                            type: SET_MESSAGE,
                            payload: {
                                "toast_message": t('widget settings update success msg'),
                                "toast_type": "success",
                                "onclose": null
                            }
                        }); */
                    }, 2000)

                }
            })

        } else {

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            // Res Msg
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('widget settings update fail msg'),
                    "toast_type": "danger",
                    "onclose": null
                }
            });

        }

    })
}

export default updateWidgetSettingsService;