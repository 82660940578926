import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import CombinedReducer from "../Reducers/CombinedReducer";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";

const middleware = [thunk];



const initialState = {
    isLoading: false,
    translationLoading: true,
    apiRecall: false,
    toastMessage: {
        toast_message: null,
        toast_type: 'info'
    },
    user: null,
    currentUserStatus: {
        currentUserType: null,
        isInitUser: false
    },
    currentUserDiscount: null,
    activeLanguage: null,
    languageList: null,
    activeLanguageKeywordsList: null,
    accessToken: null,
    websiteList: null,
    currentWebsite: null,
    currentWebsitePlanStatus: {
        isExpired: false,
        isTrial: false,
        isInit: false,
        isFreeWidget: false,
        isCanceledPlan: false,
        isProPlan: false,
        planName: null,
        planExpiredData: null
    },
    currentWebsiteData: {
        accessibilityScore: null,
        violationsRemediatedPercentage: null,
        successViolations: null,
        failedViolations: null,
        imageAltRemediatedPercentage: null,
        successImageAlt: null,
        failedImageAlt: null,
        successViolationsList: null,
        failedViolationsList: null,
    },
    currentWebsitePlanList: null,
    googleVoices: null,
    domainLimitData: { //changed:avani
        currentPackageId: null,
        currentPackageDomainLimit: null,
        currentPackageChildDomainsCount: null,
    }
}

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['isLoading', 'toastMessage', 'translationLoading']
}

const persistedReducer = persistReducer(persistConfig, CombinedReducer)



const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

let persistor = persistStore(store)

export { store, persistor };