import "../../Assets/scss/components/modify-menu.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { Alert, Button, Card, Col, Form, Modal, Placeholder, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { useNavigate } from "react-router";
import { getEnableDisableMenuService, updateEnableDisableMenuService } from "../../Services/EnableDisableServices";
import { LanguagesListPropTypes, MenulistPropTypes, OtherListPropTypes } from "../../PropTypes/EnableDisableServicesPropTypes";
import MenuButtonListMainElement from "../../Components/ModifyAccessibilityMenu/MainMenuButton/MainElement";
import MenuButtonListChildrenElement from "../../Components/ModifyAccessibilityMenu/MainMenuButton/ChildrenElement";
import LanguagesListMainElement from "../../Components/ModifyAccessibilityMenu/LanguageButton/MainElement";
import LanguagesListChildrenElement from "../../Components/ModifyAccessibilityMenu/LanguageButton/ChildrenElement";
import ProfileListMainElement from "../../Components/ModifyAccessibilityMenu/ProfileButton/MainElement";
import ProfileListChildrenElement from "../../Components/ModifyAccessibilityMenu/ProfileButton/ChildrenElement";


interface PropTypes {
    setUpdatedMenuList: Function, // function to set updated menu list
    setUpdatedLanguagesList: Function, // function to set updated languages list
    setUpdatedOtherList: Function, // function to set updated other list
    setUpdatedAccessibilityProfilesList: Function, // function to set updated profile list
}

// component to display modify accessibility menu page
const ModifyAccessibilityMenu = (props: PropTypes) => {

    const { setUpdatedMenuList, setUpdatedLanguagesList, setUpdatedOtherList, setUpdatedAccessibilityProfilesList } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { accessToken, currentWebsite, activeLanguage, languageList, user } = useSelector((store: StoreProptypes) => store);

    const [menuList, setMenuList] = useState<MenulistPropTypes[]>([]);
    const [languagesList, setLanguagesList] = useState<LanguagesListPropTypes[]>([]);
    const [otherList, setOtherList] = useState<OtherListPropTypes[]>([]);
    const [accessibilityProfilesList, setAccessibilityProfilesList] = useState<OtherListPropTypes[]>([]);

    const [loading, setLoading] = useState(false);

    const [languagesListCheckAll, setLanguagesListCheckAll] = useState<boolean>(false);
    const [languagesListCheckAllKey, setLanguagesListCheckAllKey] = useState<number>(0);

    const [accessibilityProfilesListCheckAll, setAccessibilityProfilesListCheckAll] = useState<boolean>(false);
    const [accessibilityProfilesListCheckAllKey, setAccessibilityProfilesListCheckAllKey] = useState<number>(0);

    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

    // function to handle drag end
    const mainMenuButtondragEnded = (param: DropResult) => {

        const { source, destination } = param;

        if (!destination) return;

        let _arr = [...menuList];
        const _item = _arr.splice(source.index, 1)[0];
        destination && _arr.splice(destination.index, 0, _item);
        _arr = _arr.map((item, index) => {
            return { ...item, 'order': index + 1 }
        })
        setMenuList(_arr);
    };

    // function to handle main menu button status
    const handleMainMenuButtonStatus = (
        e: React.ChangeEvent<HTMLInputElement>,
        id: number // menu item ID
    ) => {
        const tempObj = menuList.map((mapItem) => {
            const tempItem = mapItem;
            if (tempItem.id === id) {
                if (e.target.checked) {
                    return {
                        ...tempItem,
                        'status': 1
                    }
                } else {
                    return {
                        ...tempItem,
                        'status': 0
                    }
                }
            } else {
                return tempItem;
            }
        })

        setMenuList(tempObj);
    }


    // function to handle other button status
    const handleOtherButtonStatus = (
        e: React.ChangeEvent<HTMLInputElement>,
        id: number // item ID
    ) => {
        const tempObj = otherList.map((mapItem) => {
            const tempItem = mapItem;
            if (tempItem.id === id) {
                if (e.target.checked) {
                    return {
                        ...tempItem,
                        'status': 1
                    }
                } else {
                    return {
                        ...tempItem,
                        'status': 0
                    }
                }
            } else {
                return tempItem;
            }
        })

        setOtherList(tempObj);
    }

    // function to handle language button drag
    const languagedragEnded = (param: DropResult) => {
        const { source, destination } = param;

        if (!destination) return;

        // if default language is set, Prevent moving items to the first position
        if (currentWebsite?.default_language && destination.index === 0) {
            return;
        }

        let _arr = [...languagesList];
        const _item = _arr.splice(source.index, 1)[0];
        destination && _arr.splice(destination.index, 0, _item);
        _arr = _arr.map((item, index) => {
            return { ...item, 'order': index + 1 }
        })

        setLanguagesList(_arr);
    };

    // function to handle language button status
    const handleLanguageStatus = (
        e: React.ChangeEvent<HTMLInputElement>,
        id: number // item ID
    ) => {
        const tempObj = languagesList.map((mapItem) => {
            const tempItem = mapItem;
            if (tempItem.id === id) {
                if (e.target.checked) {
                    return {
                        ...tempItem,
                        'status': 1
                    }
                } else {
                    return {
                        ...tempItem,
                        'status': 0
                    }
                }
            } else {
                return tempItem;
            }
        })

        setLanguagesList(tempObj);
    }

    // function to handle check/uncheck all for languages
    const handleAllLanguageStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tempObj = languagesList.map((mapItem) => {
            const tempItem = mapItem;
            if (e.target.checked) {
                return {
                    ...tempItem,
                    'status': 1
                }
            } else {
                return {
                    ...tempItem,
                    'status': 0
                }
            }
        })

        setLanguagesList([...tempObj]);
    }


    useEffect(() => {
        // check if language list exists
        if (languagesList) {
            const selectObj = languagesList.filter((filterItem) => filterItem.status === 1);

            // check if any language is disabled
            if (selectObj && selectObj.length > 0) {
                setLanguagesListCheckAll(true);
            } else {
                setLanguagesListCheckAll(false);
            }
            // update key to update rendered list
            setLanguagesListCheckAllKey(languagesListCheckAllKey + 1);
        }
    }, [languagesList])



    useEffect(() => {

        // check if language list is available and default language is set
        if (languagesList.length > 0 && currentWebsite && currentWebsite.default_language) {

            const arr = languagesList; // your array of objects

            const idx = arr.findIndex((obj) => obj.slug === currentWebsite.default_language);


            if (idx !== -1) {
                const [movedObject] = arr.splice(idx, 1);
                arr.unshift(movedObject);
            }

            arr.length && setLanguagesList(arr);
        }

    }, [languagesList, currentWebsite])



    // function to handle drag for accessibility profile buttons
    const accessibilityProfilesDragEnded = (param: DropResult) => {

        const { source, destination } = param;

        if (!destination) return;

        let _arr = [...accessibilityProfilesList];
        const _item = _arr.splice(source.index, 1)[0];
        destination && _arr.splice(destination.index, 0, _item);
        _arr = _arr.map((item, index) => {
            return { ...item, 'order': index + 1 }
        })
        setAccessibilityProfilesList(_arr);
    };

    // function to handle profile button status
    const handleAccessibilityProfilesStatus = (
        e: React.ChangeEvent<HTMLInputElement>,
        id: number // item ID
    ) => {
        const tempObj = accessibilityProfilesList.map((mapItem) => {
            const tempItem = mapItem;
            if (tempItem.id === id) {
                if (e.target.checked) {
                    return {
                        ...tempItem,
                        'status': 1
                    }
                } else {
                    return {
                        ...tempItem,
                        'status': 0
                    }
                }
            } else {
                return tempItem;
            }
        })

        setAccessibilityProfilesList(tempObj);
    }

    // function to handle check/uncheck all for profile buttons
    const handleAllAccessibilityProfilesStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tempObj = accessibilityProfilesList.map((mapItem) => {
            const tempItem = mapItem;
            if (e.target.checked) {
                return {
                    ...tempItem,
                    'status': 1
                }
            } else {
                return {
                    ...tempItem,
                    'status': 0
                }
            }
        })

        setAccessibilityProfilesList([...tempObj]);
    }


    useEffect(() => {
        // check if accessibility profile list is available
        if (accessibilityProfilesList) {
            const selectObj = accessibilityProfilesList.filter((filterItem) => filterItem.status === 1);
            // check if any profile is disabled
            if (selectObj && selectObj.length > 0) {
                setAccessibilityProfilesListCheckAll(true);
            } else {
                setAccessibilityProfilesListCheckAll(false);
            }
            setAccessibilityProfilesListCheckAllKey(accessibilityProfilesListCheckAllKey + 1);
        }
    }, [accessibilityProfilesList])


    // Conform Popup
    const [show, setShow] = useState(false);

    // function to close popup
    const handleClose = () => setShow(false);
    
    // function to show popup
    const handleShow = () => setShow(true);


    // API 
    useEffect(() => {
        // check if access token and current website is available
        if (accessToken && currentWebsite) {
            // service to get main menu, language list, other list, and accessibility profile list
            getEnableDisableMenuService(accessToken, currentWebsite.id, dispatch, t, setMenuList, setLanguagesList, setOtherList, setAccessibilityProfilesList);
        }
    }, [accessToken, currentWebsite])

    // function to submit menu update 
    const handleEnableDisableMenuModification = (
        updateReset: number // 1 = save settings, 2 = reset to default settings
    ) => {
        // check if access token, current website and updateReset flag is available
        if (accessToken && currentWebsite && updateReset) {
            //service to update menu
            updateEnableDisableMenuService(accessToken, currentWebsite.id, updateReset, setLoading, menuList, languagesList, otherList, accessibilityProfilesList, setMenuList, setLanguagesList, setOtherList, setAccessibilityProfilesList, dispatch, t, handleClose);
        }
    }

    // info Popup
    const [showInfoPopup, setShowInfoPopup] = useState(false);
    const [infoPopupType, setInfoPopupType] = useState(0);

    // function to close information popup
    const handleInfoPopupClose = () => {
        setShowInfoPopup(false);
        setInfoPopupType(0);
    }
    // function to show information popup
    const handleInfoPopupShow = (
        type: number // type of information displayed. 1 = Main Menu, 2 = Languages, 3 = Other Options, 4 = Accessibility Profiles
    ) => {
        setInfoPopupType(type);
        setShowInfoPopup(true);
    }


    useEffect(() => {
        // check if menu list, language list, other list and profile list is available
        if (menuList && languagesList && otherList && accessibilityProfilesList) {

            const menuListObj = menuList.filter((menuListItem) => menuListItem.status === 1);

            const languagesListObj = languagesList.filter((languagesListItem) => languagesListItem.status === 1);

            const otherListObj = otherList.filter((otherListItem) => otherListItem.status === 1);

            const profileListObj = accessibilityProfilesList.filter((profileListItem) => profileListItem.status === 1);
            
            // check if all options are disabled
            if (menuListObj && menuListObj.length === 0 && languagesListObj && languagesListObj.length === 0 && otherListObj && otherListObj.length === 0 && profileListObj && profileListObj.length === 0) {
                setShowErrorAlert(true);
            } else {
                setShowErrorAlert(false);
            }
        }
    }, [menuList, languagesList, otherList, accessibilityProfilesList])


    
    useEffect(() => {
        // check if language list is available
        if (languageList && languagesList && languagesList.length > 0) {

            const totalLength = menuList.length + languagesList.length + otherList.length;
            const languagesListLength = languagesList.length;

            let index = 0;
            
            // loop through language list and add missing languages on the language list
            const includeLangObj = languageList.map((langItem) => {

                let mainLangObj = langItem;
                const menuLanguagesObj = languagesList.filter((languagesItemObj) => languagesItemObj.slug === mainLangObj.code);


                if (menuLanguagesObj && menuLanguagesObj.length > 0) {
                } else {
                    languagesList.push(
                        {
                            "id": totalLength + index,
                            "order": languagesListLength + index,
                            "name": `${mainLangObj.name}`,
                            "slug": `${mainLangObj.code}`,
                            "status": 1,
                            "feature_type": 1,
                            "is_free": true
                        }
                    )
                    index = index + 1;
                }
            })
        }
    }, [languagesList])

    

    // Set Value 
    useEffect(() => {
        // check if menu list is available
        if (menuList && menuList.length > 0) {
            setUpdatedMenuList(menuList);
        } else {
            setUpdatedMenuList([])
        }
    }, [menuList])

    useEffect(() => {
        // check if language list is available
        if (languagesList && languagesList.length > 0) {
            setUpdatedLanguagesList(languagesList);
        } else {
            setUpdatedLanguagesList([]);
        }
    }, [languagesList])

    useEffect(() => {
        // check if other list is available
        if (otherList && otherList.length > 0) {
            setUpdatedOtherList(otherList);
        } else {
            setUpdatedOtherList([]);
        }
    }, [otherList])

    useEffect(() => {
        // check if profile list is available
        if (accessibilityProfilesList && accessibilityProfilesList.length > 0) {
            setUpdatedAccessibilityProfilesList(accessibilityProfilesList);
        } else {
            setUpdatedAccessibilityProfilesList([]);
        }
    }, [accessibilityProfilesList])

    return (
        <>
            {!loading && showErrorAlert && (
                <Alert className="text-white mt-4" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                    {t('Modify Accessibility Menu Msg')}
                </Alert>
            )}

            <Row xs={1} lg={2} className="mt-4">
                <Col className="mb-4">
                    <Card className="h-100 border">
                        <Card.Header className="border-bottom">
                            <div className="title-with-info-btn d-flex align-items-center">
                                <h2 className="mb-0 h5 me-2">{t('Main Menu Buttons')}</h2>
                                <i className={`material-symbols-outlined`} role="button" aria-label={t("Learn about Other Options").toString()} tabIndex={0} onClick={() => handleInfoPopupShow(1)}>info</i>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <DragDropContext onDragEnd={mainMenuButtondragEnded}>
                                <Droppable droppableId="comments-wrapper">
                                    {(provided, snapshot) => (
                                        <MenuButtonListMainElement length={menuList && menuList.length} refMain={provided.innerRef} {...provided.droppableProps}>
                                            {!loading && menuList ? (
                                                <>
                                                    {menuList.length > 0 ? (
                                                        <>
                                                            {menuList.map((menuItem, index) => {
                                                                return (
                                                                    <Draggable
                                                                        draggableId={`main-menu-button-${menuItem.id}`}
                                                                        index={index}
                                                                        key={`main-menu-button-${menuItem.id}`}
                                                                    >
                                                                        {(_provided, _snapshot) => (
                                                                            <MenuButtonListChildrenElement
                                                                                item={menuItem}
                                                                                childrenRef={_provided.innerRef}
                                                                                dragHandleProps={_provided.dragHandleProps}
                                                                                otherProp={{ ..._provided.draggableProps }}
                                                                                snapshot={_snapshot}
                                                                                handleMainMenuButtonStatus={handleMainMenuButtonStatus}
                                                                            />
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            })}
                                                        </>
                                                    ) : (
                                                        <>

                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {[...Array(10)].map((index) => {
                                                        return (
                                                            <div className={`menu-box border`} key={index + 1}>
                                                                <Placeholder as="div" animation="glow">
                                                                    <div className="drag-icon">
                                                                        <Placeholder as="div" />
                                                                    </div>
                                                                    <div className="menu-name h6 mb-0 ms-2 me-2">
                                                                        <Placeholder as="div" />
                                                                    </div>
                                                                    <div className="action-part">
                                                                        <Placeholder as="div" />
                                                                    </div>
                                                                </Placeholder>
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </MenuButtonListMainElement>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className="mb-4">
                    <Card className="h-100 border">
                        <Card.Header className="border-bottom languages-title d-flex align-items-center justify-content-between">
                            <div className="title-with-info-btn d-flex align-items-center">
                                <h2 className="mb-0 h5 me-2">{t('Languages')}</h2>
                                <i className={`material-symbols-outlined`} role="button" aria-label={t("Learn about Other Options").toString()} tabIndex={0} onClick={() => handleInfoPopupShow(2)}>info</i>
                            </div>

                            <Form.Check
                                type="switch"
                                id={`all-languages`}
                                area-label={`Toggle all Languages`}
                                onChange={(e) => handleAllLanguageStatus(e)}
                                defaultChecked={languagesListCheckAll ? true : false}
                                key={languagesListCheckAllKey}
                            />
                        </Card.Header>
                        <Card.Body>

                            <DragDropContext onDragEnd={languagedragEnded}>
                                <Droppable droppableId="comments-wrapper">
                                    {(provided, snapshot) => (
                                        <LanguagesListMainElement length={languagesList && languagesList.length} refMain={provided.innerRef} {...provided.droppableProps}>
                                            {!loading && languagesList && currentWebsite ? (
                                                <>
                                                    {languagesList.length > 0 ? (
                                                        <>
                                                            {languagesList.map((mlanguagesItem, index) => {
                                                                return (
                                                                    <Draggable
                                                                        draggableId={`languages-button-${mlanguagesItem.id}`}
                                                                        index={index}
                                                                        key={`languages-button-${mlanguagesItem.id}${Math.random()}`}
                                                                        isDragDisabled={mlanguagesItem.slug === currentWebsite.default_language}
                                                                    >
                                                                        {(_provided, _snapshot) => (
                                                                            <LanguagesListChildrenElement
                                                                                item={mlanguagesItem}
                                                                                childrenRef={_provided.innerRef}
                                                                                dragHandleProps={_provided.dragHandleProps}
                                                                                otherProp={{ ..._provided.draggableProps }}
                                                                                snapshot={_snapshot}
                                                                                handleLanguageStatus={handleLanguageStatus}
                                                                                key={mlanguagesItem.status}
                                                                                isDisabled={mlanguagesItem.slug === currentWebsite.default_language}
                                                                            />
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            })}
                                                        </>
                                                    ) : (
                                                        <>

                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {[...Array(10)].map((index) => {
                                                        return (
                                                            <div className={`menu-box border`} key={index + 1}>
                                                                <Placeholder as="div" animation="glow">
                                                                    <div className="drag-icon">
                                                                        <Placeholder as="div" />
                                                                    </div>
                                                                    <div className="menu-name h6 mb-0 ms-2 me-2">
                                                                        <Placeholder as="div" />
                                                                    </div>
                                                                    <div className="action-part">
                                                                        <Placeholder as="div" />
                                                                    </div>
                                                                </Placeholder>
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </LanguagesListMainElement>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className="mb-4">
                    <Card className="h-100 border">
                        <Card.Header className="border-bottom languages-title d-flex align-items-center justify-content-between">
                            <div className="title-with-info-btn d-flex align-items-center">
                                <h2 className="mb-0 h5 me-2">{t('Accessibility Profiles')}</h2>
                                <i className={`material-symbols-outlined`} role="button" aria-label={t("Learn about accessibility profiles Options").toString()} tabIndex={0} onClick={() => handleInfoPopupShow(4)}>info</i>
                            </div>

                            <Form.Check
                                type="switch"
                                id={`all-accessibility-profiles`}
                                area-label={`Toggle all accessibility profiles`}
                                onChange={(e) => handleAllAccessibilityProfilesStatus(e)}
                                defaultChecked={accessibilityProfilesListCheckAll ? true : false}
                                key={accessibilityProfilesListCheckAllKey}
                            />
                        </Card.Header>
                        <Card.Body>

                            <DragDropContext onDragEnd={accessibilityProfilesDragEnded}>
                                <Droppable droppableId="comments-wrapper">
                                    {(provided, snapshot) => (
                                        <ProfileListMainElement length={accessibilityProfilesList && accessibilityProfilesList.length} refMain={provided.innerRef} {...provided.droppableProps}>
                                            {!loading && accessibilityProfilesList ? (
                                                <>
                                                    {accessibilityProfilesList.length > 0 ? (
                                                        <>
                                                            {accessibilityProfilesList.map((ProfileItem, index) => {
                                                                return (
                                                                    <Draggable
                                                                        draggableId={`profile-menu-button-${ProfileItem.id}`}
                                                                        index={index}
                                                                        key={`profile-menu-button-${ProfileItem.id}`}
                                                                    >
                                                                        {(_provided, _snapshot) => (
                                                                            <ProfileListChildrenElement
                                                                                item={ProfileItem}
                                                                                childrenRef={_provided.innerRef}
                                                                                dragHandleProps={_provided.dragHandleProps}
                                                                                otherProp={{ ..._provided.draggableProps }}
                                                                                snapshot={_snapshot}
                                                                                handleProfilesStatus={handleAccessibilityProfilesStatus}
                                                                                key={ProfileItem.status}
                                                                            />
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            })}
                                                        </>
                                                    ) : (
                                                        <>

                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {[...Array(10)].map((index) => {
                                                        return (
                                                            <div className={`menu-box border`} key={index + 1}>
                                                                <Placeholder as="div" animation="glow">
                                                                    <div className="drag-icon">
                                                                        <Placeholder as="div" />
                                                                    </div>
                                                                    <div className="menu-name h6 mb-0 ms-2 me-2">
                                                                        <Placeholder as="div" />
                                                                    </div>
                                                                    <div className="action-part">
                                                                        <Placeholder as="div" />
                                                                    </div>
                                                                </Placeholder>
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </ProfileListMainElement>
                                    )}
                                </Droppable>
                            </DragDropContext>

                        </Card.Body>
                    </Card>
                </Col>

                <Col className="mb-4">
                    <Card className="h-100 border">
                        <Card.Header className="border-bottom">
                            <div className="title-with-info-btn d-flex align-items-center">
                                <h2 className="mb-0 h5 me-2">{t('Other Options')}</h2>
                                <i className={`material-symbols-outlined`} role="button" aria-label={t("Learn about Other Options").toString()} tabIndex={0} onClick={() => handleInfoPopupShow(3)}>info</i>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div className={`menu-list ${otherList && otherList.length > 10 ? 'scroll' : ``}`} >
                                {!loading && otherList ? (
                                    <>
                                        {otherList.length > 0 ? (
                                            <>
                                                {otherList.map((otherItem, index) => {
                                                    return (
                                                        <div className={`menu-box border ${otherItem.slug === 'report_problem' && currentWebsite && currentWebsite.white_label_enabled ? `d-none` : ``}`} key={index}>
                                                            <div className="drag-icon">
                                                                <i className="material-symbols-outlined" aria-hidden="true">
                                                                    {
                                                                        otherItem.slug === 'reset' ? `autorenew` :
                                                                            otherItem.slug === 'accessibility_statement' ? `description` :
                                                                                otherItem.slug === 'hide_interface' ? `visibility_off` :
                                                                                    otherItem.slug === 'oversize_widget_toggle' ? `open_in_full` :
                                                                                        otherItem.slug === 'accessibility_profiles' ? `accessibility` :
                                                                                            otherItem.slug === 'move_widget' ? `open_with` :
                                                                                                otherItem.slug === 'report_problem' ? 'flag' : 'settings'
                                                                    }
                                                                </i>
                                                            </div>
                                                            <div className="menu-name h6 mb-0 ms-2 me-2">{otherItem.name}</div>
                                                            <div className="action-part">
                                                                <Form.Check
                                                                    className="form-check m-0 p-0 d-flex align-items-center"
                                                                    type="checkbox"
                                                                    id={`accessibility-menu-${otherItem.id}`}
                                                                    area-label={`accessibility`}
                                                                    onChange={(e) => handleOtherButtonStatus(e, otherItem.id)}
                                                                    defaultChecked={otherItem.status === 1 ? true : false}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                        ) : (
                                            <>

                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {[...Array(10)].map((index) => {
                                            return (
                                                <div className={`menu-box border`} key={index + 1}>
                                                    <Placeholder as="div" animation="glow">
                                                        <div className="drag-icon">
                                                            <Placeholder as="div" />
                                                        </div>
                                                        <div className="menu-name h6 mb-0 ms-2 me-2">
                                                            <Placeholder as="div" />
                                                        </div>
                                                        <div className="action-part">
                                                            <Placeholder as="div" />
                                                        </div>
                                                    </Placeholder>
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
            <div className="text-center position-sticky bottom-0 bg-white py-3" style={{ zIndex: 9 }}>
                <Button variant="danger" className={`mb-0 ${activeLanguage?.is_rtl ? `ms-3` : `me-3`}`} role="button" onClick={handleShow}>{t("Reset to Default Button Label")}</Button>
                <Button className="mb-0" disabled={loading} onClick={() => handleEnableDisableMenuModification(1)}>{t('Save Settings Button Label')}</Button>
                {/* <Button variant="secondary" size="lg" className={activeLanguage?.is_rtl ? 'mb-0 me-3 widget-preview-btn' : 'mb-0 ms-3 widget-preview-btn'} onClick={widgetPreviewPopupShow}>Widget Preview</Button> */}
            </div>


            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Reset to Default Button Label')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('Reset to Default Popup Text')}</p>
                    <p className="text-danger"><strong>{t('Reset to Default Popup bold Text')}</strong></p>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button className="mb-0" variant="primary" onClick={handleClose}>{t('Cancel')}</Button>
                    <Button className="mb-0" variant="danger" onClick={() => handleEnableDisableMenuModification(2)} disabled={loading}>{t('Reset to Default Button Label')}</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showInfoPopup} onHide={handleInfoPopupClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {infoPopupType && infoPopupType > 0 && (
                            <>
                                {infoPopupType === 1 ? (
                                    <>
                                        {t('Main Menu Buttons')}
                                    </>
                                ) : infoPopupType === 2 ? (
                                    <>
                                        {t('Languages')}
                                    </>
                                ) : infoPopupType === 4 ? (
                                    <>
                                        {t('Accessibility Profiles')}
                                    </>
                                ) : (
                                    <>
                                        {t('Other Options')}
                                    </>
                                )}
                            </>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {infoPopupType === 1 ? (
                        <>
                            {t('Main Menu Buttons Popup Text')}
                        </>
                    ) : infoPopupType === 2 ? (
                        <>
                            {t('Languages Popup Text')}
                        </>
                    ) : infoPopupType === 4 ? (
                        <>
                            Add, remove, or reorder the accessibility profiles here. It is recommended to keep all accessibility profiles so that users can quickly enhance the website experience as per their needs. Please “Save Settings” from the bottom of the screen to save your changes.
                        </>
                    ) : (
                        <>
                            {t('Other Options Popup Text')}
                        </>
                    )}
                </Modal.Body>
            </Modal>

        </>
    )
}

export default ModifyAccessibilityMenu;