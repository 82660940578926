import { useTranslation } from "react-i18next";
import { UserOrderItemPropTypes } from "../../PropTypes/UserOrderDataPropTypes";
import parseISODate from "../../Helpers/parseISODate";
import { Button } from "react-bootstrap";
import OrderListCellActionButtons from "./OrderListCellActionButtons";
import PaymentButtonBlock from "../PaymentButton";

interface PropTypes {
    item: UserOrderItemPropTypes, // order object
    index: number, // index
    totalOrderItems: number // total orders
}

// component to render order for scanner plan
const ScannerPlanOrderCellBlock = (props: PropTypes) => {
    
    // PropTypes
    const { item, index, totalOrderItems } = props;

    // Functions
    const { t } = useTranslation();

    return (
        <>
            <div className="aioa_dashboard-table-tr">

                <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Orders #")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Orders #").toString()}>
                        {[...Array((5 - (item.id).toString().length))].map((i) => 0)}{item.id}
                    </div>
                </div>

                <div className="aioa_dashboard-table-td aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Description")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Description").toString()}>
                        <div className="aioa_dashboard-order-plan-details">
                            <strong>Scanner {t('Plan Table Label')}</strong> : {item.name}
                            {(item.subscr_interval === 'M' ? (
                                <>
                                    &nbsp;<strong className="text-primary">({t("Monthly Plan Type")})</strong>
                                </>
                            ) : (
                                <>
                                    &nbsp;<strong className="text-primary">({t("Annually Plan Type")})</strong>
                                </>
                            ))}
                            {item.is_trial_period === 1 && (<>&nbsp;- <strong className="text-danger">{t("Trial Plan Label")}</strong> </>)}
                        </div>
                        <div className="aioa_dashboard-order-domain">
                            <strong>{t('Domain')}</strong> : {item.domain}
                        </div>
                    </div>
                </div>

                <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Orders Date")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Orders Date").toString()}>
                        {item.start_date ? parseISODate(item.start_date) : '-'}
                    </div>
                </div>

                <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Amount (USD)")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Amount (USD)").toString()}>
                        {item.platforms === "free-scanner" ? (
                            <>
                                FREE
                            </>
                        ) : (item.is_trial_period === 1) ? (
                            <>
                                $0.00
                            </>
                        ) : (
                            <>
                                ${((item.final_price)).toFixed(2)}
                            </>
                        )}
                    </div>
                </div>

                <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Status")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Status").toString()}>
                        {item.platforms === "free-scanner" ? (
                            <>
                                {item.payment_status !== 'Canceled' ? (
                                    <>
                                        <div className='td-cell-value' aria-label={t("Status").toString()}>{t('N/A')}</div>
                                    </>
                                ) : (
                                    <>
                                        <div className='td-cell-value text-danger' aria-label={t("Status").toString()}>
                                            <strong>{t('canceled label')}</strong>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (item.is_trial_period === 1) ? (
                            <>
                                {item.payment_status !== 'Canceled' ? (
                                    <>
                                        <div className='td-cell-value' aria-label={t("Status").toString()}>{t('N/A')}</div>
                                    </>
                                ) : (
                                    <>
                                        <div className='td-cell-value text-danger' aria-label={t("Status").toString()}>
                                            <strong>{t('canceled label')}</strong>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {(item.payment_status === "Failed") ? (
                                    <>
                                        <Button className="aioa_dashboard-icon-btn">
                                            <div className="aioa_dashboard-btn-icon"><i className={`material-symbols-outlined`}>info</i></div>
                                            <div className="aioa_dashboard-btn-text">{t("Payment Failed")}</div>
                                        </Button>
                                    </>
                                ) : (item.payment_status === "Canceled") ? (
                                    <>
                                        <div className='text-success'>
                                            <strong>{t("Paid Payment Status")}</strong>&nbsp;
                                            <span className='text-danger'>({t('canceled label')})</span>
                                        </div>
                                        <div className="text-danger"><strong>{item.cancel_date ? parseISODate(item.cancel_date) : '-'}</strong></div>
                                    </>
                                ) : (
                                    <>
                                        <div className='text-success'><strong>{t("Paid Payment Status")}</strong></div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>

                <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                    <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Action")}</div>
                    <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Action").toString()}>
                        {item.platforms === "free-scanner" ? (
                            <>
                                -
                            </>
                        ) : (item.is_trial_period === 1) ? (
                            <>
                                {item.payment_status !== 'Canceled' && (item.is_pre_trial !== 1) ? (
                                    <>
                                        <PaymentButtonBlock
                                            buttonType={2}
                                            activePlanType={1}
                                            activePlanDomain={item.domain}
                                            activePlanInterval={item.subscr_interval}
                                            activePlanPrice={item.final_price}

                                            websiteID={item.website_id}
                                            activeUserID={item.user_id}
                                            activePlanID={item.package_id}
                                            buttonBigSize={false}
                                        />
                                    </>
                                ) : (
                                    <>
                                        -
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <OrderListCellActionButtons
                                    orderItemIndex={index}
                                    orderItemID={item.id}
                                    paymentStatus={`${item.payment_status}`}
                                    purchaseURL={`${item.purchase_url}`}
                                    totalOrderItems={totalOrderItems}
                                />
                            </>
                        )}
                    </div>
                </div>

            </div>
        </>
    )
}

export default ScannerPlanOrderCellBlock;