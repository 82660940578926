import { useTranslation } from "react-i18next";
import { SelectedImageAltListPropTypes } from "../../PropTypes/ImageAltPropTypes";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { addDefaultImgSrc } from "../../Helpers/addDefaultImgSrc";

import iconAI from "../../Assets/img/icon-ai.svg";
import useWindowSize from "../../Helpers/useWindowSize";

interface PropTypes {
    item: SelectedImageAltListPropTypes, // image object
    rowClass: string, // class name for row
    individualCheck: Function, // function to handle individual item selection
    showFreeWidgetStrip: boolean, // flag to display free widget overlay
    handleImagePopupShow: Function, // function to handle image popup
    decorativeImageSwitch: Function, // function to handle decorative image switch
    filterValue: string, // filter value
    HandleCopyText: Function, // function to handle text copy
    handleSelectImgObj: Function, // function to handle selected image array
    handleSelectImgObjOnFocus: Function, // function to update selected image array on focus
    handleSelectImgObjOnBlur: Function, // function to update selected image array on blur
    debouncedUpdateAltTextHandler: Function // function to update image alt text
}

// component to render table cell for image alt text remediation
const ManageImageAltTableCell = (props: PropTypes) => {

    // Function
    const { t } = useTranslation();
    const windowWidth = useWindowSize();

    // Props
    const { item, rowClass, individualCheck, showFreeWidgetStrip, handleImagePopupShow, decorativeImageSwitch, filterValue, HandleCopyText, handleSelectImgObj, handleSelectImgObjOnFocus, handleSelectImgObjOnBlur, debouncedUpdateAltTextHandler } = props;

    return (
        <>
            <div className={`aioa_dashboard-table-main-tr`} id={`item-${item.id}`}>
                <div className={`aioa_dashboard-table-tr ${rowClass ? rowClass : ``}`}>
                    <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Select image").toString()}>
                            <Form.Check
                                className="form-check"
                                type="checkbox"
                                aria-label={t("Select image").toString()}
                                name={`${item.id}`}
                                id={`${item.id}`}
                                onChange={(e) =>
                                    individualCheck(
                                        item,
                                        e.target.checked
                                    )
                                }
                                label={t("Select image").toString()}
                                checked={item.is_selected === 1 ? true : false}
                                disabled={showFreeWidgetStrip}
                            />
                        </div>
                    </div>
                    <div className="aioa_dashboard-table-td tcw-250 text-center aioa_dashboard-table-cell-sidegap">
                        <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Image")}</div>
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Image").toString()}>
                            <div className="alt-text-box-img">
                                {/* display tag if image has ai alt text */}
                                {((item.is_updated_from === 2) && (item.ai_generated_text?.localeCompare(item.alt_text_updated) === 0)) && (
                                    <>
                                        <div className="image-tag">
                                            <img src={iconAI} alt={t("Image has AI Generated alt Text").toString()} />
                                        </div>
                                    </>
                                )}

                                {/* check if image has image alt text, or it has ai generated al text */}
                                {item.alt_text_updated || item.ai_generated_text || item.alt_text ? (
                                    <>
                                        <OverlayTrigger
                                            placement={`${windowWidth.windowSize > 1200 ? `right` : `bottom`}`}
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip
                                                    data-bs-html="true"
                                                    id="button-tooltip"
                                                >
                                                    <span className="text-start">
                                                        {item.alt_text_updated
                                                            ? item.alt_text_updated
                                                            : item.ai_generated_text
                                                                ? item.ai_generated_text
                                                                : item.alt_text}
                                                    </span>
                                                </Tooltip>
                                            }
                                        >
                                            <Button
                                                aria-label={t("Open Image in Popup").toString()}
                                                tabIndex={0}
                                                onClick={() => handleImagePopupShow(item)}
                                                variant="light"
                                            >
                                                <img
                                                    onError={addDefaultImgSrc}
                                                    src={item.image_url}
                                                    alt={
                                                        item.alt_text_updated
                                                            ? item.alt_text_updated
                                                            : item.ai_generated_text
                                                                ? item.ai_generated_text
                                                                : item.alt_text
                                                    }
                                                />
                                            </Button>
                                        </OverlayTrigger>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            aria-label={t("Open Image in Popup").toString()}
                                            tabIndex={0}
                                            onClick={() => handleImagePopupShow(item)}
                                            variant="light"
                                        >
                                            <img
                                                onError={addDefaultImgSrc}
                                                src={item.image_url}
                                                alt={
                                                    item.alt_text_updated
                                                        ? item.alt_text_updated
                                                        : item.ai_generated_text
                                                            ? item.ai_generated_text
                                                            : item.alt_text
                                                }
                                            />
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                        <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Is Decorative?")}</div>
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Is Decorative?").toString()}>
                            <Form.Check
                                id="decorative_image"
                                type="switch"
                                checked={item.is_decorative === 1 ? true : false}
                                onChange={(e) => { decorativeImageSwitch(item, e.target.checked, e.target.checked ? "" : (item.alt_text_updated ? item.alt_text_updated : item.alt_text ? item.alt_text : ``)); }}
                                aria-label={t("Mark image as decorative").toString()}
                                label={t("Is Decorative?").toString()}
                                className="aioa_dashboard-decorative-image-switch"
                                disabled={showFreeWidgetStrip}
                            />
                        </div>
                    </div>
                    <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap">
                        <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Image Alt text")}</div>
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Image Alt text").toString()}>
                            {/* check if decorative flag is enabled */}
                            {!item.is_decorative && (
                                <div className="aioa_dashboard-update-image-alt-text-action">
                                    {/* check if image had alt text */}
                                    {item.alt_text ? (
                                        <>
                                            <div className="alt-text">
                                                <strong>
                                                    {t("Original Alt Text")} :&nbsp;
                                                </strong>
                                                <span>
                                                    {item.alt_text}
                                                </span>
                                                <button
                                                    className="btn btn-link"
                                                    aria-label={t("Edit").toString()}
                                                    onClick={() =>
                                                        HandleCopyText(
                                                            item.id,
                                                            item.alt_text_updated ? item.alt_text_updated : item.alt_text,
                                                            1
                                                        )
                                                    }
                                                    disabled={showFreeWidgetStrip}
                                                >
                                                    <i className="material-symbols-outlined" aria-hidden="true">edit</i>
                                                </button>
                                            </div>
                                        </>
                                    ) : item.ai_generated_text ? (
                                        <>
                                            <div className="alt-text">
                                                <strong>
                                                    {t("AI Alt Text")} :&nbsp;
                                                </strong>
                                                <span>
                                                    {item.ai_generated_text}
                                                </span>
                                                <button
                                                    className="btn btn-link"
                                                    aria-label={t("Edit").toString()}
                                                    onClick={() =>
                                                        HandleCopyText(
                                                            item.id,
                                                            item.alt_text_updated ? item.alt_text_updated : (item.ai_generated_text ? item.ai_generated_text : ''),
                                                            2
                                                        )
                                                    }
                                                    disabled={showFreeWidgetStrip}
                                                >
                                                    <i className="material-symbols-outlined" aria-hidden="true">edit</i>
                                                </button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="update-textarea 33">
                                                <Form.Control
                                                    className="table-textarea"
                                                    placeholder={`${t("Enter Image Alt Text")}`}
                                                    aria-label={`${t("Enter Image Alt Text")}`}
                                                    as="textarea"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSelectImgObj(item, e.target.value) }}
                                                    onFocus={() => handleSelectImgObjOnFocus(item.id)}
                                                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleSelectImgObjOnBlur(item, e.target.value)}

                                                    defaultValue={item.temp_alt_text_updated ? item.temp_alt_text_updated : item.alt_text_updated}
                                                    disabled={showFreeWidgetStrip || (item.is_decorative === 1 ? true : false)}
                                                />
                                            </div>
                                        </>
                                    )}
                                    {/* check if its a decorative or has updated alt text */}
                                    {(filterValue === "3" || filterValue === "4") && (
                                        <>
                                            {/* check if its updated manually */}
                                            {item.is_updated_from === 1 && item.alt_text_updated ? (
                                                <>
                                                    <div className="alt-text update-alt-text">
                                                        <strong> {t("Updated Alt Text")} :&nbsp; </strong>
                                                        {item.alt_text_updated}
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            {/* check if its updated using AI */}
                                            {item.is_updated_from === 2 && item.alt_text_updated && item.ai_generated_text?.localeCompare(item.alt_text_updated) !== 0 ? (
                                                <>
                                                    <div className="alt-text update-alt-text">
                                                        <strong> {t("Updated Alt Text")} :&nbsp; </strong>
                                                        {item.alt_text_updated}
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    )}

                                    {/* check if its manually updated and textbox is opened */}
                                    {(item.is_updated_from === 1 && item.is_open_textarea === 1) && (
                                        <div className="update-textarea 1">
                                            <Form.Control
                                                className="table-textarea"
                                                placeholder={`${t(
                                                    "Enter Image Alt Text"
                                                )}`}
                                                aria-label={`${t(
                                                    "Enter Image Alt Text"
                                                )}`}
                                                as="textarea"
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    debouncedUpdateAltTextHandler(
                                                        item,
                                                        false,
                                                        e.target.value
                                                    );
                                                    individualCheck(
                                                        item,
                                                        true
                                                    );
                                                }}
                                                defaultValue={
                                                    item.temp_alt_text_updated ? item.temp_alt_text_updated : item.alt_text_updated
                                                }
                                                disabled={
                                                    showFreeWidgetStrip || (item.is_decorative === 1 ? true : false)
                                                }
                                            />
                                        </div>
                                    )}

                                    {/* check if its updated from AI, and text area is opened */}
                                    {(item.is_updated_from === 2 && item.is_open_textarea === 1) && (
                                        <div className="update-textarea 2">
                                            <Form.Control
                                                className="table-textarea"
                                                placeholder={`${t(
                                                    "Enter Image Alt Text"
                                                )}`}
                                                aria-label={`${t(
                                                    "Enter Image Alt Text"
                                                )}`}
                                                as="textarea"
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    debouncedUpdateAltTextHandler(
                                                        item,
                                                        false,
                                                        e.target.value
                                                    );
                                                    individualCheck(
                                                        item,
                                                        true
                                                    );
                                                }}
                                                defaultValue={
                                                    item.temp_alt_text_updated ? item.temp_alt_text_updated : item.alt_text_updated
                                                }
                                                disabled={
                                                    showFreeWidgetStrip || (item.is_decorative === 1 ? true : false)
                                                }
                                            />
                                        </div>
                                    )}

                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { ManageImageAltTableCell }