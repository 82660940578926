import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { StoreProptypes } from '../../PropTypes/StoreProptypes';
import { SET_MESSAGE } from '../../Actions/Types';

// component to render toast message for whole application
const ToastMessage = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [showToast, setToast] = useState(false);
    const { toastMessage } = useSelector((state: StoreProptypes) => state);


    useEffect(() => {
        if (toastMessage && toastMessage.toast_message) {
            setToast(true)
        }
    }, [toastMessage]);


    // function to clear toast message
    const ClearToast = () => {
        setToast(false);
        
        dispatch({
            type: SET_MESSAGE,
            payload: {
                "toast_message": null,
                "toast_type": null,
                "onclose": null
            },
        });
    }

    return (
        <>
            <div aria-atomic="true" aria-live='polite'>
                <ToastContainer className={`p-3 custom-toast-container ${toastMessage && toastMessage.toast_type}`} position="bottom-end" containerPosition="fixed">
                    <Toast show={showToast} delay={5000} autohide onClose={ClearToast}>
                        <Toast.Header closeButton={true} className="custom-toast-header">
                            <strong>{t("Alert")}</strong>
                        </Toast.Header>
                        <Toast.Body className="custom-toast-body">
                            {toastMessage && toastMessage.toast_message}
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
        </>
    )

}
export default ToastMessage