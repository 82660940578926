import React, { forwardRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { DraggableProvidedDragHandleProps, DraggableProvidedDraggableProps, DraggableStateSnapshot } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { StoreProptypes } from "../../../PropTypes/StoreProptypes";
import { useSelector } from "react-redux";
import { MenulistPropTypes } from "../../../PropTypes/EnableDisableServicesPropTypes";


interface PropTypes {
    item: MenulistPropTypes, // language object
    childrenRef: any, // element reference 
    dragHandleProps: DraggableProvidedDragHandleProps | null | undefined, // drag handle props
    otherProp: DraggableProvidedDraggableProps // draggable props
    snapshot: DraggableStateSnapshot, // draggable snapshot
    handleMainMenuButtonStatus: Function, // function to handle menu button status
}

// component to render main menu list element
const MenuButtonListChildrenElement = forwardRef((prop: PropTypes) => {

    const { t } = useTranslation();

    const { item, childrenRef, otherProp, snapshot, dragHandleProps, handleMainMenuButtonStatus } = prop;

    const { activeLanguage } = useSelector((state: StoreProptypes) => state);


    // infotext Popup
    const [showInfoPopup, setShowInfoPopup] = useState(false);

    // function to close info popup
    const handleInfoPopupClose = () => {
        setShowInfoPopup(false);
    }

    // function to show info popup
    const handleInfoPopupShow = () => {
        setShowInfoPopup(true);
    }

    return (
        <div className={`menu-box border ${snapshot.isDragging ? `hovering` : ``}`} ref={childrenRef} {...otherProp}>
            <div className="drag-icon" style={{ cursor: 'all-scroll' }} {...dragHandleProps}>
                <i className="material-symbols-outlined" aria-hidden="true">drag_indicator</i>
            </div>
            <div className="menu-name h6 mb-0 ms-2 me-2 d-flex align-items-center">
                {item.name}
                {item.id === 103 ?
                    <>
                        <i className={`material-symbols-outlined ${activeLanguage?.is_rtl ? 'me-2' : 'ms-2'}`} role="button" aria-label={t("Read more about libras").toString()} tabIndex={0} onClick={handleInfoPopupShow}>info</i>
                        <Modal show={showInfoPopup} onHide={handleInfoPopupClose} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {t(item.name)}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {t('Libras description')}
                            </Modal.Body>
                        </Modal>
                    </> : ""
                }
            </div>
            <div className="action-part">
                <Form.Check
                    className="form-check m-0 p-0 d-flex align-items-center"
                    type="checkbox"
                    id={`accessibility-menu-${item.id}`}
                    area-label={`Toggle ${item.name}`}
                    onChange={(e) => handleMainMenuButtonStatus(e, item.id)}
                    defaultChecked={item.status === 1 ? true : false}
                />
            </div>
        </div>
    )
});

export default MenuButtonListChildrenElement;