import i18n from "i18next";
import { getLanguagesApi, getTranslationsApi } from "../../Api/LanguageApi";
import { SET_ACTIVE_LANGUAGE, SET_LANGUAGE_KEYWORDS_LIST, SET_LANGUAGE_LIST, TRANSLATION_LOADING } from "../../Actions/Types";
import { LanguagePropTypes } from "../../PropTypes/LanguagePropTypes";

// service to get language list
const getLanguagesList = (
    dispatch: Function // dispatch function for redux store
) => {
    // set translation loading
    dispatch({
        type: TRANSLATION_LOADING,
        payload: true
    });

    // get language API
    getLanguagesApi().then(response => {

        // check if response data is available
        if (response.Data) {

            // get translations
            getTranslationsApi(response.Data[0].code).then(translationsResponse => {

                // check if translation response data is available
                if (translationsResponse.Data) {

                    // store languages list
                    dispatch({
                        type: SET_LANGUAGE_LIST,
                        payload: response.Data,
                    });

                    // store active language
                    dispatch({
                        type: SET_ACTIVE_LANGUAGE,
                        payload: response.Data[0],
                    });

                    // Store All keywords
                    dispatch({
                        type: SET_LANGUAGE_KEYWORDS_LIST,
                        payload: translationsResponse.Data,
                    });
                }
            })

        }
    });
}

// service to set language
const setLanguage = (
    languageObj: LanguagePropTypes, // language object
    dispatch: Function // dispatch function for redux
) => {

    // set loading for translation
    dispatch({
        type: TRANSLATION_LOADING,
        payload: true
    });

    // get translation
    getTranslationsApi(languageObj.code).then(translationsResponse => {

        // check if translation data is available
        if (translationsResponse.Data) {

            // dispatch active language object
            dispatch({
                type: SET_ACTIVE_LANGUAGE,
                payload: languageObj,
            });

            // store translation data
            dispatch({
                type: SET_LANGUAGE_KEYWORDS_LIST,
                payload: translationsResponse.Data,
            });
        }
    })

}

// service to get translations
const getTranslation = (
    languageCode: string // ISO language code
) => {

    // get translations api
    getTranslationsApi(languageCode).then(response => {
        // check if response data is available
        if (response.Data) {
            i18n.addResources(
                languageCode,
                'translation',
                { ...response.Data },
            );
            i18n.changeLanguage(languageCode);
        }
    })
}

export { getLanguagesList, setLanguage, getTranslation };