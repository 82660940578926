const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

// API to reset password
const resetPasswordApi = async (
    email: string, // email address
    token: string, // reset password token
    password: string, // new password
    password_confirmation: string // confirm new password
): Promise<any> => {

    const url = `${baseURL}/reset`;

    const data = new FormData();

    data.append("email", email);
    data.append("token", token);
    data.append("password", password);
    data.append("password_confirmation", password_confirmation);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: data
        });
        return await response.status;
    } catch (error) {
        return Promise.reject;
    }

}

export { resetPasswordApi }