import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { resetPasswordApi } from "../../Api/UserAction/ResetPasswordApi";

// service to handle reset password API
const resetPasswordService = (
    email: string, // email address
    token: string, // reset password token
    password: string, // password 
    password_confirmation: string, // confirm password
    handleFocus: Function, // function to handle focus
    t: Function, // i18next t function
    dispatch: Function, // dispatch function 
    navigate: Function // navigate function for router
) => {

    // set loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // reset password API
    resetPasswordApi(email, token, password, password_confirmation).then(
        (status: number | never[]) => {

            // check API response is ok
            if (status === 200) {

                // remove loading
                dispatch({
                    type: LOADING,
                    payload: false,
                });

                // navigate to login page
                navigate('/front/login');

                // dispatch reset password successful message
                dispatch({
                    type: SET_MESSAGE,
                    payload: {
                        "toast_message": t("Reset Password Success Msg", { returnObjects: true }),
                        "toast_type": "success",
                        "onclose": null
                    }
                });

            } else {

                // remove loading
                dispatch({
                    type: LOADING,
                    payload: false,
                });

                // navigate to forgot password page
                navigate('/front/forgot-password');

                // dispatch reset password fail message
                dispatch({
                    type: SET_MESSAGE,
                    payload: {
                        "toast_message": t("Reset Password Failed Msg", { returnObjects: true }),
                        "toast_type": "danger",
                        "onclose": handleFocus
                    }
                });

            }

        }
    );
};

export { resetPasswordService }