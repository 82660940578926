import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";

import "../../Assets/scss/pages/payment-page.scss";

const baseURL = `${process.env.REACT_APP_BASE_URL}`


// component to render payment page for Pdf
const PDFPaymentPage = () => {

    const { token } = useParams();

    const { t } = useTranslation();

    const bodyClassList = ['add-new-domain-screen'];

    useEffect(() => {
        document.body.classList.remove(...bodyClassList);
    }, [])

    

    const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement => input !== null && input.tagName === 'IFRAME';

    // function to dynamically apply height to an iframe
    const iframeHeight = () => {
        let frame = document.getElementById('myIframe');

        setTimeout(() => {
            // check if iframe exists and can access iframe content
            if (isIFrame(frame) && frame.contentWindow) {
                frame.style.height = frame.contentWindow.document.body.scrollHeight + 250 + 'px';
            }
        }, 3000);
    }

    // calculate iframe height on iframe load function
    const iframeLoader = () => {
        iframeHeight();
    }

    return (
        <>
            <DashboardPagesTemplate>

                <div className="aioa_dashboard-payment-screen-main">
                    
                        {(token) && (
                            <>
                                <iframe title={t("Payment for PDF").toString()} onLoad={iframeLoader} id="myIframe" src={`${baseURL}/pdf-remediat-purchase/${token}/1`} style={{ width: '100%' }}></iframe>
                            </>
                        )}
                    
                </div>

            </DashboardPagesTemplate>
        </>
    )
}

export default PDFPaymentPage;