import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { getManualAuditReportListApi, mailSendReportFileApi, uploadReportFileApi } from "../../Api/ManualReportApi";


// service to get report list
const getManualAuditReportListService = async (
    accessToken: string, // access token
    websiteID: number, // website ID
    addonID: number, // add-on Id
    dispatch: Function, // function to save redux data
    limit: number, // limit per page
    offset: number, // record offset
    filter: string, // filter by
    setTotalRecord: Function, //function to set total records
    setReportList: Function, // function to set report list
): Promise<any> => {

    // Set loading
    dispatch({
        type: LOADING,
        payload: true
    });

    // API to get report list
    const getManualAuditReportListApiRes = await getManualAuditReportListApi(accessToken, websiteID, addonID, limit, offset, filter).then(response => {

        // check if response data is available
        if (response.Data) {

            setReportList(response.Data);
            setTotalRecord(response.total_records);

            // remove loading
            dispatch({
                type: LOADING,
                payload: false
            });

            return { status: 200 };

        } else {

            setReportList([]);
            setTotalRecord(0);

            // remove loading
            dispatch({
                type: LOADING,
                payload: false
            });

            return { status: 400 };

        }
    });

    return getManualAuditReportListApiRes;
}

// service to upload report file
const uploadReportFileService = async (
    accessToken: string, // access token
    reportItemID: number, // report ID
    userId: number, // user Id
    file: File, // file
    reportStatus: number, // report status
    dispatch: Function, //dispatch function to 
    t: Function, // i18next t function
    handleHideFileUploadPopupPopup?: Function // function to hide file upload popup
): Promise<any> => {

    // Set loading
    dispatch({
        type: LOADING,
        payload: true
    });

    // API to upload report file
    const uploadReportFileApiRes = await uploadReportFileApi(accessToken, reportItemID, userId, file, reportStatus).then(res => {

        if (res.Data) {
            handleHideFileUploadPopupPopup && handleHideFileUploadPopupPopup();

            dispatch({
                type: LOADING,
                payload: false
            });

            return { status: 200 };

        } else {

            // remove loading
            dispatch({
                type: LOADING,
                payload: false
            });

            return { status: 400 };
        }

    });

    return uploadReportFileApiRes;
}

// service to email report
const mailSendReportFileService = (
    accessToken: string, // access token
    reportID: number, // report ID
    userEmailID: string, // email id
    popupCloseFunction: Function, // function to close popup
    dispatch: Function, // function to store redux data
    t: Function // i18next t function
) => {

    // Set loading
    dispatch({
        type: LOADING,
        payload: true
    });

    // API to email report
    mailSendReportFileApi(accessToken, reportID, userEmailID).then(res => {

        // check if response data is available
        if (res.Data) {
            // close popup
            popupCloseFunction();

            // remove loading
            dispatch({
                type: LOADING,
                payload: false
            });

            // dispatch success message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": `${t(`The report is sent to ${userEmailID}. Please check your inbox.`)}`,
                    "toast_type": "success"
                }
            })
        }
    })
}

export { getManualAuditReportListService, uploadReportFileService, mailSendReportFileService }