const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

// API to get violation count
const getViolationsCountApi = async (
    token: string, // access token
    websiteID: number // website ID
): Promise<any> => {

    const url = `${baseURL}/scan-history-data`;

    const data = new FormData();

    data.append("website_id", websiteID.toString());

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

// API to get violation list
const getViolationsListApi = async (
    token: string, //access token
    websiteID: number, // website Id
    lang: string, // language
    isFixedByWidget: number // flag to get list after widget fixes
): Promise<any> => {

    const url = `${baseURL}/accessibility/violations-list`;

    const data = new FormData();

    data.append("website_id", websiteID.toString());
    data.append("is_fixed_by_widget", isFixedByWidget.toString());
    data.append("langcode", lang);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { getViolationsCountApi, getViolationsListApi }