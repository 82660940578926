import { LOADING, SET_WEBSITE_LIST } from "../../Actions/Types";
import { getDomainListApi, getDomainListWithDetailsApi } from "../../Api/DomainApi/DomainListWithDetailsApi";


// service to get domain list with details
const getDomainListWithDetailsService = (
    token: string, // access token
    dispatch: Function, // dispatch function to store redux data
    t: Function, // i18next t function
    setDomainList: Function, // function to set domain list
    limit: number, // limit per page
    offset: number, // page offset
    terms: string, // search term
    statusFilter: number, // website status
    startDate : string, // start date filter
    endDate : string, // end date filter
    setTotalMainRecord: Function, // function to set total record count
    setTotalRecord: Function  // function to set displayed record count
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // API to get domain list
    getDomainListWithDetailsApi(token, limit, offset, terms, statusFilter, startDate, endDate).then((res) => {
        // check if response is ok
        if (res.status === 200) {

            // Set Website List
            setDomainList(res.Data);

            // Set Record Record
            setTotalRecord(res.total_records);

            // Set Total Record
            setTotalMainRecord(res.total_records_main);

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
        }
    })
}

// service to get domain list only
const getDomainListService = async (
    token: string, // access token
    dispatch: Function, // dispatch function to store redux data
): Promise<any> => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // API to get domain list
    getDomainListApi(token).then((listRes) => {
        // check if response is ok
        if (listRes.status === 200) {

            // Set Website List
            dispatch({
                type: SET_WEBSITE_LIST,
                payload: listRes.Data
            })

            // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

        } else {

            // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
        }
    });

    return {status:200}

}

export { getDomainListWithDetailsService, getDomainListService }