import { debounce } from 'lodash';
import { useState, useEffect, useCallback } from 'react';

// react hook to use window size
const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth);

    // function to handle window resize
    const handleResize = useCallback(debounce(() => {
        setWindowSize(window.innerWidth);
    }, 500), []);

    useEffect(() => {
        // add event listener on window resize
        window.addEventListener('resize', handleResize, { passive: true });
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return { windowSize }

}

export default useWindowSize;