import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement, } from '@stripe/react-stripe-js';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreProptypes } from '../../PropTypes/StoreProptypes';
import { addNewCardService } from '../../Services/CardServices';

interface PropTypes {
    setCardListData: Function, // function to set card list
    setLoadingCardList: Function, // function to set loader 
    setShowCardPopup: Function // function to show card popup
}

// component to render credit/debit card popup
const CardPopup = (props: PropTypes) => {

    const stripe = useStripe();

    const elements = useElements();

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { accessToken } = useSelector((store: StoreProptypes) => store);
    const [showCardPopupLoading, setShowCardPopupLoading] = useState(false);
    const [showCardError, setShowCardError] = useState(false);
    const [showCardErrorMsg, setShowCardErrorMsg] = useState('');

    const { setCardListData, setLoadingCardList, setShowCardPopup } = props;

    // function to handle card details
    const handleCardDetails = async () => {
        if (!stripe || !elements) {
            return;
        }
        if (elements) {
            //const card = elements.getElement(CardElement);
            const getCardElementNumber = elements.getElement(CardNumberElement);
            //const getCardExpiryElement = elements.getElement(CardExpiryElement);
            //const getCardCvcElement = elements.getElement(CardCvcElement);

            // check if card number exists
            if (getCardElementNumber) {
                const result = await stripe.createToken(getCardElementNumber);
                //console.info(result);

                // check if stripe token and access token are available
                if (result.token && result.token.id && accessToken) {
                    setShowCardError(false);
                    setShowCardErrorMsg('')
                    addNewCardService(accessToken, result.token.id, setShowCardPopupLoading, setCardListData, setLoadingCardList, setShowCardPopup, dispatch);
                } else {
                    if (result.error) {
                        setShowCardError(true);
                        result.error.message && setShowCardErrorMsg(result.error.message);
                    }
                }
            }
        }

    }

    return (
        <>
            <div className='card-input-group row'>
                <div className='card-input-elements col-12'>
                    <label>{t('Card Number')}</label>
                    <div className='card-input'>
                        <CardNumberElement options={{ showIcon: true }} />
                    </div>
                </div>
                <div className='card-input-elements col-6 mt-3'>
                    <label>{t('Card Expiry')}</label>
                    <div className='card-input'>
                        <CardExpiryElement />
                    </div>
                </div>
                <div className='card-input-elements col-6 mt-3'>
                    <label>{t('Card CVV')}</label>
                    <div className='card-input'>
                        <CardCvcElement options={{ placeholder: 'CVV' }} />
                    </div>
                </div>
            </div>

            <div className='card-save-btns mt-4'>
                <Button variant="primary" onClick={handleCardDetails} disabled={showCardPopupLoading}>
                    {t('Save Button Label')}
                </Button>
            </div>

            {showCardError && (
                <>
                    <div className='error-msg text-danger' style={{ fontSize: '14px', fontWeight: '500', textAlign: 'center' }}>{showCardErrorMsg}</div>
                </>
            )}

        </>
    )
}

export default CardPopup;
