import { forwardRef } from "react";

interface PropTypes {
    length: number, // number of items
    children: any, // child elements
    refMain: any // main element reference
}

// component to render wrapper for main menu list
const MenuButtonListMainElement = forwardRef((prop: PropTypes) => {
    const { length, children, refMain } = prop;
    return (
        <div className={`menu-list ${length > 10 ? 'scroll' : ``}`} ref={refMain}>
            {children}
        </div>
    )
});

export default MenuButtonListMainElement;