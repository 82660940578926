import "../../Assets/scss/pages/addon-service.scss";

import { useTranslation } from "react-i18next";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { PackagePropTypes } from "../../PropTypes/PackagePropTypes";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { getCurrentPackageDetails } from "../../Services/PlanListService";
import { Alert, Badge, Button, Card, Col, Form, InputGroup, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import parseISODate from "../../Helpers/parseISODate";
import { websitePlanPropType } from "../../PropTypes/WebsitePlanPropType";
import encodeBase64 from "../../Helpers/encodeBase64";
import { ManualAuditReportPropType } from "../../PropTypes/ManualReportPropType";
import { getManualAuditReportListService, mailSendReportFileService } from "../../Services/ManualReportServices";
import ShowRecordItem from "../../Components/ShowRecordItem";
import PaginationComponent from "../../Components/Pagination";
import NoRecordFound from "../../Components/NoRecordFound";
import ReportFileUploadElement from "../../Components/ReportFileUpload";
import { SET_MESSAGE } from "../../Actions/Types";
import { addOnEnableDisableService } from "../../Services/PackageService";
import PaymentButtonBlock from "../../Components/PaymentButton";
import PDFList from "../AddOnDetailsPages/PDFList";
import WidgetLayoutMain from "../../Components/WidgetPreview";
import ModifyAccessibilityMenu from "../AddOnDetailsPages/ModifyAccessibilityMenu";
import { LanguagesListPropTypes, MenulistPropTypes, OtherListPropTypes } from "../../PropTypes/EnableDisableServicesPropTypes";
import CustomBrandingService from "../AddOnDetailsPages/CustomBranding";
import useWindowSize from "../../Helpers/useWindowSize";
import FreeVersionOverlay from "../../Components/FreeVersionOverlay";

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

// component to render add-on detail page
const AddOnDetailsPage = () => {

    // Functions
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const windowSize = useWindowSize();

    // Redux
    const { accessToken, currentWebsite, activeLanguage, user, currentWebsitePlanList, currentWebsitePlanStatus } = useSelector((store: StoreProptypes) => store);


    const [updatedMenuList, setUpdatedMenuList] = useState<MenulistPropTypes[]>([]);
    const [updatedLanguagesList, setUpdatedLanguagesList] = useState<LanguagesListPropTypes[]>([]);
    const [updatedOtherList, setUpdatedOtherList] = useState<OtherListPropTypes[]>([]);
    const [updatedAccessibilityProfilesList, setUpdatedAccessibilityProfilesList] = useState<OtherListPropTypes[]>([]);

    const [currentAddOnID, setCurrentAddOnID] = useState<number | null>(null);

    const [isPurchasedAddOn, setIsPurchasedAddOn] = useState<boolean>(false);
    const [purchasedAddOnDetails, setPurchasedAddOnDetails] = useState<PackagePropTypes[]>([]);
    const [currentAddOnDetails, setCurrentAddOnDetails] = useState<websitePlanPropType[]>([]);

    const [reportList, setReportList] = useState<ManualAuditReportPropType[]>();
    const [limit, setLimit] = useState<number>(10);
    const [offset, setOffset] = useState<number>(0);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filter, setFilter] = useState<string>('-1');

    const prevLimitRef = useRef<number>(10);

    const [userEmail, setUserEmail] = useState('');
    const [disabledButton, setDisabledButton] = useState<boolean>(false);


    useEffect(() => {
        // check if user email exists
        if (user && user.email) {
            setUserEmail(user.email);
        } else {
            setUserEmail('');
        }
    }, [user])

    useEffect(() => {
        // check if user email exists
        if (user && currentWebsitePlanStatus) {
            // check if admin user and plan is expired
            if (user.department === 1 || currentWebsitePlanStatus.isExpired) {
                setDisabledButton(true);
            } else {
                setDisabledButton(false);
            }
        }
    }, [user, currentWebsitePlanStatus])


    useEffect(() => {

        if (currentWebsite) {

            // check path to render add on page
            if (location.pathname === '/front/addon-services/white-label-service') {

                setCurrentAddOnID(5); // 5 = white label

                // check if white label service is enabled
                if (currentWebsite.white_label_enabled) {
                    setIsPurchasedAddOn(true);
                } else {
                    setIsPurchasedAddOn(false);
                }

            }

            else if (location.pathname === '/front/addon-services/custom-branding-service') {

                setCurrentAddOnID(6); // 6 = custom branding

                // check if custom branding service is enabled
                if (currentWebsite.custom_branding_enabled) {
                    setIsPurchasedAddOn(true);
                } else {
                    setIsPurchasedAddOn(false);
                }

            }
            else if (location.pathname === '/front/addon-services/live-site-translation') {

                setCurrentAddOnID(8); // 8 = live site translation

                // check if live site translation service is enabled
                if (currentWebsite.live_site_translation_enabled) {
                    setIsPurchasedAddOn(true);
                } else {
                    setIsPurchasedAddOn(false);
                }

            }
            else if (location.pathname === '/front/addon-services/modify-accessibility-menu') {

                setCurrentAddOnID(9); // 9 = modify accessibility menu

                // check if modify accessibility menu service is enabled
                if (currentWebsite.menu_features_enabled) {
                    setIsPurchasedAddOn(true);
                } else {
                    setIsPurchasedAddOn(false);
                }

            }
            else if (location.pathname === '/front/addon-services/vpat-report') {

                // (staging - 23) (live - 25) = VPAT report
                setCurrentAddOnID((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 23 : 25);

                // check if VPAT service is enabled
                if (currentWebsite.manual_vpat_enabled) {
                    setIsPurchasedAddOn(true);
                } else {
                    setIsPurchasedAddOn(false);
                }

            }
            else if (location.pathname === '/front/addon-services/manual-audit-report') {

                // (staging - 24) (live - 26) = manual audit report
                setCurrentAddOnID((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 24 : 26);

                // check if manual audit service is enabled
                if (currentWebsite.manual_audit_vpat_enabled) {
                    setIsPurchasedAddOn(true);
                } else {
                    setIsPurchasedAddOn(false);
                }

            }
            else if (location.pathname === '/front/addon-services/manual-remediation') {

                setCurrentAddOnID(35); // 35 = manual remediation

                // check if manual remediation is enabled
                if (currentWebsite.manual_remediation_enabled) {
                    setIsPurchasedAddOn(true);
                } else {
                    setIsPurchasedAddOn(false);
                }

            }
            else if (location.pathname === '/front/addon-services/design-audit') {

                setCurrentAddOnID(36); // 36 = design audit service

                // check if design audit service is enabled
                if (currentWebsite.design_audit_enabled) {
                    setIsPurchasedAddOn(true);
                } else {
                    setIsPurchasedAddOn(false);
                }

            }
            else if (location.pathname === '/front/addon-services/native-mobile-app-audit') {

                // (staging - 35) (live - 37) = native mobile app audit
                setCurrentAddOnID((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 35 : 37);

                // check if native mobile app audit service is enabled
                if (currentWebsite.native_mobile_app_audit_enabled) {
                    setIsPurchasedAddOn(true);
                } else {
                    setIsPurchasedAddOn(false);
                }

            }

            else if (location.pathname === '/front/addon-services/web-app-spa-audit') {

                // (staging - 36) (live - 38) = web app/SPA audit
                setCurrentAddOnID((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 36 : 38);

                // check if native web app / SPA audit service is enabled
                if (currentWebsite.web_app_spa_audit_enabled) {
                    setIsPurchasedAddOn(true);
                } else {
                    setIsPurchasedAddOn(false);
                }

            }
            else if (location.pathname === '/front/addon-services/pdfs') {
                setCurrentAddOnID(0); // 0 = PDF remediation service
            }
            else {
                setCurrentAddOnID(null);
                setIsPurchasedAddOn(false);
            }
        }
    }, [location, currentWebsite]);


    useEffect(() => {
        // check if add-on Id exists
        if ((currentAddOnID !== 0 && currentAddOnID !== null) && currentWebsite && accessToken) {

            // check if add-on is purchased
            if (isPurchasedAddOn) {
                // service to get current package details
                getCurrentPackageDetails(accessToken, currentAddOnID, currentWebsite.id, dispatch, setPurchasedAddOnDetails);
            }
            else {
                setPurchasedAddOnDetails([]);
            }

            // check if plan list is available
            if (currentWebsitePlanList && Object.keys(currentWebsitePlanList).length > 0) {

                let tempPackageList: websitePlanPropType[] = [];

                // get add-on plans
                currentWebsitePlanList[`adon`].filter((filterPackage) => filterPackage.id === currentAddOnID).map((packageItem) => {
                    tempPackageList = [...tempPackageList, packageItem]
                });

                setCurrentAddOnDetails(tempPackageList);
            }

        }
    }, [currentAddOnID, currentWebsite, isPurchasedAddOn]);


    // function to redirect to white label plan purchase screen
    const handleWhiteLabelPackagePurchase = (
        pID: number, //plan Id
        price: number, // price
        interval: string // plan interval
    ) => {

        // check if current website, plan Id and interval available
        if (currentWebsite && pID && interval) {
            const urlString = `${pID}|${currentWebsite.domain}|${interval}|${user?.id}|${activeLanguage?.code}|1`;
            const encodedString = encodeBase64(urlString);
            navigate(`/front/package/${encodedString}`)
        }
    }

    // function to redirect to sub plan purchase screen
    const handleSubAddOnPackagePurchase = (
        PID: number, // plan Id
        interval: string, // interval
        SPID: number // sub package Id
    ) => {
        const urlString = `${PID}|${currentWebsite?.domain}|${interval}|${user?.id}|${activeLanguage?.code}|2|${SPID}`;
        const encodedString = encodeBase64(urlString);
        navigate(`/front/package/${encodedString}`);
    }


    useEffect(() => {
        setCurrentPage(1);
    }, [limit, filter]);

    useEffect(() => {
        setOffset(currentPage * limit - limit);
    }, [currentPage, filter]);

    useEffect(() => {
        // check if purchased add on flag is set, access token is available
        if (isPurchasedAddOn
            && accessToken
            && (currentAddOnID !== 5 // white label
                && currentAddOnID !== 6  // custom branding
                && currentAddOnID !== 8  // live site translation
                && currentAddOnID !== 9  // modify accessibility menu
                && currentAddOnID !== 0) // PDF accessibility
            && currentAddOnID !== null
            && currentWebsite
            && (limit === prevLimitRef.current) // check if limit is not changed
        ) {
            // service to get manual audit report
            getManualAuditReportListService(accessToken, currentWebsite.id, currentAddOnID, dispatch, limit, offset, filter, setTotalRecord, setReportList);
        }
    }, [currentAddOnID, currentWebsite, offset, filter]);

    useEffect(() => {
        // check if purchased add on flag is set, access token is available
        if (isPurchasedAddOn
            && accessToken
            && (currentAddOnID !== 5 // white label
                && currentAddOnID !== 6  // custom branding
                && currentAddOnID !== 8  // live site translation
                && currentAddOnID !== 9  // modify accessibility menu
                && currentAddOnID !== 0) // PDF accessibility
            && currentAddOnID !== null
            && currentWebsite
            && limit !== prevLimitRef.current // check if limit is not changed
            && offset === 0) {

            // service to get manual audit report  
            getManualAuditReportListService(accessToken, currentWebsite.id, currentAddOnID, dispatch, limit, offset, filter, setTotalRecord, setReportList);

            prevLimitRef.current = limit;
        }
    }, [offset, limit]);

    // File Upload Modal
    const [isReUploadReportModal, setIsReUploadReportModal] = useState<boolean>(false);
    const [currentReportID, setCurrentReportID] = useState<number>(0);

    // function to display reupload report modal
    const handleShowReUploadReportModal = (
        id: number // report Id
    ) => {
        setCurrentReportID(id);
        setIsReUploadReportModal(true);
    }

    // function to hide reupload report modal
    const handleHideReUploadReportModal = () => {
        setIsReUploadReportModal(false);
        setCurrentReportID(0);
    }

    // Send mail
    const [isShowMailConfirmModal, setIsShowMailConfirmModal] = useState(false);

    // function to hide email report modal
    const handleHideReportMailModal = () => {
        setIsShowMailConfirmModal(false);
        setCurrentReportID(0);
    }

    // function to show email report modal
    const handleShowReportMailModal = (
        id: number // report Id
    ) => {
        setCurrentReportID(id);
        setIsShowMailConfirmModal(true);
    }

    // function to handle form submit
    const handleSendReportSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {

        e.preventDefault();

        // check if user email is empty
        if (userEmail === '') {
            // dispatch error message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t(`Please enter your email address.`, { returnObjects: true }),
                    "toast_type": "danger"
                }
            })
        } else {
            // check if access token, user email and report Id are available
            if (accessToken && userEmail && currentReportID) {
                // service to email report
                mailSendReportFileService(accessToken, currentReportID, userEmail, handleHideReportMailModal, dispatch, t);
            }
        }
    }

    // function to handle enable/disable add-on service
    const handleEnableDisabledAddon = (
        id: number, // add-on Id
        status: number// flag to enable or disable
    ) => {
        // check if access token, add-on Id and current website are available
        if (accessToken && id && currentWebsite) {
            addOnEnableDisableService(accessToken, currentWebsite.id, id, status, dispatch, t);
        }
    }

    return (
        <>
            <DashboardPagesTemplate>
                {/* check if add-on Id is 0, then render PDF remediation */}
                {currentAddOnID !== null && currentAddOnID === 0 ? (
                    <>
                        <PDFList />
                    </>
                ) : (
                    <>

                        {/* check if current add on details are available and is not PDF */}
                        {currentAddOnDetails && currentAddOnDetails.length > 0 && (
                            <>
                                <div className="aioa_dashboard-page-title-wrapper">
                                    <div className="aioa_dashboard-page-title">
                                        <h1 className="mb-0" tabIndex={-1}>
                                            {t(
                                                currentAddOnID === 5 ? `White Label` :
                                                    currentAddOnID === 6 ? `Custom Branding Service` :
                                                        currentAddOnID === 8 ? `Live Site Translation` :
                                                            currentAddOnID === 9 ? `Modify Accessibility Menu` :
                                                                (currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 23 : 25)) ? `VPAT Report` :
                                                                    currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 24 : 26) ? `Manual Audit Report` :
                                                                        currentAddOnID === 35 ? `Manual Remediation` :
                                                                            currentAddOnID === 36 ? `Design Audit` :
                                                                                currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 35 : 37) ? `Native Mobile App Audit` :
                                                                                    currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 36 : 38) ? `Web App-SPA Audit` :
                                                                                        ``
                                            )}
                                        </h1>
                                    </div>

                                    <div className="aioa_dashboard-addon-page-right-part">
                                        {/* check if purchased flag is available and add on details are available and is not PDF */}
                                        {isPurchasedAddOn && purchasedAddOnDetails && purchasedAddOnDetails.length > 0 && (
                                            <>
                                                <div className="addon-active-plan-info">

                                                    {/* check if its a partner user and white label or custom branding add on */}
                                                    {user && user.department === 4 && (currentAddOnID === 5 || currentAddOnID === 6) ? (
                                                        <>
                                                            <div className="add-price-info">{t('Free Status')}</div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {purchasedAddOnDetails[0].purchased1
                                                                && purchasedAddOnDetails[0].purchased1 !== null // check if purchased add on details are available
                                                                && (
                                                                    <>

                                                                        {currentAddOnID === 5 // white label
                                                                            || currentAddOnID === 6 // custom branding
                                                                            || currentAddOnID === 8 // live site translation
                                                                            || currentAddOnID === 9 // modify accessibility menu
                                                                            ? (
                                                                                <>

                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <div className="addon-plan-info">
                                                                                        {purchasedAddOnDetails[0].package_prices
                                                                                            && purchasedAddOnDetails[0].package_prices.length > 0
                                                                                            && (
                                                                                                <>
                                                                                                    {purchasedAddOnDetails[0].package_prices.filter((filterItem) => filterItem.id === purchasedAddOnDetails[0].purchased1.package_price_id).map((item) => {
                                                                                                        return (
                                                                                                            <React.Fragment key={item.id}>
                                                                                                                {item.name}
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    })}
                                                                                                </>
                                                                                            )}
                                                                                    </div>
                                                                                </>
                                                                            )}


                                                                        <div className="addon-price-info">
                                                                            {currentAddOnID === 5 // white label
                                                                                || currentAddOnID === 6 // custom branding
                                                                                || currentAddOnID === 8 // live site translation
                                                                                || currentAddOnID === 9 // modify accessibility menu 
                                                                                ? (
                                                                                    <>
                                                                                        {purchasedAddOnDetails[0].purchased1.subscr_interval === "M" ? (
                                                                                            <>
                                                                                                ${purchasedAddOnDetails[0].purchased1.final_price}/{t(`Per Month`)}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                ${purchasedAddOnDetails[0].purchased1.final_price}/{t(`Per Year`)}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        ${purchasedAddOnDetails[0].purchased1.final_price}
                                                                                    </>
                                                                                )}

                                                                        </div>
                                                                    </>
                                                                )}
                                                        </>
                                                    )}
                                                </div>

                                                {purchasedAddOnDetails[0].purchased === 1 ? (
                                                    <>
                                                        <Badge bg="success">{t('Active Status')}</Badge>
                                                    </>
                                                ) : purchasedAddOnDetails[0].purchased === 2 ? (
                                                    <>
                                                        <Badge bg="secondary">{t('Add-On Payment Awaiting Status')}</Badge>
                                                    </>
                                                ) : (
                                                    <>
                                                    </>
                                                )}

                                            </>
                                        )}

                                        {(currentAddOnID === 5 // white label
                                            || currentAddOnID === 6 // custom branding
                                            || currentAddOnID === 8 // live site translation
                                            || currentAddOnID === 9 // modify accessibility menu
                                        ) && (
                                                <>
                                                    {currentAddOnID === 5 // white label
                                                        || currentAddOnID === 6 // custom branding
                                                        ? (
                                                            <>
                                                                {user
                                                                    && user.department === 4 // partner user
                                                                    ? (
                                                                        <>
                                                                            {purchasedAddOnDetails
                                                                                && purchasedAddOnDetails.length > 0 // check if purchased details available
                                                                                && purchasedAddOnDetails[0].purchased === 1
                                                                                ? (
                                                                                    <>
                                                                                        <Button
                                                                                            variant="danger"
                                                                                            onClick={() => handleEnableDisabledAddon(currentAddOnID, 0)}
                                                                                            disabled={disabledButton}
                                                                                        >
                                                                                            <>
                                                                                                {t("Disabled Button Label")}
                                                                                            </>
                                                                                        </Button>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <Button
                                                                                            variant="primary"
                                                                                            onClick={() => handleEnableDisabledAddon(currentAddOnID, 1)}
                                                                                            disabled={disabledButton}
                                                                                        >
                                                                                            <>
                                                                                                {t("Enable Button Label")}
                                                                                            </>
                                                                                        </Button>
                                                                                    </>
                                                                                )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {currentWebsite
                                                                                && purchasedAddOnDetails
                                                                                && purchasedAddOnDetails.length > 0 // check if purchased details available
                                                                                && purchasedAddOnDetails[0].purchased === 1
                                                                                && purchasedAddOnDetails[0].cancel_date === null // check if plan is not cancelled
                                                                                && (
                                                                                    <>
                                                                                        <PaymentButtonBlock
                                                                                            buttonType={3}
                                                                                            activePlanType={4}
                                                                                            activePlanDomain={currentWebsite.domain}
                                                                                            activePlanInterval={purchasedAddOnDetails[0].package_duration_type}
                                                                                            activePlanPrice={purchasedAddOnDetails[0].monthly_price}
                                                                                            websiteID={currentWebsite.id}
                                                                                            activeUserID={currentWebsite.user_id}
                                                                                            activePlanID={currentAddOnID}
                                                                                            buttonBigSize={true}
                                                                                            buttonVariant={`link`}
                                                                                            buttonLabel={`Cancel Plan`}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                        </>
                                                                    )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {currentWebsite
                                                                    && purchasedAddOnDetails
                                                                    && purchasedAddOnDetails.length > 0 // check if purchased details available
                                                                    && purchasedAddOnDetails[0].purchased === 1
                                                                    && purchasedAddOnDetails[0].cancel_date === null // check if plan is not cancelled
                                                                    && (
                                                                        <>
                                                                            <PaymentButtonBlock
                                                                                buttonType={3}
                                                                                activePlanType={4}
                                                                                activePlanDomain={currentWebsite.domain}
                                                                                activePlanInterval={purchasedAddOnDetails[0].package_duration_type}
                                                                                activePlanPrice={purchasedAddOnDetails[0].monthly_price}
                                                                                websiteID={currentWebsite.id}
                                                                                activeUserID={currentWebsite.user_id}
                                                                                activePlanID={currentAddOnID}
                                                                                buttonBigSize={false}
                                                                                buttonVariant={`danger`}
                                                                                buttonLabel={`Cancel Plan`}
                                                                            />
                                                                        </>
                                                                    )}
                                                            </>
                                                        )}
                                                </>
                                            )}


                                    </div>

                                </div>

                                <div className={`aioa_dashboard-addon-details aioa_dashboard-settings-with-preview-wrapper ${currentWebsitePlanStatus.isFreeWidget ? `website-init-msg` : ``}`}>
                                    {currentWebsitePlanStatus.isFreeWidget ? <FreeVersionOverlay /> : <></>}
                                    <Row>
                                        <Col>
                                            <div className="aioa_dashboard-addon-details-inner">

                                                <div className="addon-description">{t(`${currentAddOnDetails[0].name} Text`)}</div>

                                                {purchasedAddOnDetails
                                                    && purchasedAddOnDetails.length > 0 // check if purchased details available
                                                    && purchasedAddOnDetails[0].purchased === 1
                                                    && purchasedAddOnDetails[0].cancel_date !== null // check if plan is cancelled
                                                    && (
                                                        <Alert className='aioa_dashboard-plan-status-alert' variant="danger">
                                                            <strong>Your Add-On will be automatically cancel on {purchasedAddOnDetails[0].expired_date ? parseISODate(purchasedAddOnDetails[0].expired_date) : '-'}</strong>
                                                        </Alert>
                                                    )}

                                                {isPurchasedAddOn ? (
                                                    <>
                                                        {currentAddOnID === 6 && ( // Custom Branding
                                                            <>
                                                                <CustomBrandingService />
                                                            </>
                                                        )}

                                                        {currentAddOnID === 9 && ( // Modify Menu
                                                            <>
                                                                <ModifyAccessibilityMenu
                                                                    setUpdatedMenuList={setUpdatedMenuList}
                                                                    setUpdatedLanguagesList={setUpdatedLanguagesList}
                                                                    setUpdatedOtherList={setUpdatedOtherList}
                                                                    setUpdatedAccessibilityProfilesList={setUpdatedAccessibilityProfilesList}
                                                                />
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {user
                                                            && user.department === 4 // partner user
                                                            && (currentAddOnID === 5 // white label
                                                                || currentAddOnID === 6 // custom branding
                                                            ) ? (
                                                            <>

                                                            </>
                                                        ) : (


                                                            <>
                                                                <div className="aioa_dashboard-addon-plan-list" data-currentAddOnID={currentAddOnID}>
                                                                    <Row xs={1} md={2} xl={(currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 23 : 25) || currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 24 : 26) || currentAddOnID === 35) ? 5 : 4} className="gy-4 gy-xl-0 gx-xl-0">

                                                                        {currentAddOnID === 5 ? ( // For White Label 
                                                                            <>
                                                                                {purchasedAddOnDetails
                                                                                    && purchasedAddOnDetails.length > 0
                                                                                    && purchasedAddOnDetails[0].purchased === 1 // check if plan purchased
                                                                                    ? (
                                                                                        <>
                                                                                            {purchasedAddOnDetails
                                                                                                && purchasedAddOnDetails.length > 0
                                                                                                && purchasedAddOnDetails[0].package_duration_type === "M" // check if plan duration is monthly
                                                                                                ? (
                                                                                                    <>
                                                                                                        <Col>
                                                                                                            <Card className="aioa_dashboard-widget-plan-box">
                                                                                                                <Card.Header className="h5">{t('Yearly Plan')}</Card.Header>
                                                                                                                <Card.Body>
                                                                                                                    <div className="aioa_dashboard-widget-plan-box-price">
                                                                                                                        <div className="aioa_dashboard-widget-plan-box-offer-price">${currentAddOnDetails[0].price}<span>/{t('Per Year')}</span></div>
                                                                                                                    </div>
                                                                                                                    <div className="aioa_dashboard-widget-plan-box-btn">
                                                                                                                        <Button
                                                                                                                            variant="outline-primary"
                                                                                                                            size="lg"
                                                                                                                            onClick={() => handleWhiteLabelPackagePurchase(currentAddOnDetails[0].id, currentAddOnDetails[0].price, 'Y')}
                                                                                                                            disabled={disabledButton}
                                                                                                                        >
                                                                                                                            {t('Select Plan')}
                                                                                                                        </Button>
                                                                                                                    </div>
                                                                                                                </Card.Body>
                                                                                                            </Card>
                                                                                                        </Col>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>

                                                                                                    </>
                                                                                                )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <Col>
                                                                                                <Card className="aioa_dashboard-widget-plan-box">
                                                                                                    <Card.Header className="h5">{t('Monthly Plan')}</Card.Header>
                                                                                                    <Card.Body>
                                                                                                        <div className="aioa_dashboard-widget-plan-box-price">
                                                                                                            <div className="aioa_dashboard-widget-plan-box-offer-price">${currentAddOnDetails[0].monthly_price}<span>/{t('Per Month')}</span></div>
                                                                                                        </div>
                                                                                                        <div className="aioa_dashboard-widget-plan-box-btn">
                                                                                                            <Button
                                                                                                                variant="outline-primary"
                                                                                                                size="lg"
                                                                                                                onClick={() => handleWhiteLabelPackagePurchase(currentAddOnDetails[0].id, currentAddOnDetails[0].monthly_price, 'M')}
                                                                                                                disabled={disabledButton}
                                                                                                            >
                                                                                                                {t('Select Plan')}
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    </Card.Body>
                                                                                                </Card>
                                                                                            </Col>
                                                                                            <Col>
                                                                                                <Card className="aioa_dashboard-widget-plan-box">
                                                                                                    <Card.Header className="h5">{t('Yearly Plan')}</Card.Header>
                                                                                                    <Card.Body>
                                                                                                        <div className="aioa_dashboard-widget-plan-box-price">
                                                                                                            <div className="aioa_dashboard-widget-plan-box-offer-price">${currentAddOnDetails[0].price}<span>/{t('Per Year')}</span></div>
                                                                                                        </div>
                                                                                                        <div className="aioa_dashboard-widget-plan-box-btn">
                                                                                                            <Button
                                                                                                                variant="outline-primary"
                                                                                                                size="lg"
                                                                                                                onClick={() => handleWhiteLabelPackagePurchase(currentAddOnDetails[0].id, currentAddOnDetails[0].price, 'Y')}
                                                                                                                disabled={disabledButton}
                                                                                                            >
                                                                                                                {t('Select Plan')}
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    </Card.Body>
                                                                                                </Card>
                                                                                            </Col>
                                                                                        </>
                                                                                    )}
                                                                            </>
                                                                        ) : currentAddOnID === 9 ? ( // For Modify accessibility Menu
                                                                            <>
                                                                                {purchasedAddOnDetails
                                                                                    && purchasedAddOnDetails.length > 0 // check if plan purchased
                                                                                    && purchasedAddOnDetails[0].purchased === 1
                                                                                    ? (
                                                                                        <>
                                                                                            {purchasedAddOnDetails
                                                                                                && purchasedAddOnDetails.length > 0
                                                                                                && purchasedAddOnDetails[0].package_duration_type === "M" // check if monthly plan purchased
                                                                                                ? (
                                                                                                    <>
                                                                                                        <Col>
                                                                                                            <Card className="aioa_dashboard-widget-plan-box">
                                                                                                                <Card.Header className="h5">{t('Yearly Plan')}</Card.Header>
                                                                                                                <Card.Body>
                                                                                                                    <div className="aioa_dashboard-widget-plan-box-price">
                                                                                                                        <div className="aioa_dashboard-widget-plan-box-offer-price">${currentAddOnDetails[0].price}<span>/{t('Per Year')}</span></div>
                                                                                                                    </div>
                                                                                                                    <div className="aioa_dashboard-widget-plan-box-btn">
                                                                                                                        <Button
                                                                                                                            variant="outline-primary"
                                                                                                                            size="lg"
                                                                                                                            onClick={() => handleWhiteLabelPackagePurchase(currentAddOnDetails[0].id, currentAddOnDetails[0].price, 'Y')}
                                                                                                                            disabled={disabledButton}
                                                                                                                        >
                                                                                                                            {t('Select Plan')}
                                                                                                                        </Button>
                                                                                                                    </div>
                                                                                                                </Card.Body>
                                                                                                            </Card>
                                                                                                        </Col>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>

                                                                                                    </>
                                                                                                )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <Col>
                                                                                                <Card className="aioa_dashboard-widget-plan-box">
                                                                                                    <Card.Header className="h5">{t('Monthly Plan')}</Card.Header>
                                                                                                    <Card.Body>
                                                                                                        <div className="aioa_dashboard-widget-plan-box-price">
                                                                                                            <div className="aioa_dashboard-widget-plan-box-offer-price">${currentAddOnDetails[0].monthly_price}<span>/{t('Per Month')}</span></div>
                                                                                                        </div>
                                                                                                        <div className="aioa_dashboard-widget-plan-box-btn">
                                                                                                            <Button
                                                                                                                variant="outline-primary"
                                                                                                                size="lg"
                                                                                                                onClick={() => handleWhiteLabelPackagePurchase(currentAddOnDetails[0].id, currentAddOnDetails[0].monthly_price, 'M')}
                                                                                                                disabled={disabledButton}
                                                                                                            >
                                                                                                                {t('Select Plan')}
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    </Card.Body>
                                                                                                </Card>
                                                                                            </Col>
                                                                                            <Col>
                                                                                                <Card className="aioa_dashboard-widget-plan-box">
                                                                                                    <Card.Header className="h5">{t('Yearly Plan')}</Card.Header>
                                                                                                    <Card.Body>
                                                                                                        <div className="aioa_dashboard-widget-plan-box-price">
                                                                                                            <div className="aioa_dashboard-widget-plan-box-offer-price">${currentAddOnDetails[0].price}<span>/{t('Per Year')}</span></div>
                                                                                                        </div>
                                                                                                        <div className="aioa_dashboard-widget-plan-box-btn">
                                                                                                            <Button
                                                                                                                variant="outline-primary"
                                                                                                                size="lg"
                                                                                                                onClick={() => handleWhiteLabelPackagePurchase(currentAddOnDetails[0].id, currentAddOnDetails[0].price, 'Y')}
                                                                                                                disabled={disabledButton}
                                                                                                            >
                                                                                                                {t('Select Plan')}
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    </Card.Body>
                                                                                                </Card>
                                                                                            </Col>
                                                                                        </>
                                                                                    )}
                                                                            </>
                                                                        ) : (
                                                                            <>

                                                                                {currentAddOnDetails
                                                                                    && currentAddOnDetails.length > 0
                                                                                    && currentAddOnDetails[0].package_prices // check if add-on pricing is available
                                                                                    && currentAddOnDetails[0].package_prices.length > 0
                                                                                    && (
                                                                                        <>
                                                                                            {currentAddOnDetails[0].package_prices.map((item) => {
                                                                                                return (
                                                                                                    <Col key={item.id}>
                                                                                                        <Card className="aioa_dashboard-widget-plan-box">
                                                                                                            <Card.Header className="h5">
                                                                                                                {currentAddOnID === 8 //Live Site Translation
                                                                                                                    ? (
                                                                                                                        <>
                                                                                                                            {t('x pages', { number: item.pages, defaultValue: `${item.pages} Pages` })}
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            {t('x pages', { number: item.pages, defaultValue: `${item.pages} Pages` })} / {t('x templates', { number: item.pages, defaultValue: `${(item.name === "Small Site") ? 4 : (item.name === "Medium Site") ? 12 : (item.name === "Large Site") ? 20 : (item.name === "Very Small Site") ? 2 : 40} Templates` })}
                                                                                                                        </>
                                                                                                                    )}

                                                                                                            </Card.Header>
                                                                                                            <Card.Body>
                                                                                                                <div className="aioa_dashboard-widget-plan-box-description">
                                                                                                                    {currentAddOnID === 8 //Live Site Translation
                                                                                                                        ? (
                                                                                                                            <>
                                                                                                                                <ul>
                                                                                                                                    <li> {item.monthly_price === 25
                                                                                                                                        ? t('up-to-k-characters', { number: 500, defaultValue: 'Up to 500K characters' })
                                                                                                                                        : ((item.monthly_price === 50)
                                                                                                                                            ? t('up-to-m-characters', { number: 1, defaultValue: 'Up to 1 million characters' })
                                                                                                                                            : item.monthly_price === 200
                                                                                                                                                ? t('up-to-m-characters', { number: 5, defaultValue: 'Up to 5 million characters' })
                                                                                                                                                : t('up-to-m-characters', { number: 10, defaultValue: 'Up to 10 million characters' }))}</li>
                                                                                                                                </ul>
                                                                                                                            </>
                                                                                                                        ) : (currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 23 : 25) // VPAT report
                                                                                                                            || currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 24 : 26))  // Manual Audit Report
                                                                                                                            ? (
                                                                                                                                <>

                                                                                                                                    <ul>
                                                                                                                                        {
                                                                                                                                            (item.name === "Small Site") ? (
                                                                                                                                                <>
                                                                                                                                                    {currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 23 : 25) // VPAT report
                                                                                                                                                        && (<li>{t("Key templates")}</li>)}
                                                                                                                                                    <li>{t('usually-delivered-in', { number: 7, defaultValue: "Usually delivered in 7 business days" })}</li>
                                                                                                                                                </>
                                                                                                                                            ) : (item.name === "Medium Site") ? (
                                                                                                                                                <>
                                                                                                                                                    {currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 23 : 25) // VPAT report
                                                                                                                                                        && (<li>{t("Complete review")}</li>)}
                                                                                                                                                    <li>{t('usually-delivered-in', { number: 14, defaultValue: "Usually delivered in 14 business days" })}</li>
                                                                                                                                                </>
                                                                                                                                            ) : (item.name === "Large Site") ? (
                                                                                                                                                <>
                                                                                                                                                    {currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 23 : 25) // VPAT report
                                                                                                                                                        && (<li>{t("Complete review")}</li>)}
                                                                                                                                                    <li>{t('usually-delivered-in', { number: 28, defaultValue: "Usually delivered in 28 business days" })}</li>
                                                                                                                                                </>
                                                                                                                                            ) : (item.name === "Very Small Site") ? (
                                                                                                                                                <>
                                                                                                                                                    {currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 23 : 25) // VPAT report
                                                                                                                                                        && (<li>{t("Key templates")}</li>)}
                                                                                                                                                    <li>{t('usually-delivered-in', { number: 5, defaultValue: "Usually delivered in 5 business days" })}</li>
                                                                                                                                                </>
                                                                                                                                            ) : (
                                                                                                                                                <>
                                                                                                                                                    {currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 23 : 25) // VPAT report
                                                                                                                                                        && (<li>{t("Complete review")}</li>)}
                                                                                                                                                    <li>{t('usually-delivered-in', { number: 70, defaultValue: "Usually delivered in 70 business days" })}</li>
                                                                                                                                                </>
                                                                                                                                            )}
                                                                                                                                    </ul>
                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                <>
                                                                                                                                    <ul>
                                                                                                                                        <li>
                                                                                                                                            {t('usually-delivered-in-weeks',
                                                                                                                                                {
                                                                                                                                                    number: ((item.name === "Very Small Site") ? `1`
                                                                                                                                                        : (item.name === "Small Site") ? `2`
                                                                                                                                                            : (item.name === "Medium Site") ? `4`
                                                                                                                                                                : (item.name === "Large Site") ? `11`
                                                                                                                                                                    : `22`),
                                                                                                                                                    defaultValue: `Usually delivered in ${((item.name === "Very Small Site") ? `1`
                                                                                                                                                        : (item.name === "Small Site") ? `2`
                                                                                                                                                            : (item.name === "Medium Site") ? `4`
                                                                                                                                                                : (item.name === "Large Site") ? `11` : `22`)} week(s)`
                                                                                                                                                })}
                                                                                                                                        </li>
                                                                                                                                    </ul>
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                </div>
                                                                                                                <div className="aioa_dashboard-widget-plan-box-price">
                                                                                                                    {currentAddOnID === 8 // Live site translations
                                                                                                                        ? (
                                                                                                                            <>
                                                                                                                                <div className="aioa_dashboard-widget-plan-box-offer-price">${item.monthly_price}<span>/{t('Per Month')}</span></div>
                                                                                                                            </>
                                                                                                                        ) : (
                                                                                                                            <>
                                                                                                                                <div className="aioa_dashboard-widget-plan-box-offer-price">${item.price}</div>
                                                                                                                            </>
                                                                                                                        )}

                                                                                                                </div>
                                                                                                                <div className="aioa_dashboard-widget-plan-box-btn">
                                                                                                                    {currentAddOnID && (
                                                                                                                        <>
                                                                                                                            {currentAddOnID === 8 // Live site translations
                                                                                                                                ? (
                                                                                                                                    <>
                                                                                                                                        <Button
                                                                                                                                            variant="outline-primary"
                                                                                                                                            size="lg"
                                                                                                                                            onClick={() => handleSubAddOnPackagePurchase(currentAddOnID, "M", item.id)}
                                                                                                                                            disabled={disabledButton}
                                                                                                                                        >
                                                                                                                                            {t('Select Plan')}
                                                                                                                                        </Button>
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    <>
                                                                                                                                        <Button
                                                                                                                                            variant="outline-primary"
                                                                                                                                            size="lg"
                                                                                                                                            onClick={() => handleSubAddOnPackagePurchase(currentAddOnID, "Y", item.id)}
                                                                                                                                            disabled={disabledButton}
                                                                                                                                        >
                                                                                                                                            {t('Buy Now')}
                                                                                                                                        </Button>
                                                                                                                                    </>
                                                                                                                                )}
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </Card.Body>
                                                                                                        </Card>
                                                                                                    </Col>
                                                                                                )
                                                                                            })}
                                                                                        </>
                                                                                    )}
                                                                            </>
                                                                        )}


                                                                    </Row>
                                                                    {
                                                                        (currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 23 : 25) // VPAT Report
                                                                            || currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 24 : 26) // Manual Audit Report
                                                                            || currentAddOnID === 35 //Manual Remediation Report
                                                                        )
                                                                        && (
                                                                            <Row>
                                                                                <Col>
                                                                                    <Card className="aioa_dashboard-widget-plan-box all-plans-details">
                                                                                        {(currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 24 : 26) // Manual Audit Report
                                                                                            || currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 23 : 25) // VPAT Report
                                                                                        ) ?
                                                                                            <Card.Header className="h5">{t('All plans include:')}
                                                                                                {currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 23 : 25) // VPAT Report
                                                                                                    && (
                                                                                                        <>
                                                                                                            Forms, Videos, Audio & Documents, Dynamic elements
                                                                                                        </>
                                                                                                    )}
                                                                                            </Card.Header>
                                                                                            : <></>
                                                                                        }
                                                                                        <Card.Body>
                                                                                            <div className="aioa_dashboard-widget-plan-box-description">
                                                                                                {currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 24 : 26) // Manual Audit Report
                                                                                                    && (
                                                                                                        <>
                                                                                                            <ul className="two-col-list mb-4">
                                                                                                                <li>WCAG 2.0 / WCAG 2.1 / WCAG 2.2 Level AA conformance testing</li>
                                                                                                                <li className="d-inline-flex align-items-center"><span>Automated, semi-automated testing</span>
                                                                                                                    <OverlayTrigger
                                                                                                                        placement="bottom"
                                                                                                                        overlay={<Tooltip id="button-tooltip-2">Automated, semi-automated testing using tools like WAVE and Google Lighthouse</Tooltip>}

                                                                                                                    ><span className="material-symbols-outlined fs-6 ms-1">help</span></OverlayTrigger>
                                                                                                                </li>
                                                                                                                <li className="d-inline-flex align-items-center"><span>Manual testing</span>
                                                                                                                    <OverlayTrigger
                                                                                                                        placement="bottom"
                                                                                                                        overlay={<Tooltip id="button-tooltip-2">Manual testing using assistive technologies, web browsers, operating systems, and screen readers like NVDA, VoiceOver, and JAWS.</Tooltip>}

                                                                                                                    ><span className="material-symbols-outlined fs-6 ms-1">help</span></OverlayTrigger>
                                                                                                                </li>
                                                                                                                <li>Simple before-after UI/UX recommendations on how to fix the issues.</li>
                                                                                                                <li className="d-inline-flex align-items-center"><span>Comprehensive audit report</span>
                                                                                                                    <OverlayTrigger
                                                                                                                        placement="bottom"
                                                                                                                        overlay={<Tooltip id="button-tooltip-2">Comprehensive audit report with all accessibility violations, with the type of update required.</Tooltip>}

                                                                                                                    ><span className="material-symbols-outlined fs-6 ms-1">help</span></OverlayTrigger>
                                                                                                                </li>
                                                                                                                <li>Two hours of free consultation with our ADA experts to review the audit report and ways to fix the issues.</li>
                                                                                                                <li>Multimedia and Documents Audit</li>
                                                                                                            </ul>
                                                                                                            <p><strong>Note</strong>: Once a purchase is done, to kickstart the manual audit you need to email us website access credentials at <a href="mailto:hello@skynettechnologies.com">hello@skynettechnologies.com</a> or visit <a href="https://www.skynettechnologies.com/website-accessibility-audit" target="_blank">https://www.skynettechnologies.com/website-accessibility-audit</a> if you have any questions before making the purchase.</p>
                                                                                                        </>
                                                                                                    )}
                                                                                                {currentAddOnID === ((process.env.REACT_APP_BASE_URL?.includes("staging") || process.env.REACT_APP_BASE_URL?.includes("free")) ? 23 : 25) // VPAT Report
                                                                                                    && (
                                                                                                        <p className="mb-0 text-center"><strong>Note</strong>: Once a purchase is done, to kickstart the VPAT Report or Accessibility Conformance Report (ACR) please email us manual audit report at <a href="mailto:hello@skynettechnologies.com">hello@skynettechnologies.com</a> or visit <a href="https://www.skynettechnologies.com/vpat-accessibility-conformance-report" target="_blank">https://www.skynettechnologies.com/vpat-accessibility-conformance-report</a> if you have any questions before making the purchase.</p>
                                                                                                    )}

                                                                                                {currentAddOnID === 35 // Manual Remediation
                                                                                                    && (
                                                                                                        <p className="mb-0 text-center"><strong>Note</strong>: Once a purchase is done, to kickstart the manual accessibility remediation please email us manual audit report and website access credentials at <a href="mailto:hello@skynettechnologies.com">hello@skynettechnologies.com</a> or visit <a href="https://www.skynettechnologies.com/ada-website-accessibility" target="_blank">https://www.skynettechnologies.com/ada-website-accessibility</a> if you have any questions before making the purchase.</p>
                                                                                                    )}
                                                                                            </div>
                                                                                        </Card.Body>
                                                                                    </Card>
                                                                                </Col>
                                                                            </Row>
                                                                        )}
                                                                </div>
                                                            </>


                                                        )}
                                                    </>
                                                )}

                                            </div>
                                        </Col>



                                        {(windowSize.windowSize > 1199)
                                            && (isPurchasedAddOn
                                                && (currentAddOnID === 5 // White Label
                                                    || currentAddOnID === 6 // Custom Branding
                                                    || currentAddOnID === 9 // Modify Accessibility Menu
                                                ))
                                            && (
                                                <>
                                                    <Col className="aioa-dashboard-widget-preview-col">
                                                        <WidgetLayoutMain
                                                            updatedMenuList={updatedMenuList}
                                                            updatedLanguagesList={updatedLanguagesList}
                                                            updatedOtherList={updatedOtherList}
                                                            updatedAccessibilityProfilesList={updatedAccessibilityProfilesList}
                                                            isShowLangModal={true}
                                                        />
                                                    </Col>
                                                </>
                                            )}

                                    </Row>
                                </div>

                                {isPurchasedAddOn
                                    && currentAddOnID !== null
                                    && currentAddOnID !== 5 // white label
                                    && currentAddOnID !== 6 // custom branding
                                    && currentAddOnID !== 8 // live site translation
                                    && currentAddOnID !== 9 // modify accessibility menu
                                    && currentAddOnID !== 0 && ( // AddOn Reports
                                        <>
                                            <div className={`aioa_dashboard-addon-report-list`}>

                                                <div className="aioa_dashboard-addon-report-filter-wrapper">
                                                    <div className="caption-text h5 mb-0">All Report(s)</div>
                                                    <div className="aioa_dashboard-addon-report-list-filter-wrapper">
                                                        <div className="aioa_dashboard-addon-report-list-filter-block">
                                                            <Form.Label htmlFor="tabFilter">{t("Filter")}</Form.Label>
                                                            <Form.Select
                                                                //size="lg"
                                                                onChange={(e) => { setFilter(e.target.value) }}
                                                                id="tabFilter"
                                                                value={filter}
                                                                aria-label={t("Filter").toString()}
                                                            >
                                                                <option value="-1">{t("All")}</option>
                                                                <option value="0">{t("Processing")}</option>
                                                                <option value="1">{t("Completed")}</option>
                                                            </Form.Select>
                                                        </div>

                                                        <div className="aioa_dashboard-addon-report-list-filter-block">
                                                            <Form.Label htmlFor='showPages'>{t("Items Per Page")}</Form.Label>
                                                            <Form.Select
                                                                //size="lg"
                                                                onChange={(e) => setLimit(parseInt(e.target.value))}
                                                                id="showPages"
                                                                value={limit}
                                                                aria-label={t("Items Per Page").toString()}
                                                            >
                                                                <option value="10">{t("10")}</option>
                                                                <option value="20">{t("20")}</option>
                                                                <option value="40">{t("40")}</option>
                                                                <option value="80">{t("80")}</option>
                                                                <option value="100">{t("100")}</option>
                                                            </Form.Select>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="aioa_dashboard-table">
                                                    <div className="aioa_dashboard-table-thead">
                                                        <div className="aioa_dashboard-table-tr">
                                                            <div className="aioa_dashboard-table-th tcw-100 aioa_dashboard-table-cell-sidegap">{t("Sr No")}</div>
                                                            <div className="aioa_dashboard-table-th tcw-auto aioa_dashboard-table-cell-sidegap">{t("Your Plan")}</div>
                                                            <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Pages")}</div>
                                                            <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Status")}</div>
                                                            <div className="aioa_dashboard-table-th tcw-200 text-center aioa_dashboard-table-cell-sidegap">{t("Action")}</div>
                                                        </div>
                                                    </div>

                                                    <div className="aioa_dashboard-table-body">
                                                        {reportList ? (
                                                            <>
                                                                {reportList.length > 0 ? (
                                                                    <>
                                                                        {reportList.map((reportItem, index) => {
                                                                            return (
                                                                                <div className="aioa_dashboard-table-tr" key={reportItem.id}>
                                                                                    <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                                                                                        <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Sr No")}</div>
                                                                                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Sr No").toString()}>{offset + 1 + index}</div>
                                                                                    </div>
                                                                                    <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap">
                                                                                        <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Your Plan")}</div>
                                                                                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Your Plan").toString()}>
                                                                                            <div><strong>{t('Manual Remediation for')}</strong> : {reportItem.domain}</div>
                                                                                            <div>
                                                                                                <strong>{t('Plan Table Label')}</strong>&nbsp;:&nbsp;{reportItem.price_details.name}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                                                                                        <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Pages")}</div>
                                                                                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Pages").toString()}>{reportItem.price_details.pages}</div>
                                                                                    </div>
                                                                                    <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                                                                                        <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Status")}</div>
                                                                                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Status").toString()}>
                                                                                            <Badge bg={`${reportItem.vpat_status === 1 ? `success` : `warning`}`}>
                                                                                                <span>{reportItem.vpat_status === 1 ? t("completed") : t("Processing")}</span>
                                                                                            </Badge>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="aioa_dashboard-table-td tcw-200 text-center aioa_dashboard-table-cell-sidegap">
                                                                                        <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Action")}</div>
                                                                                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Action").toString()}>
                                                                                            {reportItem.vpat_status === 1 ? (
                                                                                                <>
                                                                                                    <div className='report-action'>
                                                                                                        <OverlayTrigger
                                                                                                            delay={{ show: 250, hide: 400 }}
                                                                                                            overlay={<Tooltip id="email-tooltip">{t("Download Report")}</Tooltip>}
                                                                                                        >
                                                                                                            <a
                                                                                                                href={`${baseURL}/storage/app/manual_audit_vpat/${reportItem.vpat_file}`}
                                                                                                                className='btn btn-primary'
                                                                                                                aria-label={t("Download Report").toString()}
                                                                                                                target="_blank"
                                                                                                            >
                                                                                                                <i className="material-symbols-outlined" aria-hidden="true">download</i>
                                                                                                            </a>
                                                                                                        </OverlayTrigger>

                                                                                                        <OverlayTrigger
                                                                                                            delay={{ show: 250, hide: 400 }}
                                                                                                            overlay={<Tooltip id="email-tooltip">{t("Email Report")}</Tooltip>}
                                                                                                        >
                                                                                                            <Button
                                                                                                                variant='secondary'
                                                                                                                aria-label={t("Email Report").toString()}
                                                                                                                onClick={() => handleShowReportMailModal(reportItem.id)}
                                                                                                            >
                                                                                                                <>
                                                                                                                    <i className="material-symbols-outlined" aria-hidden="true">mail</i>
                                                                                                                </>
                                                                                                            </Button>
                                                                                                        </OverlayTrigger>
                                                                                                        {user && user.department === 1 // admin user
                                                                                                            && (
                                                                                                                <>
                                                                                                                    <OverlayTrigger
                                                                                                                        delay={{ show: 250, hide: 400 }}
                                                                                                                        overlay={<Tooltip id="email-tooltip">{t("Upload Report File")}</Tooltip>}
                                                                                                                    >
                                                                                                                        <Button
                                                                                                                            variant='warning'
                                                                                                                            aria-label={t("Upload Report File").toString()}
                                                                                                                            onClick={() => { handleShowReUploadReportModal(reportItem.id) }}
                                                                                                                        >
                                                                                                                            <>
                                                                                                                                <i className="material-symbols-outlined" aria-hidden="true">upload_file</i>
                                                                                                                            </>
                                                                                                                        </Button>
                                                                                                                    </OverlayTrigger>
                                                                                                                </>
                                                                                                            )}


                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {user && user.department === 1 // admin user
                                                                                                        ? (
                                                                                                            <>
                                                                                                                <ReportFileUploadElement
                                                                                                                    reportID={reportItem.id}
                                                                                                                    //setLoading={setReportListLoading}
                                                                                                                    setReportList={setReportList}
                                                                                                                    limit={limit}
                                                                                                                    offset={offset}
                                                                                                                    setTotalRecord={setTotalRecord}
                                                                                                                    filter={filter}
                                                                                                                    addOnID={currentAddOnID}
                                                                                                                />
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                -
                                                                                                            </>
                                                                                                        )}
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <NoRecordFound imageType={`pdf`} />
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>

                                                            </>
                                                        )}

                                                    </div>
                                                    <div className="aioa_dashboard-table-pagination-main">
                                                        <>
                                                            <ShowRecordItem
                                                                offset={offset}
                                                                limit={limit}
                                                                totalRecord={totalRecord}
                                                            />
                                                            <PaginationComponent
                                                                currentPage={currentPage}
                                                                totalRecords={totalRecord}
                                                                itemsPerPage={limit}
                                                                setCurrentPage={setCurrentPage}
                                                            />
                                                        </>
                                                    </div>
                                                </div>

                                                <Modal show={isReUploadReportModal} onHide={handleHideReUploadReportModal} size="lg" fullscreen="lg-down" backdrop="static" centered>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Report File Upload</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="mb-3 text-danger"><strong>Would you like to re-upload the report? Please note that it will overwrite the previous report.</strong></div>
                                                        <ReportFileUploadElement
                                                            reportID={currentReportID}
                                                            setReportList={setReportList}
                                                            limit={limit}
                                                            offset={offset}
                                                            setTotalRecord={setTotalRecord}
                                                            filter={filter}
                                                            addOnID={currentAddOnID}
                                                            ispopup={true}
                                                            handleHideFileUploadPopupPopup={handleHideReUploadReportModal}
                                                        />
                                                    </Modal.Body>
                                                </Modal>

                                                <Modal show={isShowMailConfirmModal} onHide={handleHideReportMailModal} size="lg" fullscreen="lg-down" backdrop="static" centered>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Email PDF ({currentAddOnDetails[0].name})</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <span>Would you like to send a copy of this document to your email address, </span>
                                                        <Form>
                                                            <InputGroup className="mt-3 input-group-outline">
                                                                <Form.Control
                                                                    placeholder={`${t("Enter Email Address")}`}
                                                                    aria-label={`${t("Enter Email Address")}`}
                                                                    type="email"
                                                                    size="lg"
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserEmail(e.target.value)} value={userEmail}
                                                                />
                                                            </InputGroup>
                                                        </Form>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleHideReportMailModal}>
                                                            {t('Cancel')}
                                                        </Button>
                                                        <Button variant="primary" onClick={handleSendReportSubmit}>
                                                            {t('Email PDF')}
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>

                                            </div>
                                        </>
                                    )}

                            </>
                        )}

                    </>
                )}

            </DashboardPagesTemplate>
        </>
    )
}

export default AddOnDetailsPage;