import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { packageEnableDisableApi } from "../../Api/PlanListApi";
import { DomainDetailsService } from "../DomainService/DomainDetails";

// service to enable/disable add-ons
const addOnEnableDisableService = (
    accessToken: string, // access token
    website_id: number, // website ID
    package_id: number, // package Id
    package_status: number, // enable or disable flag
    dispatch: Function, // dispatch function to store redux data
    t: Function, // i18next t function
) => {

    // Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // API to enable/disable add-on
    packageEnableDisableApi(accessToken, website_id, package_id, package_status).then(response => {
        // check if response data is available
        if (response.Data) {
            // service to get domain details
            DomainDetailsService(accessToken, website_id, dispatch).then((res) => {

                // check if response is ok
                if (res.status === 200) {

                    // remove Loading
                    dispatch({
                        type: LOADING,
                        payload: false,
                    });

                    // dispatch success message
                    dispatch({
                        type: SET_MESSAGE,
                        payload: {
                            "toast_message": (package_status === 1) ? t('Add-On Enable') : t('Add-On Disable'),
                            "toast_type": (package_status === 1) ? 'success' : 'danger'
                        }
                    });
                }
            });

        } else {

            // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            // dispatch error message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Error sending request for Add-On enable/disable. Please try again later.", { returnObjects: true }),
                    "toast_type": "danger"
                }
            });
        }
    })
}



export { addOnEnableDisableService }