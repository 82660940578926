//const baseURL = `${process.env.REACT_APP_BASE_URL}/api`;
const baseURL = `https://ada.skynettechnologies.us/api`;

let headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

// gets list of languages
const getLanguagesApi = async (): Promise<any> => {
    
    const url = `${baseURL}/active-language`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}

// get Translations Api
const getTranslationsApi = async (
    languageCode:string // ISO language code
): Promise<any> => {
    
    const url = `${baseURL}/translation/${languageCode}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export {getLanguagesApi, getTranslationsApi};