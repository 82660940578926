import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface PropTypes {
    setChangeWidgetAccessibilityStatementLink: Function // function to set statement link value
}

// component to render widget accessibility statement link
const WidgetAccessibilityStatementLink = (props: PropTypes) => {

    // Props
    const { setChangeWidgetAccessibilityStatementLink } = props;

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();

    // Stets
    const [widgetAccessibilityStatementLink, setWidgetAccessibilityStatementLink] = useState<string>('');
    const [customURLValidation, setCustomURLValidation] = useState<boolean>(false);
    const [customURLValidationMsg, setCustomURLValidationMsg] = useState<string>('');

    // Set current widget icon type
    useEffect(() => {
        // check if current website is available
        if (currentWebsite) {
            // check if statement link is available
            if (currentWebsite.statement_link) {
                setWidgetAccessibilityStatementLink(currentWebsite.statement_link);
            } else {
                setWidgetAccessibilityStatementLink('');
            }
        }
    }, [currentWebsite]);

   // function to validate statement URL
    const URLValidationEvent = (e: any) => {
        const inputValue = e.target.value;

        let httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

        // check if input value is blank
        if (inputValue === '') {
            setCustomURLValidation(false);
            setCustomURLValidationMsg('')
            setWidgetAccessibilityStatementLink(inputValue);
        } 
        // check if URL is invalid
        else if (!httpRegex.test(inputValue)) {
            setCustomURLValidation(true);
            setCustomURLValidationMsg(t('url validation msg'));
        } else {
            setCustomURLValidation(false);
            setCustomURLValidationMsg('')
            setWidgetAccessibilityStatementLink(inputValue);
        }
    }

    // set current statement link in prop function
    useEffect(() => {
        if (widgetAccessibilityStatementLink) {
            setChangeWidgetAccessibilityStatementLink(widgetAccessibilityStatementLink);
        } else {
            setChangeWidgetAccessibilityStatementLink('');
        }
    }, [widgetAccessibilityStatementLink]);

    
    return (
        <>
            <div className="aioa_dashboard-widget-settings-controls">
                <Form.Group className="custom-statement" controlId="custom_accessibility_statement">
                    <Form.Label>{t('accessibility statement link label text')}</Form.Label>
                    <Form.Control
                    size="lg"
                        type="text"
                        placeholder="https://www.yourwebsite.link/accessibility-statement"
                        defaultValue={widgetAccessibilityStatementLink}
                        onChange={(e) => URLValidationEvent(e)}
                        onBlur={(e) => URLValidationEvent(e)}
                    />
                </Form.Group>
                {customURLValidation && (
                    <>
                        <div className="text-danger"><strong>{customURLValidationMsg}</strong></div>
                    </>
                )}
            </div>
        </>
    )
}

export default WidgetAccessibilityStatementLink;