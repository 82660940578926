import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { bulkDomainListActionAPI, checkBulkDomainListAPI } from "../../Api/BulkDomainAPI";

// service to check validity of bulk domains
const checkBulkDomainListService = (
    accessToken: string, // access token
    domainListFile: File, // file
    dispatch: Function, // function to store redux data
    t: Function, // i18next t function
    setValidDomain: Function, // function to set valid domain list
    setInValidDomain: Function, // function to set invalid domain list
    setExistDomain: Function, // function to set existing domain list
    setDuplicateDomain: Function // function to set duplicate domain list
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // API to validate bulk domains
    checkBulkDomainListAPI(accessToken, domainListFile).then(response => {

        // check if response data is available
        if ((response.data && response.data.length > 0) || (response.invalidDomain && response.invalidDomain.length > 0) || (response.existDomain && response.existDomain.length > 0) || (response.duplicateDomains && response.duplicateDomains.length > 0)) {

            // Set All Data
            setValidDomain(response.data);
            setInValidDomain(response.invalidDomain);
            setExistDomain(response.existDomain);
            setDuplicateDomain(response.duplicateDomains);

            // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    toast_message: t("File upload successfully!"),
                    toast_type: 'success'
                }
            });
        }

        else {

            // Set All Data
            setValidDomain([]);
            setInValidDomain([]);
            setExistDomain([]);
            setDuplicateDomain([]);

            // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    toast_message: t("File is blank or invalid. Please check with sample file. Make sure your file header must be domain and plan."),
                    toast_type: 'danger'
                }
            });
        }

    })
}

// function to perform update on bulk domains
const bulkDomainListActionService = (
    accessToken: string, // access token
    objId: number, // domain id
    domain: string, // domain name
    planId: number, // plan id
    action: string, // action
    dispatch: Function, // dispatch function to store redux data
    t: Function, // i18next t function
    setValidDomain: Function, // function to set list of valid domain
    callBackFunction?: Function // callback function 
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // api to perform action on bulk domains
    bulkDomainListActionAPI(accessToken, objId, domain, planId, action).then(response => {
        if (response.data) {

            setValidDomain(response.data);
            callBackFunction && callBackFunction();

            // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    toast_message: t("Domain successfully updated."),
                    toast_type: 'success'
                }
            })
        }
        else {

            // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    toast_message: t("Domain not updated. please try again."),
                    toast_type: 'danger'
                }
            });

        }

    })
}

export { checkBulkDomainListService, bulkDomainListActionService }