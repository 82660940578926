import { useTranslation } from "react-i18next";
import DashboardPageTitle from "../../Components/InnerPageTitle";
import NormalUserDashboardBlock from "../../Components/NormalUserDashboard";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import PartnerUserDashboardBlock from "../../Components/PartnerUserDashboard";

// component to display dashboard page
const DashboardPage = () => {

  // Redux Data
  const { currentWebsite, user } = useSelector((store: StoreProptypes) => store);

  // Functions
  const { t } = useTranslation();
  

  return (
    <>
      <DashboardPagesTemplate>

        {user && user.department === 4 ? (
          <>
            {/* Partner User Dashboard */}
            <DashboardPageTitle
              pageTitle={`${t('Partner Dashboard')}`}
            />
            <PartnerUserDashboardBlock />
          </>
        ) : (
          <>
          {/* Normal User Dashboard */}
            <DashboardPageTitle
              pageTitle={`${t('dashboard page title')}`}
              subPageTitle={`${t('dashboard page sub title', { domainName: currentWebsite?.domain })}`}
              showFreeWidgetStrip={true}
            />
            <NormalUserDashboardBlock />
          </>
        )}


      </DashboardPagesTemplate>
    </>
  )
}

export default DashboardPage;