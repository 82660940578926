import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";

import Select from 'react-select';

import '../../Assets/scss/components/search-website-dropdown.scss';

interface select2WebsitePropTypes {
    "value": number,
    "label": string
}

interface PropTypes {
    selectedWebsiteID: Function // function to set website Id
}

// component to render search domain dropdown
const SearchDomainDropdownBlock = (props: PropTypes) => {

    // Props
    const { selectedWebsiteID } = props;

    // Function
    const { t } = useTranslation();

    // Redux Data
    const { websiteList, currentWebsite } = useSelector((store: StoreProptypes) => store);
    const [selectWebsiteData, setSelectWebsiteData] = useState<select2WebsitePropTypes[]>();

    // function to handle domain change
    const domainChange = (
        label: string | undefined, // website label 
        value: number | undefined // website value
    ) => {
        // check if label and value exists
        if (label && value) {
            selectedWebsiteID(value);
        } else {
            selectedWebsiteID(0);
        }
    }

    useEffect(() => {
        // check if website list exists
        if (websiteList && websiteList.length > 0) {
            let temp = websiteList.map((item) => {
                const domain = {
                    value: item.id,
                    label: item.domain
                }
                return domain;
            })
            setSelectWebsiteData([...temp]);
        }
    }, [currentWebsite, websiteList])

    return (
        <>
            <Form.Label htmlFor='domain_select' aria-hidden={true}>{t("Domains")}</Form.Label>
            <Select
                id="domain_select"
                //defaultValue={selectWebsiteData}
                onChange={(e) => domainChange(e?.label, e?.value)}
                options={selectWebsiteData}
                closeMenuOnSelect={true}
                backspaceRemovesValue={true}
                isClearable={true}
                isSearchable={true}
                placeholder={`${t('Select Domain Dropdown Label')}...`}
                className="aioa_dashboard-custom-domain-filter-select"
                aria-label={t("Domains").toString()}
            />
        </>
    )
}

export default SearchDomainDropdownBlock;