import { generatePDFApi } from "../../Api/DashboardPDFReportApi";

// service to handle generate PDF api
const generatePDFService = (
    token: string, // access token
    websiteID: number, // website ID
    languageCode: string, // active language code
    setLoading: Function, // set loading function 
    setSavePDFLink: Function, // set save pdf link
) => {

    setLoading(true);

    // api to get APIs
    generatePDFApi(token, websiteID, languageCode).then(response => {
        
        // check if api response is okay
        if (response.status === 200) {
            const pdfLink = response.data;
            // check if pdf link is available
            if (pdfLink) {
                setSavePDFLink(pdfLink);
            }
        }
        setLoading(false);
    })
}


export { generatePDFService }