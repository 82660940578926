import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import WidgetCustomSizeControl from "../WidgetCustomSizeControl";


interface PropTypes {
    setChangeWidgetIconSize: Function, // function to flag widget icon size change
    changeWidgetIconColor: string, // flag of widget icon size change
    setIsChangeWidgetIconCustomSize: Function, // function to flag custom widget icon size change
    setChangeWidgetCustomIconSize: Function, // function to flag custom widget icon size
    isShowCustomWidgetIconSizeMobile: boolean, // flag to display custom widget icon size for mobile
    setIsShowCustomWidgetIconSizeMobile: Function // function to flag custom widget icon size for mobile
}

// component to render widget icon size settings
const WidgetIconSize = (props: PropTypes) => {

    // PropTypes
    const { setChangeWidgetIconSize, setIsChangeWidgetIconCustomSize, setChangeWidgetCustomIconSize, isShowCustomWidgetIconSizeMobile, setIsShowCustomWidgetIconSizeMobile } = props;

    // Redux Data
    const { currentWebsite, currentWebsitePlanStatus } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();

    // State
    const [widgetIconSize, setWidgetIconSize] = useState<number>(3);
    const [isWidgetIconCustomSize, setIsWidgetIconCustomSize] = useState<boolean>(false);
    const [widgetIconCustomSize, setWidgetIconCustomSize] = useState<number>(50);


    // Set current widget icon type
    useEffect(() => {
        // check if current website data is available
        if (currentWebsite) {
            // check if widget icon size is set
            if (currentWebsite.widget_icon_size) {
                setWidgetIconSize(currentWebsite.widget_icon_size);
            } else {
                setWidgetIconSize(3);
            }

            // check if custom widget size flag is set
            if (currentWebsite.is_widget_custom_size === 1) {
                setIsWidgetIconCustomSize(true);
            } else {
                setIsWidgetIconCustomSize(false);
            }

            // check if custom widget icon size is set
            if (currentWebsite.widget_icon_size_custom) {
                setWidgetIconCustomSize(currentWebsite.widget_icon_size_custom);
            } else {
                setWidgetIconCustomSize(50);
            }

            // check if flag is set for mobile icon size
            if (currentWebsite.is_mobile_icon === 1) {
                setIsShowCustomWidgetIconSizeMobile(true);
            } else {
                setIsShowCustomWidgetIconSizeMobile(false);
            }

        }
    }, [currentWebsite]);

    
    useEffect(() => {
        // check if widget icon size is available
        if (widgetIconSize) {
            setChangeWidgetIconSize(widgetIconSize);
        } else {
            setChangeWidgetIconSize(3);
        }
    }, [widgetIconSize]);

    useEffect(() => {
        // check if custom widget icon size flag is set
        if (isWidgetIconCustomSize) {
            setIsChangeWidgetIconCustomSize(true);
        } else {
            setIsChangeWidgetIconCustomSize(false);
        }
    }, [isWidgetIconCustomSize]);

    useEffect(() => {
        // check if custom widget icon size is available
        if (widgetIconCustomSize) {
            setChangeWidgetCustomIconSize(widgetIconCustomSize);
        } else {
            setChangeWidgetCustomIconSize(50);
        }
    }, [widgetIconCustomSize]);


    return (
        <>
            {isWidgetIconCustomSize ? (
                <>

                    <div className="aioa_dashboard-widget-custom-settings border-bottom">
                        <Form.Check
                            type="switch"
                            className="aioa_dashboard-switch"
                            id="desktop-widget-icon-size"
                            label={`${t('Enable custom icon size')}`}
                            checked={isWidgetIconCustomSize}
                            onChange={(e) => setIsWidgetIconCustomSize(!isWidgetIconCustomSize)}
                        />
                    </div>


                    <WidgetCustomSizeControl isType={1} setSelectedWidgetIconCustomSize={setWidgetIconCustomSize} selectedWidgetIconCustomSize={widgetIconCustomSize} />


                    <div className="aioa_dashboard-widget-custom-settings border-top">
                        <Form.Check
                            type="switch"
                            className="aioa_dashboard-switch"
                            id="is-custom-widget-icon-size"
                            label={`${t('Use different icon size for mobile')}`}
                            checked={isShowCustomWidgetIconSizeMobile}
                            onChange={(e) => setIsShowCustomWidgetIconSizeMobile(!isShowCustomWidgetIconSizeMobile)}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="aioa_dashboard-widget-settings-btns">
                        <Button onClick={() => setWidgetIconSize(1)} className={`${widgetIconSize === 1 ? 'active' : ''}`} size="lg">{t('Extra Small')}</Button>
                        <Button onClick={() => setWidgetIconSize(2)} className={`${widgetIconSize === 2 ? 'active' : ''}`} size="lg">{t('Small')}</Button>
                        <Button onClick={() => setWidgetIconSize(3)} className={`${widgetIconSize === 3 ? 'active' : ''}`} size="lg">{t('Medium')}</Button>
                        <Button onClick={() => setWidgetIconSize(4)} className={`${widgetIconSize === 4 ? 'active' : ''}`} size="lg">{t('Large')}</Button>
                        <Button onClick={() => setWidgetIconSize(5)} className={`${widgetIconSize === 5 ? 'active' : ''}`} size="lg">{t('Extra Large')}</Button>
                    </div>

                    <div className="aioa_dashboard-widget-custom-settings border-top">
                        <Form.Check
                            type="switch"
                            className="aioa_dashboard-switch"
                            id="custom-switch-size"
                            label={`${t('Enable custom icon size')}`}
                            checked={isWidgetIconCustomSize}
                            onChange={(e) => setIsWidgetIconCustomSize(!isWidgetIconCustomSize)}
                        />
                        {!currentWebsitePlanStatus.isFreeWidget ?
                            <Form.Check
                                type="switch"
                                className="aioa_dashboard-switch"
                                id="custom-switch-mobile-icon"
                                label={`${t('Use different icon size for mobile')}`}
                                checked={isShowCustomWidgetIconSizeMobile}
                                onChange={(e) => setIsShowCustomWidgetIconSizeMobile(!isShowCustomWidgetIconSizeMobile)}
                            />
                            : <></>}
                    </div>



                </>
            )}
        </>
    )
}

export default WidgetIconSize;