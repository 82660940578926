import DashboardDomainDropdown from "../DomainDropdown";
import DashboardLanguageButton from "../LanguageButton";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useSelector } from "react-redux";
import DashboardLogo from "../DashboardLogo";

import "../../Assets/scss/components/header.scss";
import AddNewDomainButton from "../AddNewDomainButton";
import DashboardSupportButton from "../SupportButton";
import useWindowScroll from "../../Helpers/useWindowScroll";
import UserDropdownButton from "../UserDropdownButton";
import { useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";

interface PropTypes {
    handelMobileMenu: Function, // function to handle mobile menu toggle
}

// component to render dashboard header
const DashboardHeader = (props: PropTypes) => {

    // Redux Data
    const { user } = useSelector((store: StoreProptypes) => store);

    //Props
    const { handelMobileMenu } = props;

    // Status
    const location = useLocation();

    // Function
    const windowScrollValue = useWindowScroll('aioa_dashboard-header-main');


    return (
        <>
            <header className={`aioa_dashboard-header ${windowScrollValue}`} id="aioa_dashboard-header-main">
                <div className="aioa_dashboard-header-domain-wrapper">
                    {user ? (
                        <>
                            <DashboardDomainDropdown />
                            {!(location.pathname.includes('/front/package') || location.pathname.includes('/front/web/package')) && (
                                <>
                                    <AddNewDomainButton isShowHeader={true} />
                                </>
                            )}

                        </>
                    ) : (
                        <>
                            <DashboardLogo />
                        </>
                    )}
                </div>

                <div className="aioa_dashboard-header-user-main-wrapper">
                    <DashboardLogo />

                    <div className="aioa_dashboard-header-user-wrapper">

                        {user && (
                            <DashboardSupportButton />
                        )}
                        <DashboardLanguageButton />
                        {user && (
                            <UserDropdownButton />
                        )}

                        <div className="aioa_dashboard-navigation-toggle d-xxl-none">
                            <Button className="aioa_dashboard-navigation-toggler-btn" aria-label="Toggle navigation" onClick={() => handelMobileMenu()}>
                                <span className="aioa_dashboard-navigation-toggler-icon"></span>
                                <span className="aioa_dashboard-navigation-toggler-icon"></span>
                                <span className="aioa_dashboard-navigation-toggler-icon"></span>
                            </Button>
                        </div>

                    </div>
                </div>
            </header>
        </>
    )
}

export default DashboardHeader;