import { UserPropTypes } from "../../PropTypes/UserPropTypes";

const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

// API to update user profile
const updateUserProfileApi = async (
    user: UserPropTypes, // user data
    accessToken: string, // access token
    password?: string // password
): Promise<any> => {

    const url = `${baseURL}/user/edit`;

    const formData = new FormData();

    
    user.name && formData.append("name", user.name);
    user.phone && formData.append("phone", user.phone);
    user.email && formData.append("email", user.email);
    password && formData.append("password", password);
    formData.append("company_name", user.company_name ? user.company_name : '');
    formData.append("website", user.website ? user.website : '');

    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: formData,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}



export { updateUserProfileApi };