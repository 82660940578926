import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { savePrePurchasedDomainApi } from "../../Api/DomainApi/SavePrePurchasedDomainApi";
import { getUserDetailsService } from "../UserAction/LoginActionService";
import { DomainDetailsService } from "./DomainDetails";

// Service to add new website to pre-purchased package
const AddPrePurchasedDomainService = async (
    token: string, // access token
    newWebsiteURL: string, // website URL
    userID: number, // user Id
    newWebsitePlanID: number, // Plan Id
    dispatch: Function, // dispatch function to store redux data
    t: Function, // i18next t function 
    navigate: Function // navigate function for routing    
): Promise<any> => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // API to save domain to pre-purchased plan
    const savePrePurchasedDomainApiRes = savePrePurchasedDomainApi(token, newWebsiteURL, userID, newWebsitePlanID).then((res) => {

        // check if response status is okay and response data exists
        if (res.status === 200 && res.Data) {

            // service to get user details
            const getUserDetailsServiceRes = getUserDetailsService(token, dispatch).then((userRes) => {

                // check if response status is okay
                if (userRes.status === 200) {


                    // service to get domain details
                    const DomainDetailsServiceRes = DomainDetailsService(token, res.Data.id, dispatch).then((websiteDetailsRes) => {


                        // check if response status is okay
                        if (websiteDetailsRes.status === 200) {

                            // dispatch success message
                            dispatch({
                                type: SET_MESSAGE,
                                payload: {
                                    "toast_message": t('Domain add successfully.'),
                                    "toast_type": "success"
                                }
                            });

                            // navigate to dashboard
                            navigate('/front');


                            /* // remove Loading
                            dispatch({
                                type: LOADING,
                                payload: false,
                            }); */

                            // return Statement
                            return websiteDetailsRes;

                        }
                    });

                    // return Statement
                    return DomainDetailsServiceRes;

                }
                else {

                    // dispatch error message
                    dispatch({
                        type: SET_MESSAGE,
                        payload: {
                            "toast_message": t('Error'),
                            "toast_type": "danger"
                        }
                    });

                    /* // remove Loading
                    dispatch({
                        type: LOADING,
                        payload: false,
                    }); */

                    // return Statement
                    return userRes;
                }

            });

            return getUserDetailsServiceRes;

        }
        else {

            /* // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            }); */

            return { status: 400 };
        }
    });

    return savePrePurchasedDomainApiRes;

}

export { AddPrePurchasedDomainService }