import { Card } from "react-bootstrap"
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import parseISODate from "../../Helpers/parseISODate";
import { useTranslation } from "react-i18next";
import PaymentButtonBlock from "../PaymentButton";
import StrikePrice from "../../Helpers/strikePrice";

const NormalUserCanceledPlanInfo = () => { // only Normal user Plan Details

    // Functions
    const { t } = useTranslation();

    // Redux Data
    const { currentWebsite, user } = useSelector((store: StoreProptypes) => store);

    console.log("hello from CanceledPlanInfo");


    return (
        <>
            {currentWebsite && currentWebsite.canceled_package_only && currentWebsite.canceled_package_only.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box normal-user canceled-plan-box">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Canceled Plan')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.canceled_package_only[0].name} Plan</h4>
                                {currentWebsite.is_free_widget !== 1 && (
                                    <>
                                        <p className="text-danger mb-0"><strong>{t(`Plan canceled on ${parseISODate(currentWebsite.canceled_package_only[0].cancel_date)}`)} </strong></p>
                                    </>
                                )}
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">

                                    {currentWebsite.used_canceled_discount == 0 ? (<>
                                        {currentWebsite.canceled_package_only[0].subscr_interval === 'Y' ? (
                                            <>
                                                <strong className="original-price">
                                                    <del>${(currentWebsite.canceled_package_only[0].final_price).toFixed(2)}<span>/{t('Per Year')}</span></del>
                                                </strong>
                                                <strong className="original-price">
                                                    $<StrikePrice price={currentWebsite.canceled_package_only[0].final_price} isDiscountPr={true} discountPr={currentWebsite.reactivate_discount} />
                                                    <span>/{t('Per Year')}</span>
                                                </strong>

                                            </>
                                        ) : (
                                            <>
                                                <strong className="original-price">
                                                    <del>${(currentWebsite.canceled_package_only[0].final_price).toFixed(2)}<span>/{t('Per Month')}</span></del>
                                                </strong>
                                                <strong className="original-price">
                                                    $<StrikePrice price={currentWebsite.canceled_package_only[0].final_price} isDiscountPr={true} discountPr={currentWebsite.reactivate_discount} />
                                                    <span>/{t('Per Month')}</span>
                                                </strong>
                                            </>
                                        )}
                                    </>) : (<>
                                        {currentWebsite.canceled_package_only[0].subscr_interval === 'Y' ? (
                                            <>
                                                <strong className="original-price">
                                                    ${(currentWebsite.canceled_package_only[0].final_price).toFixed(2)}<span>/{t('Per Year')}</span>
                                                </strong>

                                            </>
                                        ) : (
                                            <>
                                                <strong className="original-price">
                                                    ${(currentWebsite.canceled_package_only[0].final_price).toFixed(2)}<span>/{t('Per Month')}</span>
                                                </strong>

                                            </>
                                        )}
                                    </>

                                    )}

                                </div>
                                <div className="aioa_dashboard-plan-info-box-btn">
                                    <PaymentButtonBlock
                                        activePlanDomain={currentWebsite.domain}
                                        activePlanType={1}
                                        buttonType={4}
                                        activePlanInterval={currentWebsite.canceled_package_only[0].subscr_interval}
                                        activePlanPrice={currentWebsite.canceled_package_only[0].final_price}
                                        activeUserID={currentWebsite.user_id}
                                        isShowDiscount={currentWebsite.used_canceled_discount === 0 ? true : false}
                                        activePlanID={currentWebsite.canceled_package_only[0].package_id}
                                        buttonBigSize={true}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const PrePurchasedPartnerUserCanceledPlanInfo = () => { // only PrePurchased Partner user Plan Details

    // Functions
    const { t } = useTranslation();

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);


    return (
        <>
            {currentWebsite && currentWebsite.canceled_package_only && currentWebsite.canceled_package_only.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box prepurchased-Partner canceled-plan-box">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Canceled Plan')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.canceled_package_only[0].name} Plan</h4>
                                <p className="text-danger mb-0"><strong>{t(`Plan canceled on ${parseISODate(currentWebsite.canceled_package_only[0].cancel_date)}`)} </strong></p>
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">

                                    {currentWebsite.canceled_package_only[0].subscr_interval === 'Y' ? (
                                        <>
                                            <strong className="original-price">
                                                <del>${(currentWebsite.canceled_package_only[0].final_price).toFixed(2)}<span>/{t('Per Year')}</span></del>
                                            </strong>
                                            <strong className="original-price">
                                                $<StrikePrice price={currentWebsite.canceled_package_only[0].final_price} isDiscountPr={true} discountPr={currentWebsite.reactivate_discount} />
                                                <span>/{t('Per Year')}</span>
                                            </strong>
                                        </>
                                    ) : (
                                        <>
                                            <strong className="original-price">
                                                <del>${(currentWebsite.canceled_package_only[0].final_price).toFixed(2)}<span>/{t('Per Month')}</span></del>
                                            </strong>
                                            <strong className="original-price">
                                                $<StrikePrice price={currentWebsite.canceled_package_only[0].final_price} isDiscountPr={true} discountPr={currentWebsite.reactivate_discount} />
                                                <span>/{t('Per Month')}</span>
                                            </strong>
                                        </>
                                    )}

                                </div>
                                <div className="aioa_dashboard-plan-info-box-btn">
                                    <PaymentButtonBlock
                                        buttonType={2}
                                        activePlanType={1}
                                        activePlanDomain={currentWebsite.domain}
                                        activePlanInterval={currentWebsite.canceled_package_only[0].subscr_interval}
                                        activePlanPrice={currentWebsite.canceled_package_only[0].final_price}
                                        websiteID={currentWebsite.id}
                                        activeUserID={currentWebsite.user_id}
                                        activePlanID={currentWebsite.canceled_package_only[0].package_id}
                                        buttonBigSize={true}
                                        buttonVariant={`danger`}
                                        buttonLabel={`Renew Now Button`}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const NormalPartnerUserCanceledPlanInfo = () => { // only Normal Partner user Plan Details

    // Functions
    const { t } = useTranslation();

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && currentWebsite.canceled_package_only && currentWebsite.canceled_package_only.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box normal-partner canceled-plan-box">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Canceled Plan')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.canceled_package_only[0].name} Plan</h4>
                                <p className="text-danger mb-0"><strong>{t(`Plan canceled on ${parseISODate(currentWebsite.canceled_package_only[0].cancel_date)}`)} </strong></p>
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">

                                    {currentWebsite.canceled_package_only[0].subscr_interval === 'Y' ? (
                                        <>
                                            <strong className="original-price">
                                                <del>${(currentWebsite.canceled_package_only[0].final_price).toFixed(2)}<span>/{t('Per Year')}</span></del>
                                            </strong>
                                            <strong className="original-price">
                                                $<StrikePrice price={currentWebsite.canceled_package_only[0].final_price} isDiscountPr={true} discountPr={currentWebsite.reactivate_discount} />
                                                <span>/{t('Per Year')}</span>
                                            </strong>
                                        </>
                                    ) : (
                                        <>
                                            <strong className="original-price">
                                                <del>${(currentWebsite.canceled_package_only[0].final_price).toFixed(2)}<span>/{t('Per Month')}</span></del>
                                            </strong>
                                            <strong className="original-price">
                                                $<StrikePrice price={currentWebsite.canceled_package_only[0].final_price} isDiscountPr={true} discountPr={currentWebsite.reactivate_discount} />
                                                <span>/{t('Per Month')}</span>
                                            </strong>
                                        </>
                                    )}
                                </div>
                                <div className="aioa_dashboard-plan-info-box-btn">
                                    <PaymentButtonBlock
                                        buttonType={2}
                                        activePlanType={1}
                                        activePlanDomain={currentWebsite.domain}
                                        activePlanInterval={currentWebsite.canceled_package_only[0].subscr_interval}
                                        activePlanPrice={currentWebsite.canceled_package_only[0].final_price}
                                        websiteID={currentWebsite.id}
                                        activeUserID={currentWebsite.user_id}
                                        activePlanID={currentWebsite.canceled_package_only[0].package_id}
                                        buttonBigSize={true}
                                        buttonVariant={`danger`}
                                        buttonLabel={`Renew Now Button`}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const MultiStoreUserCanceledPlanInfo = () => { // only Multi Store user Plan Details

    // Functions
    const { t } = useTranslation();

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && currentWebsite.canceled_package_only && currentWebsite.canceled_package_only.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box normal-user canceled-plan-box">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Canceled Plan')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.canceled_package_only[0].name} Plan</h4>
                                <p className="text-danger mb-0"><strong>{t(`Plan canceled on ${parseISODate(currentWebsite.canceled_package_only[0].cancel_date)}`)} </strong></p>
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">
                                    {currentWebsite.canceled_package_only[0].subscr_interval === 'Y' ? (
                                        <>
                                            {(currentWebsite && currentWebsite.used_canceled_discount === 0) ?
                                                <>
                                                    <strong className="original-price">
                                                        <del>${(currentWebsite.canceled_package_only[0].final_price).toFixed(2)}<span>/{t('Per Year')}</span></del>
                                                    </strong>
                                                    <strong className="original-price">
                                                        $<StrikePrice price={currentWebsite.canceled_package_only[0].final_price} isDiscountPr={true} discountPr={currentWebsite.reactivate_discount} />
                                                        <span>/{t('Per Year')}</span>
                                                    </strong>
                                                </>
                                                : <>
                                                <strong className="original-price">
                                                    ${(currentWebsite.canceled_package_only[0].final_price).toFixed(2)}<span>/{t('Per Year')}</span>
                                                </strong>
                                                </>
                                            }
                                        </>
                                    ) : (

                                        <>
                                            {(currentWebsite && currentWebsite.used_canceled_discount === 0) ? (<>
                                                <strong className="original-price">
                                                    <del>${(currentWebsite.canceled_package_only[0].final_price).toFixed(2)}<span>/{t('Per Month')}</span></del>
                                                </strong>
                                                <strong className="original-price">
                                                    $<StrikePrice price={currentWebsite.canceled_package_only[0].final_price} isDiscountPr={true} discountPr={currentWebsite.reactivate_discount} />
                                                    <span>/{t('Per Month')}</span>
                                                </strong>
                                            </>) : (<>
                                                <strong className="original-price">
                                                    ${(currentWebsite.canceled_package_only[0].final_price).toFixed(2)}<span>/{t('Per Month')}</span>
                                                </strong>
                                            </>)}

                                        </>

                                    )}
                                </div>
                                <div className="aioa_dashboard-plan-info-box-btn">
                                    <>
                                    <PaymentButtonBlock
                                        activePlanDomain={currentWebsite.domain}
                                        activePlanType={2}
                                        buttonType={4}
                                        activePlanInterval={currentWebsite.canceled_package_only[0].subscr_interval}
                                        activePlanPrice={currentWebsite.canceled_package_only[0].final_price}
                                        activeUserID={currentWebsite.user_id}
                                        isShowDiscount={currentWebsite.used_canceled_discount === 0 ? true : false}
                                        activePlanID={currentWebsite.canceled_package_only[0].package_id}
                                    />
                                    </>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const AllUserOnlyCanceledPlanInfo = () => {

    // Redux Data
    const { currentWebsite, user } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && (
                <>
                    {user && (
                        <>
                            {user.department === 4 ? ( // For Partner User Only
                                <>
                                    {(user.is_multiple_domain === 1) ? (
                                        <>
                                            <PrePurchasedPartnerUserCanceledPlanInfo />
                                        </>
                                    ) : ( // Normal Partner plan with upgrade one step up
                                        <>
                                            <NormalPartnerUserCanceledPlanInfo />
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {user.is_multiple_domain === 2 ? ( // Sliver Gold Plan with Add New plan
                                        <>
                                            <MultiStoreUserCanceledPlanInfo />
                                        </>
                                    ) : ( // Normal Plan with one stepup upgrade option
                                        <>
                                            <NormalUserCanceledPlanInfo />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}

        </>
    )
}

export default AllUserOnlyCanceledPlanInfo;
