import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, InputGroup, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { domainValidationService } from "../../Services/DomainService/DomainValidationService";
import { AddPrePurchasedDomainService } from "../../Services/DomainService/AddPrePurchasedDomain";
import { useNavigate } from "react-router-dom";

interface PropTypes {
    callBackFunction: Function, // callback function when domain is added successfully
}

// component to display add new domain to pre-purchased package
const AddNewPrePurchasedDomainModalBlock = (props: PropTypes) => {

    // Props
    const { callBackFunction } = props;

    // Function
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Redux
    const { accessToken, isLoading, user } = useSelector((store: StoreProptypes) => store);

    // State
    const [newAddWebsiteURL, setNewAddWebsiteURL] = useState<string>('');

    // function to validate Domain
    const handleCheckWebsiteValidation = (e: React.MouseEvent<HTMLButtonElement>) => {

        e.preventDefault();

        // check if website url is empty
        if (newAddWebsiteURL === '') {

            // dispatch error message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Please add valid website URL'),
                    "toast_type": "danger"
                }
            })
        } else {

            // service to validate domain
            domainValidationService(newAddWebsiteURL.replaceAll(" ", "").replace(/\s+/g, ''), dispatch, t).then((res) => {

                // check if response is ok
                if (res.states === 200) {

                    // check if access token and user data exists
                    if (accessToken && user) {

                        // service to add pre-purchased domain
                        AddPrePurchasedDomainService(accessToken, newAddWebsiteURL.replaceAll(" ", "").replace(/\s+/g, ''), user.id, user.current_active_package_id, dispatch, t, navigate).then((preRes) => {
                            // check if response is ok
                            if (preRes.status) {                                
                                // execute callback function once domain added successfully                                
                                //alert("Domain added successfully");
                                callBackFunction();                               
                            }
                        })
                    }
                }
            })
        }
    }

    return (
        <>
            <Form>
                <div aria-live="polite" className="aioa_dashboard-multi-step-form-step-content">

                    <Form.Label htmlFor="url"> {t("Website URL")} <span className="text-danger" aria-hidden="true">*</span> </Form.Label>
                    <InputGroup className="mb-3 input-group-outline">
                        <Form.Control
                            size="lg"
                            id="url"
                            type="text"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewAddWebsiteURL(e.target.value)}
                            value={newAddWebsiteURL.replace(/\s+/g, '')}
                            required
                        />
                    </InputGroup>

                    <div className="d-flex justify-content-center">
                        <Button size="lg" onClick={(e) => handleCheckWebsiteValidation(e)} disabled={isLoading}>
                            {t("Add")}
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    )
}
export default AddNewPrePurchasedDomainModalBlock;