import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import DashboardPageTitle from "../../Components/InnerPageTitle";
import { Alert, Button, Card, CardBody, Col, Form, Row, Spinner } from "react-bootstrap";
import { useRef, useState } from "react";
import "../../Assets/scss/pages/report-problem.scss";
import ReportProblemAPI from "../../Api/ReportProblemApi";

// options for select dropdown
const problemOptions = [
    "Accessibility Profiles",
    "Accessibility Statement",
    "Background",
    "Big Black/White Cursor",
    "Content Scaling",
    "Light/High/Smart Contrast",
    "Dictionary",
    "Dyslexia Font",
    "Filter Content",
    "Font Size",
    "Hide Images",
    "Hide Interface",
    "High/Low Saturation",
    "Highlight Focus/Hover/Links/Titles",
    "Invert Colors",
    "Languages",
    "Letter Spacing",
    "Libras",
    "Line Height",
    "Monochrome",
    "Move Widget",
    "Mute Sounds",
    "Oversize Widget",
    "Read Mode",
    "Readable Font",
    "Reading Guide",
    "Reading Mask",
    "Reset Button",
    "Screen Reader",
    "Stop Animations",
    "Text Alignment",
    "Text Color",
    "Text Magnifier",
    "Title Color",
    "Virtual Keyboard",
    "Other"
]

// component to render report problem page
const ReportProblemPage = () => {

    // Redux Data
    const { currentWebsite, currentUserStatus, accessToken } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [validationMessage, setValidationMessage] = useState("");
    const [formSubmitMessage, setFormSubmitMessage] = useState("");

    const [problem, setProblem] = useState<string>("");
    const [otherProblem, setOtherProblem] = useState<string>("");

    const [message, setMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState<File | null>();

    const [loading, setLoading] = useState(false);

    // function to handle file input
    const handleFileInput = (e: any) => {
        const target = e.target;
        const fileUploaded = target.files;

        if (fileUploaded) {
            setSelectedFile(fileUploaded[0]);
        }
    }

    // function to handle form submit
    const HandleSubmit = (e: React.FormEvent) => {
        
        e.preventDefault();

        // remove form submit message
        setFormSubmitMessage('');

        // check if selected problem is empty
        if (problem === '') {
            setValidationMessage(t("Please Select a Problem."));
        }
        // check if selected problem is other but other text input is empty
        else if (problem === "Other" && otherProblem === "") {
            setValidationMessage(t('Please Enter the Problem'));
        } 
        // check if message is empty
        else if (message === '') {
            setValidationMessage(t("Please Describe Your Problem"));
        }
        // check if selected file is empty
        else if (!selectedFile) {
            setValidationMessage(t("Please upload a screenshot or video recording"));
        }
        // check if selected file is more than 25mb
        else if (selectedFile.size > 26214400) {
            setValidationMessage(t("Please upload a screenshot or video recording with a file size of no more than 25MB"));
        }
        else {
            // set validation message
            setValidationMessage("");

            // set loader
            setLoading(true);
            
            // API to submit report problem form
            accessToken && currentWebsite && ReportProblemAPI(accessToken, currentWebsite.id, problem === "Other" ? otherProblem : problem, selectedFile, message).then((response) => {
                // check if response ok
                if (response.status === 200) {
                    // set from submit message
                    setFormSubmitMessage(t("Thank you for reporting the problem. We’ll get back to you as soon as possible"));

                    // remove loader
                    setLoading(false);

                    // remove form inputs
                    setProblem("");
                    setOtherProblem("");
                    setMessage("");
                    setSelectedFile(null);
                    if(fileInputRef.current){
                        fileInputRef.current.value = ''
                    }
                }
                else {
                    // set error message
                    setValidationMessage(t("There was an error submitting the form. Please try again later"));
                }
            })
        }
    }

    // function to handle browse
    const handleBrowse = () => {
        const input = fileInputRef.current;
        input && input.click();
    }


    return (
        <>

            {currentUserStatus && (
                <>
                    <DashboardPagesTemplate>
                        <DashboardPageTitle
                            pageTitle={`${t("Report a Problem")}`}
                            subPageTitle={`${t('page subtitle', { domainName: currentWebsite?.domain })}`}
                        />
                        <Card className="report-problem-form-wrapper">
                            <CardBody>
                                {validationMessage && (
                                    <>
                                        <Alert variant='danger'>
                                            {validationMessage}
                                        </Alert>
                                    </>
                                )}
                                {formSubmitMessage && (
                                    <>
                                        <Alert variant='success'>
                                            {formSubmitMessage}
                                        </Alert>
                                    </>
                                )}
                                <Form onSubmit={(e) => HandleSubmit(e)}>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group controlId="problem" className="mb-3">
                                                <Form.Label>{t("Select a Problem")}</Form.Label>
                                                <Form.Select required value={problem} onChange={(e) => setProblem(e.currentTarget.value)}>
                                                    <option value="" disabled>{t("Select one from the dropdown")}</option>
                                                    {problemOptions.map(item => <option value={t(item)}>{t(item)}</option>)}
                                                </Form.Select>
                                            </Form.Group>
                                            {problem === "Other" ? <Form.Group controlId="other_problem" className="mb-3">
                                                <Form.Label>{t("Enter Other Problem")}</Form.Label>
                                                <Form.Control type="text" required placeholder={t("Enter Other Problem")} value={otherProblem} onChange={(e) => setOtherProblem(e.currentTarget.value)} />
                                            </Form.Group> : <></>}
                                            <Form.Group controlId="media" className="mb-3">

                                                <Form.Label>{t("Please Upload screenshot or video of any error or issue you may have.")}</Form.Label>
                                                <div className="custom-file-control-wrapper">
                                                    <div className="custom-file-control">
                                                        <Form.Control ref={fileInputRef} type="file" required aria-label={t("Upload Screenshot or Video")} accept="image/*,video/*" onChange={(e) => handleFileInput(e)} />
                                                        <div className="fake-input-wrapper">
                                                            <div className="file-list-wrapper">
                                                                <div className="file">
                                                                    {selectedFile?.name}
                                                                </div>
                                                            </div>
                                                            <Button variant="primary" onClick={handleBrowse}>{t("Select file")}</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Form.Text>{t("Supported file types: png, jpg, jpeg,gif, mp4; maximum allowed file size: 25MB.")}</Form.Text>
                                            </Form.Group>
                                            <Form.Group controlId="message" className="mb-3">
                                                <Form.Label>{t("Message")}</Form.Label>
                                                <Form.Control as="textarea" value={message} placeholder={t("We would appreciate it if you describe the problem, so we can get back to you soon with a solution.")}
                                                    aria-label={t("Message")} required onChange={(e) => setMessage(e.target.value)} rows={5} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button type="submit" size="lg" disabled={loading}>  {loading ? <Spinner animation="grow" size="sm" className="me-=2" /> : ""}  {t("Submit")} </Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </DashboardPagesTemplate>
                </>
            )
            }


        </>
    )
}

export default ReportProblemPage;