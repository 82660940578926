import { SET_MESSAGE } from "../../Actions/Types";
import { addNewCardApi, deleteCardListApi, getCardListApi, makeDefaultCardApi } from "../../Api/CardAPI";

// service to get card list
const getCardListService = (
    accessToken: string, // access token
    setCardListData: Function, // function to set card list
    setLoadingCardList: Function, // function to set loader
) => {

    // set loader
    setLoadingCardList(true);

    // API to get card list
    getCardListApi(accessToken).then(response => {
        // check response data is available
        if (response.Data) {
            setCardListData(response.Data);
        }
        // remove loader
        setLoadingCardList(false);
    })
}

// function to add new card
const addNewCardService = (
    accessToken: string, //access token
    cardToken: string, // card token
    setLoading: Function, // function to set loader
    setCardListData: Function, // function to set card list data
    setLoadingCardList: Function, // function to set card list loading
    setShowCardPopup: Function, // function to show card popup
    dispatch: Function // dispatch function to store redux data
) => {

    // set loading
    setLoading(true);

    // API to add new card
    addNewCardApi(accessToken, cardToken).then(response => {
        // check if response status is okay and response data is available
        if (response.Data && response.status === 200) {

            // service to get card list
            getCardListService(accessToken, setCardListData, setLoadingCardList);

            //hide show card popup
            setShowCardPopup(false)

            // dispatch add card success message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": response.msg,
                    "toast_type": "success"
                }
            })

            //remove loading
            setLoading(false);
        } else {

            // dispatch add card failure message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": response.msg,
                    "toast_type": "danger"
                }
            })

            // remove loading
            setLoading(false);
        }

    })
}

// service to delete card
const deleteCardService = (
    accessToken: string, // access token
    deleteCardID: number, // card Id
    setShowDeleteCardPopupLoading: Function, // function to show/hide delete card popup loading
    setCardListData: Function, // function to set card list
    setLoadingCardList: Function, // function to set card list loading
    setDeleteCardPopup: Function, // function to show/hide delete card popup
    dispatch: Function, // function to store redux data
    t: Function // i18next t function
) => {

    // set loader
    setShowDeleteCardPopupLoading(true);

    // API to delete card
    deleteCardListApi(accessToken, deleteCardID).then(response => {
        // check if response data is available
        if (response.Data) {
            //setCardListData(response.Data);

            // service to get card list
            getCardListService(accessToken, setCardListData, setLoadingCardList);

            // hide delete card popup
            setDeleteCardPopup(false)

            // dispatch card delete success message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Card Delete Msg'),
                    "toast_type": "success"
                }
            })
        }
        // remove popup loading
        setShowDeleteCardPopupLoading(false);
    })
}


// service to set default card
const makeDefaultCardService = (
    accessToken: string, // access token 
    defaultCardID: number, // card Id
    setCardListData: Function, // function to set card list
    setLoadingCardList: Function, // function to set card list loader
    setDeleteCardPopup: Function // function to show/hide delete card popup
) => {

    // API to set default card
    makeDefaultCardApi(accessToken, defaultCardID).then(response => {
        // check if response data is available
        if (response.Data) {
            
            // service to get card list
            getCardListService(accessToken, setCardListData, setLoadingCardList);

            // hide delete card popup
            setDeleteCardPopup(false)
        }
        
    })
}


export { addNewCardService, getCardListService, deleteCardService, makeDefaultCardService }