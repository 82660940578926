import { LOADING, LOGIN_SUCCESS, SET_MESSAGE } from "../../Actions/Types";
import { getWhiteLabelServiceDomainListAPI, updateBrandDetailsAPI, updateWhiteLabelServiceDomainStatusAPI } from "../../Api/WhiteLabelAPI";
import { WhiteLabelWebsitePropTypes } from "../../PropTypes/WebsitePropTypes";

// service to update branding details
const updateBrandDetailsService = (
    accessToken: string, // access token
    userID: number, // user id
    websiteURL: string, // website url
    setLoading: Function, // function to set loading
    dispatch: Function, // function to store redux data
    t: Function, // i18next t function
    brandLogo?: File, // branding logo file
) => {
    
    // set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // API to update branding details
    updateBrandDetailsAPI(accessToken, userID, websiteURL, brandLogo).then(response => {
        // check if response data is available
        if (response.Data) {

            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: response.Data },
            });
            // dispatch success message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Update successfully."),
                    "toast_type": "success"
                }
            });

            // Remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
            
        }
        else {

            // dispatch update failed message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("White label service setting not updated. Please try again."),
                    "toast_type": "danger"
                }
            })

            // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
        }
    })
}

// service to get white label domain list
const getWhiteLabelServiceDomainList = (
    accessToken: string, // access token
    addOnID: number, // Add on Id
    status: number, // flag to get active or inactive domains 
    limit: number, // limit per page
    offset: number, // record offset
    terms: string, // search term
    setTotalRecord: Function, // function to set record displayed
    setDomainMainTotalRecord: Function, // function to set total number of records
    setWebsiteList: Function, // function to set website list
    setLoading: Function // function to set loading
) => {

    setLoading(true);

    // API to get white label domain list
    getWhiteLabelServiceDomainListAPI(accessToken, addOnID, status, limit, offset, terms).then(res => {
        // check if response data is available
        if (res.Data) {
            setWebsiteList(res.Data);
            setTotalRecord(res.total_records);
            setDomainMainTotalRecord(res.total_records_main);
            setLoading(false);
        }
    })
}

// service to update white label domain status
const UpdateWhiteLabelServiceDomainStatus = (
    accessToken: string, // access token
    addOnID: number, // add on Id
    statusObj: WhiteLabelWebsitePropTypes[], // website array
    setApiRecall: Function, // function to set api recall flag
    dispatch: Function, // function to save redux data
    t: Function, // i18next t function
    setSelectedWebsiteList: Function // function to set website list
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // API to update white label domain status
    updateWhiteLabelServiceDomainStatusAPI(accessToken, addOnID, statusObj).then(res => {
        // check if response data is available
        if (res.Data) {

            setSelectedWebsiteList([]);
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Update successfully."),
                    "toast_type": "success"
                }
            });

            setApiRecall(true);

            // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

        }
    })
}

export { getWhiteLabelServiceDomainList, UpdateWhiteLabelServiceDomainStatus, updateBrandDetailsService }