const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}


// API to perform logout action
const UserLogoutActionApi = async (
    token: string // access token
): Promise<any> => {

    const url = `${baseURL}/logout`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { UserLogoutActionApi }