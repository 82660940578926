const baseURL = `${process.env.REACT_APP_BASE_URL}/api`;

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};


// Add New Card API
const addNewCardApi = async (
    accessToken: string, // access token
    cardToken: string // card token
): Promise<any> => {

    const url = `${baseURL}/save-card`;
    const data = new FormData();

    data.append("card_token", cardToken);
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

// Get Card list api
const getCardListApi = async (
    accessToken: string, // access token
): Promise<any> => {

    const url = `${baseURL}/saved-card-list`;
    
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

// Delete Card API
const deleteCardListApi = async (
    accessToken: string, // access token
    deleteCardID: number // card Id
): Promise<any> => {

    const url = `${baseURL}/delete-card`;
    const data = new FormData();
    data.append("id", deleteCardID.toString());
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

// set default card API
const makeDefaultCardApi = async (
    accessToken: string, // access token
    defaultCardID: number // card Id
): Promise<any> => {

    const url = `${baseURL}/make-card-default`;
    const data = new FormData();
    data.append("id", defaultCardID.toString());
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { addNewCardApi, getCardListApi, deleteCardListApi, makeDefaultCardApi };