import { Card } from "react-bootstrap"
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import UpgradePlanList from "../PaymentButton/UpgradePlanList";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const AllUserAllPlanInfo = () => {

    // Redux Data
    const { currentWebsite, user } = useSelector((store: StoreProptypes) => store);

    

    return (
        <>
            <Card className="aioa_dashboard-upgrade-plan-info-box mt-0">
                <Card.Body>
                    <div className="aioa_dashboard-upgrade-plan-info-box-title">
                        <div className="h4 text-primary mb-1">{("select_plans")}</div>
                        <div className="">{("select_your_desire_plan_based_on_your_website_pageview")}</div>
                    </div>
                    {/* <h1>hi</h1> */}

                    {currentWebsite && (
                        <>
                            {user && (
                                <>
                                    {user.department === 4 ? ( // For Partner User Only
                                        <>
                                            {(user.is_multiple_domain === 1) ? (
                                                <>
                                                    <UpgradePlanList
                                                        activePlanType={3}
                                                        activePlanDomain={currentWebsite.domain}
                                                        activePlanInterval={'M'}
                                                        activePlanPrice={0}
                                                    />
                                                    {/* <h1>hu</h1> */}
                                                </>
                                            ) : ( // Normal Partner plan with upgrade one step up
                                                <>
                                                    <UpgradePlanList
                                                        activePlanType={1}
                                                        activePlanDomain={currentWebsite.domain}
                                                        activePlanInterval={'M'}
                                                        activePlanPrice={0}
                                                    />
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {user.is_multiple_domain === 2 ? ( // Sliver Gold Plan with Add New plan 
                                                <>
                                                    <UpgradePlanList
                                                        activePlanType={2}
                                                        activePlanDomain={currentWebsite.domain}
                                                        activePlanInterval={'M'}
                                                        activePlanPrice={0}
                                                    />
                                                </>
                                            ) : ( // Normal Plan with one stepup upgrade option
                                                <>
                                                    <UpgradePlanList
                                                        activePlanType={1}
                                                        activePlanDomain={currentWebsite.domain}
                                                        activePlanInterval={'M'}
                                                        activePlanPrice={0}
                                                    />
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}

                </Card.Body>
            </Card>

        </>
    )
}

export default AllUserAllPlanInfo;