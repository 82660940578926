import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";
import { websiteListPropType } from "../../PropTypes/WebsitePropTypes";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import IsVerified from '../../Assets/img/verified.png';
import InVerified from '../../Assets/img/Inactive.png';
import parseISODate from "../../Helpers/parseISODate";

interface PropTypes {
    item: websiteListPropType, // website object
    srno: number, // serial number 
    handleShowAutoLoginModal: Function, // function to show auto login modal
    handleShowWidgetScriptCodeModal: Function, // function to show script code modal
    handleSetCurrentWebsite: Function // function to set current website
}

// component to render table cell block for websites managed by platforms like shopify, wix
const PlatformWebsiteTableCellBlock = (props: PropTypes) => {
    
    // PropTypes
    const { item, srno, handleShowAutoLoginModal, handleShowWidgetScriptCodeModal, handleSetCurrentWebsite } = props;
console.log("hello from PlatformWebsiteTableCellBlock")
    // Redux Data
    const { user } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();

    return (
        <div className="aioa_dashboard-table-tr">

            <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Sr No")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Sr No").toString()}>{srno}</div>
            </div>

            <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-none d-xl-flex">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Domain")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                    <div className="domain-name">
                        {/* check if domain is verified */}
                        {item.is_verified === 1 ? (
                            <>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip data-bs-html="true" id="button-tooltip">
                                            <span className="text-start">{t("Widget is active")}</span>
                                        </Tooltip>
                                    }
                                >
                                    <div className={`domain-verify-icon`}>
                                        <img src={IsVerified} alt='' />
                                    </div>
                                </OverlayTrigger>
                            </>
                        ) : (
                            <>
                                {/* check if last usage information is available */}
                                {item.widget_last_use_date ? (
                                    <>
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip data-bs-html="true" id="button-tooltip">
                                                    <span className="text-start">{t(`Widget was lastly used on ${parseISODate(item.widget_last_use_date)}`)}</span>
                                                </Tooltip>
                                            }
                                        >
                                            <div className={`domain-verify-icon`}>
                                                <img src={InVerified} alt='' />
                                            </div>
                                        </OverlayTrigger>
                                    </>
                                ) : (
                                    <>
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip data-bs-html="true" id="button-tooltip">
                                                    <span className="text-start">{t("Widget activation is pending.")}</span>
                                                </Tooltip>
                                            }
                                        >
                                            <div className={`domain-verify-icon`}>
                                                <img src={InVerified} alt='' />
                                            </div>
                                        </OverlayTrigger>
                                    </>
                                )}
                            </>
                        )}
                        {item.domain}
                    </div>
                    {/* check if admin user */}
                    {(user && user.department === 1) && (
                        <>
                            <div className='email-filed'> <span>{t('Email')}</span> : {item.email}</div>
                        </>
                    )}
                    <div className="link-btn-group">
                        {/* check if admin user */}
                        {(user && user.department === 1) && (
                            <Button variant="link" onClick={() => handleShowAutoLoginModal(item.al_token, item.url)}>{t('auto login link label')}</Button>
                        )}
                    </div>
                </div>
            </div>

            <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Domain")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                    <div className="domain-name">{item.domain}</div>
                </div>
            </div>

            <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Widget Status")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Widget Status").toString()}>
                    <div className="domain-name">{t(`Current plan is expired.`)}</div>
                </div>
            </div>

            {/* check if admin user */}
            {(user && user.department === 1) && (
                <>
                    <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                        <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Email")}</div>
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Email").toString()}>
                            <div className='email-filed'>{item.email}</div>
                        </div>
                    </div>
                </>
            )}

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Plan")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Plan").toString()}>{item.platform}</div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Status")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Status").toString()}><strong>{item.platform}</strong></div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Price(USD)")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Price(USD)").toString()}>{item.platform}</div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Renew Date")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Renew Date").toString()}>{item.platform}</div>
            </div>

            {/* check if admin user */}
            {user && user.department === 1 ? (
                <>
                    <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                        <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Total/Scan URLs")}</div>
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Total/Scan URLs").toString()}>{item.platform}</div>
                    </div>

                    <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                        <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Used Ai Credits")}</div>
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Used Ai Credits").toString()}>{item.platform}</div>
                    </div>
                </>
            ) : (
                <>
                    {/* check its a normal user and not having multiple domains */}
                    {user && user.is_multiple_domain !== 2 && (
                        <>
                            <div className="aioa_dashboard-table-td tcw-250 text-center aioa_dashboard-table-cell-sidegap">
                                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Manage Plan")}</div>
                                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Manage Plan").toString()}>
                                    {item.platform}
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}

            <div className="aioa_dashboard-table-td tcw-250 text-center aioa_dashboard-table-cell-sidegap d-xl-none">
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Action").toString()}>
                    <div className="link-btn-group">
                        <Button variant="primary" onClick={() => handleSetCurrentWebsite(item.id)}>{t('view report btn label')}</Button>

                        {/* check if domain is not verified and last usage details are not available */}
                        {(item.is_verified !== 1 && (item.widget_last_use_date === '' || item.widget_last_use_date === null)) && (
                            <Button variant="primary" onClick={() => handleShowWidgetScriptCodeModal(item.widget_position, item.widget_color_code, item.current_package_only[0].api_key, item.url)}>{t('Copy Code')}</Button>
                        )}

                        {/* check if admin user */}    
                        {(user && user.department === 1) && (
                            <Button variant="primary" onClick={() => handleShowAutoLoginModal(item.al_token, item.url)}>{t('auto login link label')}</Button>
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PlatformWebsiteTableCellBlock;