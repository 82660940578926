const baseURL = `${process.env.REACT_APP_BASE_URL}/api/websites`

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

// API to get domain list with details
const getDomainListWithDetailsApi = async (
    token: string, // access token
    limit: number, // limit of records per page
    offset: number, // record offset 
    terms: string, // search term
    statusFilter: number, // website status filter
    startDate : string,  // start date
    endDate : string, // end date
): Promise<any> => {

    const url = `${baseURL}/list`;

    const data = new FormData();

    data.append("alldetail", 'true');

    limit !== null && data.append("limit", limit.toString());
    offset !== null && data.append("offset", offset.toString());
    terms !== '' && data.append("terms", terms);
    statusFilter !== 4 && data.append("filter_status", (statusFilter === 10 ? 0 : statusFilter).toString());

    // check if start date and end date are not empty
    if(startDate !== '' && endDate !== '') {
        data.append("start_date", startDate);
        data.append("end_date", endDate);
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}


// API to get domain list only
const getDomainListApi = async (
    token: string, // access token
): Promise<any> => {

    const url = `${baseURL}/list`;
    const data = new FormData();

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { getDomainListWithDetailsApi, getDomainListApi }