const baseURL = `${process.env.REACT_APP_BASE_URL}/api/website-pdf`
const baseURL1 = `${process.env.REACT_APP_BASE_URL}/api`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

// api to get pdf list
const getPDFListApi = async (
    accessToken: string, // access token
    offset: number, // record offset
    limit: number, // limit per page
    website_id: number,  // website id
    filterValue?: string, // filter string
    sortBy?: string // sort by string
): Promise<any> => {

    const url = `${baseURL}/list`;

    const data = new FormData();


    data.append("offset", offset.toString());
    data.append("limit", limit.toString());
    data.append("website_id", website_id.toString());
    filterValue && data.append("filter_value", filterValue);
    sortBy && data.append("short_by", sortBy);


    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}


// API to get quoted PDF list
const getQuotesPDFListApi = async (
    accessToken: string, // access token
    website_id: number, // website ID
    remediatedPDFsStatus?: number // remediated pdf status
): Promise<any> => {

    const url = `${baseURL}/quotes`;

    const data = new FormData();

    data.append("website_id", website_id.toString());
    remediatedPDFsStatus && data.append("status", remediatedPDFsStatus.toString());

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}

// api to handle direct payment request
const directPaymentApi = async (
    accessToken: string, // access token
    website_id: number, // website id
    pdfIds: string, // pdf id
    flag?: number // flag
): Promise<any> => {

    const url = `${baseURL}/direct-payment-request`;

    const data = new FormData();

    data.append("website_id", website_id.toString());
    data.append("website_pdf_ids", pdfIds);
    flag && data.append("pre_payment_pdfs", flag.toString());

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

// api to get payment link
const getPaymentLinkApi = async (
    accessToken: string, // access token
    website_id: number, // website Id
    pdfIds: string // pdf id
): Promise<any> => {

    const url = `${baseURL}/get-payment-link`;

    const data = new FormData();

    data.append("website_id", website_id.toString());
    data.append("pdf_ids", pdfIds);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

// api to cancel remediation quote
const cancelRemediationQuotePDFApi = async (
    accessToken: string, // access token
    name: string | null, // name
    email: string, // email address
    phone: string | null, // phone number
    subject: string, // subject
    website_id: number, // website id
    plan_id: number, // plan id
    is_pdf_remediation: number, // flag if need to cancel pdf remediation
    is_quote_removed: number, // flag if quote is removed
    pdfRemoveID: string // pdf id to remove
): Promise<any> => {

    const url = `${baseURL1}/contact-us`;

    const Formdata = new FormData();

    Formdata.append("name", name ? name : "");
    Formdata.append("email", email);
    Formdata.append("phone", phone ? phone : "");
    Formdata.append("subject", subject);
    Formdata.append("website_id", website_id.toString());
    Formdata.append("plan_id", plan_id.toString());
    Formdata.append("is_pdf_remediation", is_pdf_remediation.toString());
    Formdata.append("is_quote_removed", is_quote_removed.toString());
    Formdata.append("pdf_ids", pdfRemoveID);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: Formdata
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}

export { getPDFListApi, getQuotesPDFListApi, directPaymentApi, getPaymentLinkApi, cancelRemediationQuotePDFApi };