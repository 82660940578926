import { Form } from "react-bootstrap";
import { DraggableProvidedDragHandleProps, DraggableProvidedDraggableProps, DraggableStateSnapshot } from "react-beautiful-dnd";
import { forwardRef } from "react";
import { MenulistPropTypes } from "../../../PropTypes/EnableDisableServicesPropTypes";

interface PropTypes {
    item: MenulistPropTypes, // language object
    childrenRef: any, // element reference 
    dragHandleProps: DraggableProvidedDragHandleProps | null | undefined, // drag handle props
    otherProp: DraggableProvidedDraggableProps // draggable props
    snapshot: DraggableStateSnapshot, // draggable snapshot
    handleProfilesStatus: Function // function to handle profile status
}

// component to render profile item
const ProfileListChildrenElement = forwardRef((prop: PropTypes) => {

    const { item, childrenRef, otherProp, snapshot, dragHandleProps, handleProfilesStatus } = prop;

    return (
        <div className={`menu-box border ${snapshot.isDragging ? `hovering` : ``}`} ref={childrenRef} {...otherProp}>
            <div className="drag-icon" style={{ cursor: 'all-scroll' }} {...dragHandleProps}>
                <i className="material-symbols-outlined" aria-hidden="true">drag_indicator</i>
            </div>
            <div className="menu-name icon-with-name ms-2 me-2">
                {/* <div className="icon border"><img src={langFlag} alt="" /></div> */}
                <div className="name h6 mb-0 ms-2">{item.name}</div>
            </div>
            <div className="action-part">
                <Form.Check
                    className="form-check m-0 p-0 d-flex align-items-center"
                    type="checkbox"
                    id={`accessibility-menu-${item.id}`}
                    area-label={`accessibility`}
                    onChange={(e) => handleProfilesStatus(e, item.id)}
                    defaultChecked={item.status === 1 ? true : false}
                />
            </div>
        </div>
    )
});

export default ProfileListChildrenElement;