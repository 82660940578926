// Google TTS api key
const key = process.env.REACT_APP_TTS_KEY;

// API to get google voices
const GoogleGetVoicesAPI = async (): Promise<any> => {
    const url = `https://texttospeech.googleapis.com/v1/voices?key=${key}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}


// API to get Google Speech Synthesis
const GoogleSynthesizeSpeechAPI  = async (
    text: string, // text string to get voice for
    voiceName: string, // voice name
    languageCode:string // language code 
): Promise<any> => {
    
    // API URL
    const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${key}`;

    const payload = {
        input: { text },
        voice: { name: voiceName, languageCode },
        audioConfig: { audioEncoding: 'MP3' },
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(payload)
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}


export { GoogleGetVoicesAPI, GoogleSynthesizeSpeechAPI };