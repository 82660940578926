import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { domainValidationAPI } from "../../Api/DomainApi/DomainValidationApi";

// service to validate domain
const domainValidationService = async (
    newWebsiteURL: string, // website url
    dispatch: Function, // function to store redux data
    t: Function // i18next t function
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // API to validate domain
    const domainValidationRes = await domainValidationAPI(newWebsiteURL).then(async (validationRes) => {
        
        // check if website url is validated
        if (validationRes.data && newWebsiteURL.replaceAll(" ", "").replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0] === validationRes.data.domain && validationRes.status === 1) {
            
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Website Exist Msg'),
                    "toast_type": "danger"
                }
            });

            // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            return { states: 300 };

        } 
        // check if website is not validated
        else if ((validationRes.data === null || validationRes.data.length === 0) && validationRes.status === 1) {
            
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Website Not Exist Msg'),
                    "toast_type": "danger"
                }
            });

            // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            return { states: 400 };
            
        } else {

            // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            return { states: 200 };
        }
    });

    return domainValidationRes;
}

export { domainValidationService }