import { SET_GOOGLE_VOICES } from "../../Actions/Types";
import { GoogleGetVoicesAPI } from "../../Api/GoogleTTSAPI"

// service to get google TTS voices
const GoogleGetVoicesService = (dispatch: Function) => {
    // API to get google TTS voices
    GoogleGetVoicesAPI().then(response => {
        // check if response is available
        if (response) {
            // dispatch google voices list
            dispatch({ type: SET_GOOGLE_VOICES, payload: response.voices })
        }
    })
}

export { GoogleGetVoicesService };