import { LOADING, SET_CURRENT_USER_DISCOUNT, SET_CURRENT_WEBSITE_PLAN_LIST } from "../../Actions/Types";
import { getCurrentPackageApi, getPlanListApi } from "../../Api/PlanListApi";


// service to get plan list and store it
const getPlanListService = (
    token: string, // access token
    planType: number, // plan type
    dispatch: Function // function to store redux data
) => {

    // Set Loading
    if (planType !== 2) {
        dispatch({
            type: LOADING,
            payload: true,
        });
    }

    // get plan list API
    getPlanListApi(token, planType).then((planRes) => {

        // check if plan data is available
        if (planRes.Data) {

            // check plan tye
            if (planType === 2) {

                // dispatch to store plan data
                dispatch({
                    type: SET_CURRENT_WEBSITE_PLAN_LIST,
                    payload: planRes.Data,
                });

            }

            if (planType !== 2) {
                // Set Loading
                dispatch({
                    type: LOADING,
                    payload: false,
                });
            }

        }

        // check if discount information is available
        if (planRes.discount_details) {
            dispatch({
                type: SET_CURRENT_USER_DISCOUNT,
                payload: planRes.discount_details,
            });
        }

    });
}


// function to get current plan details
const getCurrentPackageDetails = (
    token: string, // access token
    packageID: number, // active plan Id
    websiteID: number, // website id
    dispatch: Function, // function to store data
    setCurrentAddOnDetails: Function // set state
) => {

    // Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // api to get current package details
    getCurrentPackageApi(token, packageID, websiteID).then((currentPackageRes) => {
        // check if current package data is available
        if (currentPackageRes.Data) {

            //set data to state
            setCurrentAddOnDetails(currentPackageRes.Data);

            // Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
        }
    })
}

export { getPlanListService, getCurrentPackageDetails };