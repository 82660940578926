import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { forgetPasswordApi } from "../../Api/UserAction/ForgotPasswordApi";

// service to handle forget password API
const forgetPasswordService = (
    email: string, // email input
    handleFocus: Function, // function to handle focus
    t: Function, // i18next t function
    dispatch: Function, // dispatch function for redux store
    navigate: Function // navigate function of router
) => {

    // Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // API for forget password
    forgetPasswordApi(email).then((status: number | never[]) => {

        // check if api response is okay
        if (status === 200) {

            // remove loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            // navigate to login page
            navigate('/front/login');

            // dispatch message on successful api call
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Forgot Password Success Msg", { returnObjects: true }),
                    "toast_type": "success",
                    "onclose": null
                }
            });

        } else {

            // remove loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            // dispatch message on api call failure
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("Forgot Password Failed Msg", { returnObjects: true }),
                    "toast_type": "danger",
                    "onclose": handleFocus
                }
            });

        }

    });
}

export { forgetPasswordService }