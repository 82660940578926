import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { getEnableDisableMenuApi, updateEnableDisableMenuApi } from "../../Api/EnableDisableAPI";

// service to get menu items
const getEnableDisableMenuService = (
    accessToken: string, // access token
    websiteID: number, // website Id
    dispatch: Function, // dispatch function
    t: Function, // i18next t function
    setMenuList: Function, // function to set menu list
    setLanguagesList: Function, // function to set languages list
    setOtherList: Function, // function to set other list
    setAccessibilityProfilesList: Function, // function to set profile list
) => {

    // Set loading
    dispatch({
        type: LOADING,
        payload: true
    });

    // API to get menu list
    getEnableDisableMenuApi(accessToken, websiteID).then(response => {

        // check if response data is available
        if (response.Data && response.Data.length > 0) {

            let responseData;

            // parse to JSON
            responseData = response.Data.map((responseItem: string) => {
                return JSON.parse(responseItem);
            });


            setMenuList(responseData[0]);
            setLanguagesList(responseData[1]);
            setOtherList(responseData[2]);
            setAccessibilityProfilesList(responseData[3]);

            // remove loading
            dispatch({
                type: LOADING,
                payload: false
            });

        } else {
            // remove loading
            dispatch({
                type: LOADING,
                payload: false
            });
        }

    })
}


// service to enable/disable items
const updateEnableDisableMenuService = (
    accessToken: string, // access token
    website_id: number, // website id
    update_reset: number, // flag to update or reset. 1 = save settings, 2 = reset default
    setLoading: Function, // set loading
    menuList: any, // list of menu item
    languagesList: any, // list of menu item
    otherList: any, // list of other menu item
    accessibilityProfilesList: any, // list of profile item
    setMenuList: Function, // function to set menu list
    setLanguagesList: Function, // function to set language list
    setOtherList: Function, // function to set other list
    setAccessibilityProfilesList: Function, // function to set profile list
    dispatch: Function, // function to store redux data
    t: Function, // i18next t function
    handleClose: Function // function to close confirmation popup 
) => {
    setLoading(true);

    // api to enable/disable menu items
    updateEnableDisableMenuApi(accessToken, website_id, update_reset, menuList, languagesList, otherList, accessibilityProfilesList).then(response => {
        // check if response data is available
        if (response.Data && response.Data.length > 0) {
            // close popup
            handleClose();

            let responseData;

            // parse to JSON
            responseData = response.Data.map((responseItem: string) => {
                return JSON.parse(responseItem);
            });

            setMenuList(responseData[0]);
            setLanguagesList(responseData[1]);
            setOtherList(responseData[2]);
            setAccessibilityProfilesList(responseData[3]);

            // check if update_reset flag is 1
            if (update_reset === 1) {
                dispatch({
                    type: SET_MESSAGE,
                    payload: {
                        "toast_message": t('All in One Accessibility widget menu settings updated successfully.'),
                        "toast_type": "success",
                        "onclose": null
                    }
                })
            } else {
                dispatch({
                    type: SET_MESSAGE,
                    payload: {
                        "toast_message": t('All in One Accessibility widget menu settings restored to default.'),
                        "toast_type": "success",
                        "onclose": null
                    }
                })
            }

        }
        setLoading(false);
    })
}


export { getEnableDisableMenuService, updateEnableDisableMenuService }