import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface PropTypes {
    setChangeGaID: Function // function to set updated GA ID
    setChangeAdobeID: Function // function to set updated Adobe Analytics ID
}

// component to render Analytics tracking
const AnalyticsTrackingCode = (props: PropTypes) => {

    // Props
    const { setChangeAdobeID, setChangeGaID } = props;

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();

    // States
    const [gaID, setGaID] = useState<string>('');
    const [adobeID, setAdobeID] = useState<string>('');

    // Set current widget icon type
    useEffect(() => {
        if (currentWebsite) {
            // check if GA Key available
            if (currentWebsite.ga_key) {
                setGaID(currentWebsite.ga_key);
            } else {
                setGaID('');
            }

            // check if Adobe Key available
            if (currentWebsite.adobe_key) {
                setAdobeID(currentWebsite.adobe_key);
            } else {
                setAdobeID('');
            }
        }
    }, [currentWebsite]);

    // set current GA key value in prop function
    useEffect(() => {
        if (gaID) {
            setChangeGaID(gaID);
        } else {
            setChangeGaID('');
        }
    }, [gaID]);


    // set current Adobe key value in prop function
    useEffect(() => {
        if (adobeID) {
            setChangeAdobeID(adobeID);
        } else {
            setChangeAdobeID('');
        }
    }, [adobeID]);

    return (
        <>
            <div className="aioa_dashboard-widget-settings-controls">
                <Row xs={1} sm={2} className="gy-4">
                    <Col>
                        <Form.Group className="ga-id" controlId="ga_id">
                            <Form.Label>{t('GA measurement ID')}</Form.Label>
                            <Form.Control
                                size="lg"
                                type="text"
                                placeholder=""
                                className="border"
                                defaultValue={gaID}
                                onChange={(e) => setGaID(e.target.value)}
                                onBlur={(e) => setGaID(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="ga-id" controlId="adobe_id">
                            <Form.Label>{t('Adobe Tracking ID')}</Form.Label>
                            <Form.Control
                                size="lg"
                                type="text"
                                placeholder=""
                                className="border"
                                defaultValue={adobeID}
                                onChange={(e) => setAdobeID(e.target.value)}
                                onBlur={(e) => setAdobeID(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default AnalyticsTrackingCode;