import ProductPlaceholderImage from '../Assets/img/placeholder-image.webp';
import faviconPlaceholder from '../Assets/img/web-icon.webp';

// function to render fallback image 
const addDefaultImgSrc = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = ProductPlaceholderImage;
}

// function to render fallback favicon icon
const addDefaultFaviconImgSrc = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = faviconPlaceholder;
}

export { addDefaultImgSrc, addDefaultFaviconImgSrc }