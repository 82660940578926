
// function to parse ISO date for expiry date
const expiredDate = (TimeISOString: string | null) => {

    if (TimeISOString) {
        if(TimeISOString === "0000-00-00 00:00:00") {
            return '-';
        }

        const padTo2Digits = (num: string) => {
            return num.toString().padStart(2, '0');
        }

        let parsedTime = new Date(TimeISOString);
        parsedTime = new Date(parsedTime.getTime());
        let dateString = padTo2Digits(parsedTime.getMonth().toString()) + '/' + parsedTime.getFullYear().toString().slice(-2);

        return dateString;
    }
    else {
        return '-'
    }
}
export default expiredDate;