// Error Page
import ErrorPage from '../Pages/ErrorPage';

// User Module pages
import LoginPage from '../Pages/UserModule/LoginPage';
import ForgotPasswordPage from '../Pages/UserModule/ForgotPasswordPage';
import ResetPasswordPage from '../Pages/UserModule/ResetPasswordPage';
import AutoLoginPage from '../Pages/UserModule/AutoLogin';

// Dashboard Pages
import ScriptsPage from '../Pages/ScriptsPage';
import DashboardPage from '../Pages/DashboardPage';
import WidgetSettingsPage from '../Pages/WidgetSettingsPage';
import WebsiteListPage from '../Pages/WebsiteListPage';
import ImageAltTextRemediationPage from '../Pages/ImageAltTextRemediationPage';
import ManagePlanPage from '../Pages/ManagePlanPage';
import OrdersPage from '../Pages/OrdersPage';
import PlanPaymentPage from '../Pages/PaymentGetway';
import ProfilePage from '../Pages/UserModule/Profile';
import AddOnDetailsPage from '../Pages/AddOnPages';
import SettingsPage from '../Pages/SettingsPage';
import PDFPaymentPage from '../Pages/PaymentGetway/PDFPaymentGetway';
import DomainReportPage from '../Pages/DomainReportPage';
import GettingStartedPage from '../Pages/GettingStartedPage';
import WebWidgetSettingsPage from '../Pages/WidgetSettingsPage/WebWidgetSettings';
import ReportProblemPage from '../Pages/ReportProblemPage';

// iFrame Pages
export const routesList = [
    {
        path: "/front/login",
        element: <LoginPage />,
        breadcrumb: "Login",
        protected: false,
        metaData: {
            title: "Login | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/autologin/:token",
        element: <AutoLoginPage />,
        breadcrumb: "Auto Login",
        protected: false,
        metaData: {
            title: "Login | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/forgot-password",
        element: <ForgotPasswordPage />,
        breadcrumb: "Forgot Password",
        protected: false,
        metaData: {
            title: "Auto Login | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/reset-password",
        element: <ResetPasswordPage />,
        breadcrumb: "Reset Password",
        protected: false,
        metaData: {
            title: "Reset Password | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/edit-profile",
        element: <ProfilePage />,
        breadcrumb: "Reset Password",
        protected: true,
        metaData: {
            title: "Reset Password | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/scripts",
        element: <ScriptsPage />,
        breadcrumb: "Scripts",
        protected: true,
        metaData: {
            title: "Scripts | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/getting-started",
        element: <GettingStartedPage />,
        breadcrumb: "Getting Started",
        protected: true,
        metaData: {
            title: "Getting Started | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front",
        element: <DashboardPage />,
        breadcrumb: "Dashboard",
        protected: true,
        metaData: {
            title: "Dashboard | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/domain-report",
        element: <DomainReportPage />,
        breadcrumb: "Domain Report",
        protected: true,
        metaData: {
            title: "Domain Report | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/widget-settings",
        element: <WidgetSettingsPage />,
        breadcrumb: "Widget Settings",
        protected: true,
        metaData: {
            title: "Widget Settings | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/web-widget-settings",
        element: <WebWidgetSettingsPage />,
        breadcrumb: "Web Widget Settings",
        protected: true,
        metaData: {
            title: "Web Widget Settings Page Title",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/websites",
        element: <WebsiteListPage />,
        breadcrumb: "websites",
        protected: true,
        metaData: {
            title: "websites | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/manage-image-alt-text",
        element: <ImageAltTextRemediationPage />,
        breadcrumb: "Image Alt Text Remediation",
        protected: true,
        metaData: {
            title: "Image Alt Text Remediation | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/addon-services/:slug",
        element: <AddOnDetailsPage />,
        breadcrumb: "White Label",
        protected: true,
        metaData: {
            title: "White Label Service | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/manage-plan",
        element: <ManagePlanPage />,
        breadcrumb: "Manage Plan",
        protected: true,
        metaData: {
            title: "Manage plan | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/package/:token",
        element: <PlanPaymentPage />,
        breadcrumb: "Payment",
        protected: true,
        metaData: {
            title: "Plan Payment | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/pdf-package/:token",
        element: <PDFPaymentPage />,
        breadcrumb: "PDF Payment",
        protected: true,
        metaData: {
            title: "PDF Payment | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/orders",
        element: <OrdersPage />,
        breadcrumb: "Orders",
        protected: true,
        metaData: {
            title: "Orders | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/settings",
        element: <SettingsPage />,
        breadcrumb: "Settings",
        protected: true,
        metaData: {
            title: "Settings | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/report-a-problem",
        element: <ReportProblemPage />,
        breadcrumb: "Report a Problem",
        protected: true,
        metaData: {
            title: "Report a Problem | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }, {
        path: "/front/404",
        element: <ErrorPage />,
        breadcrumb: "404 Error",
        protected: false,
        metaData: {
            title: "404 Error | All in One Accessibility",
            description: "Implement accessibility quickly in your website with All In One Accessibility widget for your website. It is developed with the ADA & WCAG 2.1 recommendations."
        }
    }
]