import DashboardPageTitle from "../../Components/InnerPageTitle";
import DashboardIframePagesTemplate from "../../Template/DashboardIframePagesTemplate";
import WidgetSettings from "../../Components/WidgetSettings";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";


// component to render widget settings page on iframe
const WebWidgetSettingsPage = () => {
  const { t } = useTranslation();

  // Redux Data
  const { currentWebsite } = useSelector((store: StoreProptypes) => store);

  return (
    <>
      <DashboardIframePagesTemplate>
        <DashboardPageTitle
          pageTitle={`${t("Widget Settings")}`}
          subPageTitle={`Update your All in One Accessibility Widget Settings for ${currentWebsite?.domain}`}
        />
        <WidgetSettings />
      </DashboardIframePagesTemplate>

    </>
  )
}

export default WebWidgetSettingsPage;