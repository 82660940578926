import { useTranslation } from "react-i18next";
import pageRecordJoyful from "../../Assets/img/no-record-icon/pages-report-joyful.svg";
import pageRecordsad from "../../Assets/img/no-record-icon/pages-report-sad.svg";

import checksRecordJoyful from "../../Assets/img/no-record-icon/accessibility-violations-joyful.svg";
import checksRecordsad from "../../Assets/img/no-record-icon/accessibility-violations-sad.svg";

import imgRecordJoyful from "../../Assets/img/no-record-icon/manage-alt-textinfo-joyful.svg";
import imgRecordsad from "../../Assets/img/no-record-icon/manage-alt-textinfo-sad.svg";

import pdfRecordJoyful from "../../Assets/img/no-record-icon/pdf-remediation-joyful.svg";
import pdfRecordsad from "../../Assets/img/no-record-icon/pdf-remediation-sad.svg";

import cardRecordsad from "../../Assets/img/no-record-icon/card-remediation-sad.svg";

import doaminRecordsad from "../../Assets/img/no-record-icon/domain-sad.svg";
import doaminRecordjoy from "../../Assets/img/no-record-icon/domain-joy.svg";

import "../../Assets/scss/components/no-record-found.scss";

interface PropTypes {
  joyful?: boolean; // flag to display joyful image
  noRecordText?: string; // text message
  imageType?: string; // type of image needs to display
}


// component to display no record found message
const NoRecordFound = (props: PropTypes) => {

  // props
  const { joyful, noRecordText, imageType } = props;
  
  // Functions
  const {t} = useTranslation();

  return (
    <>
      <div className="aioa-dashboard-no-record-screen text-center">
      <div className="h5 mb-3">
          {noRecordText ? <>{noRecordText}</> : <>{t("No Records Found")}</>}
        </div>
        {joyful ? (
          <>
            <img
              src={
                (imageType === 'page') ? pageRecordJoyful 
                  : (imageType === 'checks') ? checksRecordJoyful 
                    : (imageType === 'img') ? imgRecordJoyful 
                      : (imageType === 'pdf') ? pdfRecordJoyful 
                        : (imageType === 'card') ? cardRecordsad
                          : (imageType === 'domain') ? doaminRecordjoy
                          : pageRecordJoyful
              }
              alt=""
              width={400}
              height={160}
            />
          </>
        ) : (
          <>
            <img
              src={
                (imageType === 'page') ? pageRecordsad 
                  : (imageType === 'checks') ? checksRecordsad
                    : (imageType === 'img') ? imgRecordsad 
                      : (imageType === 'pdf') ? pdfRecordsad
                        : (imageType === 'card') ? cardRecordsad
                          : (imageType === 'domain') ? doaminRecordsad
                          : pageRecordJoyful
              }
              alt=""
              width={400}
              height={160}
            />
          </>
        )}
      </div>
    </>
  );
};

export default NoRecordFound;
