const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

// API to get report list
const getManualAuditReportListApi = async (
    accessToken: string, // access token
    websiteId: number, // website ID
    addonID: number, // add on ID
    limit: number, // limit per page
    offset: number, // record offset
    filter: string // filter by string
): Promise<any> => {

    const url = `${baseURL}/manual-audit-vpat-list`;

    const formData = new FormData();

    formData.append("website_id", websiteId.toString());
    filter !== '-1' && formData.append("vpatStatus", filter);
    formData.append("limit", limit.toString());
    formData.append("offset", offset.toString());
    formData.append("package_id", addonID.toString());

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: formData
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}

// API to upload report file
const uploadReportFileApi = async (
    accessToken: string, // access token
    reportID: number, // report ID
    userId: number, // user ID
    file: File, // report file
    vpatStatus: number // VPAT status
): Promise<any> => {

    const url = `${baseURL}/manual-audit-vpat-upload`;

    const formData = new FormData();

    formData.append("id", reportID.toString());
    formData.append("user_id", userId.toString());
    formData.append("file_upload", file);
    formData.append("vpat_status", vpatStatus.toString());

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: formData
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}


// API to email report 
const mailSendReportFileApi = async (
    accessToken: string, // access token
    reportID: number, // report Id
    email: string // email address
): Promise<any> => {

    const url = `${baseURL}/manual-audit-vpat-sendmail`;

    const formData = new FormData();

    formData.append("id", reportID.toString());
    formData.append("email", email.toString());

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: formData
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }

}

export { getManualAuditReportListApi, uploadReportFileApi, mailSendReportFileApi }