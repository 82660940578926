import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import WidgetCustomSizeControl from "../WidgetCustomSizeControl";

interface PropTypes {
    setChangeWidgetIconSizeMobile: Function, // function to change widget icon size for mobile
    setIsChangeWidgetIconCustomSizeMobile: Function, // function to flag change of widget icon size for mobile
    setChangeWidgetIconCustomSizeMobile: Function // function to set custom widget icon size for mobile
}

// component to render widget icon size for mobile
const WidgetIconSizeMobile = (props: PropTypes) => {

    // PropTypes
    const { setChangeWidgetIconSizeMobile, setChangeWidgetIconCustomSizeMobile, setIsChangeWidgetIconCustomSizeMobile } = props;

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();

    // State
    const [widgetIconSizeMobile, setWidgetIconSizeMobile] = useState<number>(3);
    const [isWidgetIconCustomSizeMobile, setIsWidgetIconCustomSizeMobile] = useState<boolean>(false);
    const [widgetIconCustomSizeMobile, setWidgetIconCustomSizeMobile] = useState<number>(20);

    // Set current widget icon type
    useEffect(() => {
        if (currentWebsite) {
            // check if widget icon size is set for mobile
            if (currentWebsite.widget_icon_size_mobile) {
                setWidgetIconSizeMobile(currentWebsite.widget_icon_size_mobile);
            } else {
                setWidgetIconSizeMobile(3);
            }

            // check if flag is set for custom widget icon size for mobile
            if (currentWebsite.is_widget_custom_size_mobile === 1) {
                setIsWidgetIconCustomSizeMobile(true);
            } else {
                setIsWidgetIconCustomSizeMobile(false);
            }

            // check if custom widget icon size is set for mobile
            if (currentWebsite.widget_icon_size_custom_mobile) {
                setWidgetIconCustomSizeMobile(currentWebsite.widget_icon_size_custom_mobile);
            } else {
                setWidgetIconCustomSizeMobile(20);
            }
        }
    }, [currentWebsite]);

    useEffect(() => {
        // check if widget icon size for mobile is available
        if (widgetIconSizeMobile) {
            setChangeWidgetIconSizeMobile(widgetIconSizeMobile);
        } else {
            setChangeWidgetIconSizeMobile(3);
        }
    }, [widgetIconSizeMobile]);

    useEffect(() => {
        // check if custom widget icon size flag is set
        if (isWidgetIconCustomSizeMobile) {
            setIsChangeWidgetIconCustomSizeMobile(true);
        } else {
            setIsChangeWidgetIconCustomSizeMobile(false);
        }
    }, [isWidgetIconCustomSizeMobile]);

    useEffect(() => {
        // check if custom widget icon size is available
        if (widgetIconCustomSizeMobile) {
            setChangeWidgetIconCustomSizeMobile(widgetIconCustomSizeMobile);
        }
        else {
            setChangeWidgetIconCustomSizeMobile(50);
        }
    }, [widgetIconCustomSizeMobile]);

    return (
        <>
            {isWidgetIconCustomSizeMobile ? (
                <>
                    <div className="aioa_dashboard-widget-custom-settings border-bottom">
                        <Form.Check
                            type="switch"
                            className="aioa_dashboard-switch"
                            id="custom-mobile-icon-size-switch"
                            label={`${t('Enable icon custom size for mobile')}`}
                            checked={isWidgetIconCustomSizeMobile}
                            onChange={(e) => setIsWidgetIconCustomSizeMobile(!isWidgetIconCustomSizeMobile)}
                        />
                    </div>
                    <WidgetCustomSizeControl isType={2} setSelectedWidgetIconCustomSize={setWidgetIconCustomSizeMobile} selectedWidgetIconCustomSize={widgetIconCustomSizeMobile} />
                </>
            ) : (
                <>
                    <div className="aioa_dashboard-widget-settings-btns">
                        <Button onClick={() => setWidgetIconSizeMobile(1)} className={`${widgetIconSizeMobile === 1 ? 'active' : ''}`} size="lg">Extra Small</Button>
                        <Button onClick={() => setWidgetIconSizeMobile(2)} className={`${widgetIconSizeMobile === 2 ? 'active' : ''}`} size="lg">Small</Button>
                        <Button onClick={() => setWidgetIconSizeMobile(3)} className={`${widgetIconSizeMobile === 3 ? 'active' : ''}`} size="lg">Medium</Button>
                        <Button onClick={() => setWidgetIconSizeMobile(4)} className={`${widgetIconSizeMobile === 4 ? 'active' : ''}`} size="lg">Large</Button>
                        <Button onClick={() => setWidgetIconSizeMobile(5)} className={`${widgetIconSizeMobile === 5 ? 'active' : ''}`} size="lg">Extra Large</Button>
                    </div>
                    <div className="aioa_dashboard-widget-custom-settings border-top">
                        <Form.Check
                            type="switch"
                            className="aioa_dashboard-switch"
                            id="custom-switch-size"
                            label={`${t('Enable icon custom size for mobile')}`}
                            checked={isWidgetIconCustomSizeMobile}
                            onChange={(e) => setIsWidgetIconCustomSizeMobile(!isWidgetIconCustomSizeMobile)}
                        />
                    </div>
                </>
            )}
        </>
    )
}

export default WidgetIconSizeMobile;