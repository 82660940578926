import { WhiteLabelWebsitePropTypes } from "../../PropTypes/WebsitePropTypes";

const baseURL = `${process.env.REACT_APP_BASE_URL}/api`

let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

// API to update custom branding details
const updateBrandDetailsAPI = async (
    accessToken: string, // access token
    userID: number, // user ID
    websiteURL: string, // website URL
    brandLogo ?: File, // brand logo file
): Promise<any> => {

    const url = `${baseURL}/update-brand-details`;
    const formData = new FormData();
    formData.append("id", userID.toString());
    brandLogo && formData.append("brand_logo", brandLogo);
    formData.append("website_url", websiteURL)
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: formData
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

// API to get white label domain list
const getWhiteLabelServiceDomainListAPI = async (
    accessToken: string, // access token
    addOnID: number, // add-on ID
    status: number, // flag to get active or inactive domains
    limit: number, // limit per page
    offset: number, // record offset
    terms: string // search term
): Promise<any> => {

    const url = `${baseURL}/partner-whitelabel-status-list`;
    const data = new FormData();
    
    data.append("adon_id", addOnID.toString());
    data.append("filter_status", status.toString());
    data.append("limit", limit.toString());
    data.append("offset", offset.toString());
    data.append("terms", terms);
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

// API to update white label status
const updateWhiteLabelServiceDomainStatusAPI = async (
    accessToken: string, // access token
    addOnID: number, // add-on Id
    statusObj: WhiteLabelWebsitePropTypes[], // website object
): Promise<any> => {

    const url = `${baseURL}/partner-whitelabel-status-change`;
    const data = new FormData();
    
    data.append("adon_id", addOnID.toString());
    
    statusObj && statusObj.map((item)=>{
        return(
            data.append(`service_status[${item.id}]`, (item.adon_status).toString())
        )
    })
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + accessToken
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { getWhiteLabelServiceDomainListAPI, updateWhiteLabelServiceDomainStatusAPI, updateBrandDetailsAPI };