import { HelmetProvider } from 'react-helmet-async';
import BrowserRouterComponents from './Router';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { API_RECALL } from './Actions/Types';

// main component to render the app
function App() {

  const dispatch = useDispatch();

  const helmetContext = {};

  // function to get cookies
  const setCookie = (
    name: string, // cookie name
    value: string // cookie value
  ) => {
    let inFifteenMinutes = new Date(new Date().getTime() + 60 * 60 * 1000);
    Cookies.set(name, value, { expires: inFifteenMinutes });
  };

  // function to get cookie value
  const getCookie = (
    name: string // cookie name
  ): string | undefined => {
    return Cookies.get(name);
  };

  // function to check if cookie is expired (returns true if expired)
  const isCookieExpired = (
    name: string // cookie name
  ): boolean => {
    const value = getCookie(name);
    return value === undefined;
  };

  // check if cookie is expired
  if (isCookieExpired("apiDataRecall")) {

    setCookie("apiDataRecall", 'true');

    // Loading
    dispatch({
      type: API_RECALL,
      payload: true,
    });

  }

  return (
    <>
      <HelmetProvider context={helmetContext}>
        <BrowserRouterComponents />
      </HelmetProvider >
    </>
  );
}

export default App;
