import { useEffect, useState } from "react";
import "../Assets/scss/base.scss";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";

import LodingImg from "../Assets/img/icon-spinner.svg";
import ToastMessage from "../Components/ToastMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DomainDetailsService } from "../Services/DomainService/DomainDetails";
import { getUserDetailsService } from "../Services/UserAction/LoginActionService";
import { getPlanListService } from "../Services/PlanListService";
import { API_RECALL } from "../Actions/Types";


interface PropTypes {
    children: any
}

// master template
const MainPageTemplate = (prop: PropTypes) => {

    const { children } = prop;

    const { isLoading, apiRecall, translationLoading, toastMessage, currentUserStatus, user, currentWebsiteData, accessToken, currentWebsite, currentWebsitePlanList } = useSelector((state: StoreProptypes) => state);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [fullScreenLoading, setFullScreenLoading] = useState<boolean>(false);



    useEffect(() => {
        // check if loading is active
        if (isLoading || translationLoading) {
            setFullScreenLoading(true);
        } else {
            setFullScreenLoading(false);
        }
    }, [isLoading, translationLoading])

    // function to handle back to top
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop();
    }, []);


    useEffect(() => {
        // check if website data is not available
        if (currentWebsiteData === null || currentWebsitePlanList === null) {

            // check if access token and current website is available
            if (accessToken && currentWebsite) {

                // service to get user details 
                getUserDetailsService(accessToken, dispatch).then((userRes) => {

                    // check if response status is ok
                    if (userRes.status === 200) {

                        // service to get domain details
                        DomainDetailsService(accessToken, currentWebsite.id, dispatch).then((domainRes) => {
                            // check if response status is ok
                            if (domainRes.status === 200) {
                                // service to get plan/pricing details
                                getPlanListService(accessToken, 2, dispatch);
                            }
                        })
                    }
                });
            }
        }
    }, []);


    useEffect(() => {

        // check if user data and current user status is available
        if (user && currentUserStatus) {

            // check if current user type is multi-domain partner and status is initialize
            if (currentUserStatus.currentUserType === 3 && currentUserStatus.isInitUser) {


                const userStatusCondition1: boolean = (location.pathname.includes('/front/package') || location.pathname.includes('/front/web/package'));
                const userStatusCondition2: boolean = (location.pathname === '/front/edit-profile' || location.pathname === '/front/edit-profile/');
                const userStatusCondition3: boolean = (location.pathname === '/front/settings' || location.pathname === '/front/settings/');
                const userStatusCondition4: boolean = (location.pathname === '/front/settings' || location.pathname === '/front/settings/');
                const userStatusCondition5: boolean = (location.pathname === '/front/getting-started' || location.pathname === '/front/getting-started/');

                //console.info("userStatusCondition ==>", userStatusCondition1, userStatusCondition2, userStatusCondition3, userStatusCondition4, userStatusCondition5, (userStatusCondition1 || userStatusCondition2 || userStatusCondition3 || userStatusCondition4 || userStatusCondition5));

                // check if path name is matching the criteria
                if (!(userStatusCondition1 || userStatusCondition2 || userStatusCondition3 || userStatusCondition4 || userStatusCondition5)) {
                    navigate('/front/getting-started?user_update=true');
                }
            }
        }
    }, [user, currentUserStatus]);


    // API Call Base on Flag
    useEffect(() => {
        //check if user data exists
        if (user) {
            // if API recall flag is true
            if (apiRecall) {
                // if access token and current website is available
                if (accessToken && currentWebsite) {
                    // service to get user details
                    getUserDetailsService(accessToken, dispatch).then(async (userRes) => {
                        // check if user status is ok
                        if (userRes.status === 200) {

                            // service to fetch domain details
                            DomainDetailsService(accessToken, currentWebsite.id, dispatch).then(async (domainRes) => {
                                // check if user status is ok
                                if (domainRes.status === 200) {
                                    // service to get plan package information
                                    getPlanListService(accessToken, 2, dispatch);
                                    // set api recall flag false
                                    dispatch({
                                        type: API_RECALL,
                                        payload: false,
                                    });
                                }
                            });
                        }
                    });
                }
            }
        } else {
            // set api recall flag false
            dispatch({
                type: API_RECALL,
                payload: false,
            });
        }

    }, [apiRecall]);

    return (
        <>
            {fullScreenLoading && (
                <>
                    <div role="alert" className="aioa_dashboard-fullscreen-loader" aria-live='polite'>
                        <div className="aioa_dashboard-loader">
                            <img src={LodingImg} alt="" />
                            <span className="visually-hidden">{t("Loading page. Please Do not refresh the page")} </span>
                        </div>
                    </div>
                </>
            )}


            {toastMessage.toast_message !== null && (
                <>
                    <ToastMessage />
                </>
            )}

            <div className="aioa_dashboard-main-content">
                {children}
            </div>

        </>
    )
}

export default MainPageTemplate;