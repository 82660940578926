import SupportButtonIcon from "../../Assets/img/icon-customer-service.svg";
import "../../Assets/scss/components/support-button.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// component to render dashboard support button
const DashboardSupportButton = () => {

    // Functions
    const { t } = useTranslation();

    return (
        <>
            <Link to="/front/report-a-problem" className="btn btn-outline-primary aioa_dashboard-support-btn">
                <div className="aioa_dashboard-support-icon">
                    <img src={SupportButtonIcon} alt="" aria-hidden="true" />
                </div>
                <div className="aioa_dashboard-support-name">{t('Support')}</div>
            </Link>
        </>
    )
}

export default DashboardSupportButton;