const baseURL = `${process.env.REACT_APP_BASE_URL}/api/websites`

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

// API to get domain details
const DomainDetailsApi = async (
    token: string, // access token
    websiteID: number // Domain ID
): Promise<any> => {

    // alldetail=true will fetch all details about the domain
    const url = `${baseURL}/${websiteID}?alldetail=true`;

    try {
        // fetch API 
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            }
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { DomainDetailsApi }