import { LOADING, SET_MESSAGE, SET_DOMAIN_LIMIT_DATA } from "../../Actions/Types";
import { getBillingHistoryApi, sendInvoiceEmailApi, invoicePDFApi } from "../../Api/UserOrderApi";
import { DomainDetailsService } from "../DomainService/DomainDetails";
import { getUserDetailsService } from "../UserAction/LoginActionService";
import { UserOrderItemPropTypes } from "../../PropTypes/UserOrderDataPropTypes";
import { UserPropTypes } from "../../PropTypes/UserPropTypes";

// service to get billing history
const getBillingHistoryService = (
    accessToken: string, // access token
    limit: number, // limit per page
    offset: number, // record offset
    domainID: number, // domain id
    orderID: string, // order id
    startDate: string | null, // start date
    endDate: string | null, // end date
    setTotalRecord: Function, // function to set total records
    dispatch: Function, // dispatch function to store redux data
    t: Function, // i18next t function
    navigate: Function, // navigate function for routing
    setOrderList: Function, // function to set order list
    isNewWebsiteFlag: boolean, // new website flag
    isUpdateUserFlag: boolean, // flag to update user
    isUpdateWebsiteFlag: boolean, // flag to update website
    updateWebsiteID: number, // update website id,
    isUpdateDomainLimitFlag: boolean = false, //change:avani
    user: UserPropTypes | null = null
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // api to get billing history
    getBillingHistoryApi(accessToken, limit, offset, domainID, orderID, startDate, endDate).then(async (response) => {


        // check if response is ok
        if (response.status === 200) {

            // check if any update flag is available
            if (isUpdateWebsiteFlag || isNewWebsiteFlag || isUpdateUserFlag || isUpdateDomainLimitFlag) {

                // service to get user details
                getUserDetailsService(accessToken, dispatch).then(async (userRes) => {

                    // check if response is ok
                    if (userRes.status === 200) {

                        // check if website update flag available
                        if (updateWebsiteID !== 0) {

                            // service to get domain details
                            DomainDetailsService(accessToken, updateWebsiteID, dispatch).then(async (res) => {

                                // check if response is ok
                                if (res.status === 200) {

                                    // Set Order Data

                                    setOrderList(response.Data);
                                    setTotalRecord(response.total_records);

                                    navigate('/front/orders');

                                    // remove Loading
                                    dispatch({
                                        type: LOADING,
                                        payload: false,
                                    });

                                }
                            });

                        } else {
                            if (isUpdateDomainLimitFlag && user) {
                                //todo:here
                                let currentPackageId = user?.current_active_package_id;

                                if (currentPackageId) {
                                    let orderList: UserOrderItemPropTypes[] | undefined;
                                    let currentPackageId = user?.current_active_package_id

                                    // Assign response.Data to orderList
                                    orderList = response.Data as UserOrderItemPropTypes[];
                                    const foundItem = orderList.find(item => item.id === currentPackageId);
                                    if (foundItem) {
                                        dispatch({ //todo:here
                                            type: SET_DOMAIN_LIMIT_DATA,
                                            payload: {
                                                currentPackageId: currentPackageId,
                                                currentPackageDomainLimit: foundItem.domain_limit,
                                                currentPackageChildDomainsCount: foundItem.child_domains.length,
                                            }
                                        });
                                    }
                                }
                            }
                            else {
                                // Set Order Data
                                setOrderList(response.Data);
                                setTotalRecord(response.total_records);

                                navigate('/front/orders');
                            }

                            // remove Loading
                            dispatch({
                                type: LOADING,
                                payload: false,
                            });

                        }

                    }
                })

            } else {

                // Set Order Data
                setOrderList(response.Data);
                setTotalRecord(response.total_records)

                // remove Loading
                dispatch({
                    type: LOADING,
                    payload: false,
                });
            }

        }

    })
}


// service to send invoice over email
const sendInvoiceEmailService = (
    accessToken: string, // access token
    OrderId: number, // order id
    userEmail: string, // email address
    dispatch: Function, // function to store redux data
    t: Function, // i18next t function
    setConfirmEmailModelHide: Function // function to hide email confirmation modal
) => {

    // api to send invoice over email
    sendInvoiceEmailApi(accessToken, OrderId, userEmail).then(response => {
        if (response.Data) {
            setConfirmEmailModelHide();
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t(`Invoice Success Msg`, { returnObjects: true }),
                    "toast_type": "success"
                }
            })
        }
    })
}


// service to get invoice pdf
const invoicePDFService = (
    accessToken: string, // access token
    OrderId: number, // order id
    loadingDownload: boolean[], // loading flag
    seLoadingDownload: Function, // function to set loading flag
    index: number, // index
    length: number // 
) => {
    const temp = loadingDownload;
    temp.splice(index, 1, true);
    seLoadingDownload([...temp]);

    // api to get invoice pdf
    invoicePDFApi(accessToken, OrderId).then(response => {
        // check if response data is available
        if (response.data) {
            window.open(response.data, '_blank', 'noreferrer');
            seLoadingDownload(new Array(length).fill(false));
        }
    })
}


export { getBillingHistoryService, sendInvoiceEmailService, invoicePDFService }