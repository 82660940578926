import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";
import { websiteListPropType } from "../../PropTypes/WebsitePropTypes";
import parseISODate from "../../Helpers/parseISODate";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import InVerified from '../../Assets/img/Inactive.png';
import PaymentButtonBlock from "../PaymentButton";

interface PropTypes {
    item: websiteListPropType, // website object
    srno: number, // serial number 
    handleShowAutoLoginModal: Function, // function to show auto login popup
    handleSetCurrentWebsite: Function, // function to set current website
}


// component to render website list cell block with expired plan
const ExpiredPlanWebsiteListTableCellBlock = (props: PropTypes) => {

    // PropTypes
    const { item, srno, handleShowAutoLoginModal, handleSetCurrentWebsite } = props;
    

    



    // Redux Data
    const { user } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();

    return (
        <div className={`aioa_dashboard-table-tr ${user && user.department !== 1 && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2) && item.is_show_price === 0 ? `primary-row` : ``}`}>

            {/* check if its a partner user and show price flag is set to 0 */}
            {user && user.department !== 1 && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2) && item.is_show_price === 0 && (
                <>
                    <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                            <div className="domain-name">
                                <div className="primary-domain">
                                    <i className={`material-symbols-outlined`} aria-hidden="true">check</i>
                                    <div className="text-success">{t('Primary Domain')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Sr No")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Sr No").toString()}>{srno}</div>
            </div>

            <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-none d-xl-flex">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Domain")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                    <div className="domain-name">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip data-bs-html="true" id="button-tooltip">
                                    <span className="text-start">{t(`Current plan is expired.`)}</span>
                                </Tooltip>
                            }
                        >
                            <div className={`domain-verify-icon`}>
                                <img src={InVerified} alt='' />
                            </div>
                        </OverlayTrigger>
                        {item.domain}
                        {/* check if its a partner user and show price flag is set to 0 */}
                        {user && user.department !== 1 && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2) && item.is_show_price === 0 && (
                            <>
                                <div className="primary-domain">
                                    <i className={`material-symbols-outlined`} aria-hidden="true">check</i>
                                    <div className="text-success">{t('Primary Domain')}</div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* check if admin user */}
                    {(user && user.department === 1) && (
                        <>
                            <div className='email-filed'> <span>{t('Email')}</span> : {item.email}</div>
                        </>
                    )}
                    <div className="link-btn-group">
                        <Button variant="link" onClick={() => handleSetCurrentWebsite(item.id)}>{t('view report btn label')}</Button>

                        {/* check if admin user */}
                        {(user && user.department === 1) && (
                            <Button variant="link" onClick={() => handleShowAutoLoginModal(item.al_token, item.url)}>{t('auto login link label')}</Button>
                        )}
                    </div>
                </div>
            </div>

            <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Domain")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                    <div className="domain-name">{item.domain}</div>
                </div>
            </div>

            <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Widget Status")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Widget Status").toString()}>
                    <div className="domain-name">{t(`Current plan is expired.`)}</div>
                </div>
            </div>

            {/* check if admin user */}
            {(user && user.department === 1) && (
                <>
                    <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap d-xl-none">
                        <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Email")}</div>
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Email").toString()}>
                            <div className='email-filed'>{item.email}</div>
                        </div>
                    </div>
                </>
            )}

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Plan")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Plan").toString()}>{item.expired_package_detail[0].name}</div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Status")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Status").toString()}><strong className="text-danger">{t('Expired Plan Label')}</strong></div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Price(USD)")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Price(USD)").toString()}>-</div>
            </div>

            <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Expired Date")}</div>
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Expired Date").toString()}>
                    <div className="aioa_dashboard-table-date-info expired-date-info">
                        <div className="aioa_dashboard-table-date-label d-none d-xl-block">{t('Expired Date')}</div>
                        <div className="aioa_dashboard-table-date">{parseISODate(item.expired_package_detail[0].end_date)}</div>
                    </div>
                </div>
            </div>

            {/* check if admin user */}
            {user && user.department === 1 ? (
                <>
                    <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                        <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Total/Scan URLs")}</div>
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Total/Scan URLs").toString()}>{item.total_pages}/{item.total_scan_pages}</div>
                    </div>

                    <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                        <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Used Ai Credits")}</div>
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Used Ai Credits").toString()}>{item.AiCreditUsedCount}</div>
                    </div>
                </>
            ) : (
                <>
                    <div className="aioa_dashboard-table-td tcw-250 text-center aioa_dashboard-table-cell-sidegap">
                        <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Manage Plan")}</div>
                        <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Manage Plan").toString()}>
                            {/* check if normal user have multiple domains */}
                            {user && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2 )? (
                                <>
                                    {/* check if website show price flag is 0 */}
                                    {item.is_show_price === 0 ? (
                                        <>
                                            <PaymentButtonBlock
                                                buttonType={4}
                                                activePlanType={2}
                                                activePlanDomain={item.domain}
                                                activePlanInterval={item.expired_package_detail[0].subscr_interval}
                                                activePlanPrice={item.expired_package_detail[0].final_price}
                                                websiteID={item.id}
                                                activeUserID={item.user_id}
                                                activePlanID={item.expired_package_detail[0].package_id}
                                                buttonVariant={`danger`}
                                                buttonLabel={`Renew Now Button`}
                                            />
                                            {/* <h1>h1 best</h1> */}
                                        </>
                                    ) : (
                                        <>
                                            -
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <PaymentButtonBlock
                                        buttonType={2}
                                        activePlanType={1}
                                        activePlanDomain={item.domain}
                                        activePlanInterval={item.expired_package_detail[0].subscr_interval}
                                        activePlanPrice={item.expired_package_detail[0].final_price}
                                        websiteID={item.id}
                                        activeUserID={item.user_id}
                                        activePlanID={item.expired_package_detail[0].package_id}
                                        buttonVariant={`danger`}
                                        buttonLabel={`Renew Now Button`}
                                    />
                                </>
                            )}
                        </div>
                    </div>


                </>
            )}

            <div className="aioa_dashboard-table-td tcw-250 text-center aioa_dashboard-table-cell-sidegap d-xl-none">
                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Action").toString()}>
                    <div className="link-btn-group">
                        <Button variant="primary" onClick={() => handleSetCurrentWebsite(item.id)}>{t('view report btn label')}</Button>

                        {/* check if admin user */}
                        {(user && user.department === 1) && (
                            <Button variant="primary" onClick={() => handleShowAutoLoginModal(item.al_token, item.url)}>{t('auto login link label')}</Button>
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ExpiredPlanWebsiteListTableCellBlock;
