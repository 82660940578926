// function to make element sticky on scroll
const useWindowScroll = (
    elementID: string
) => {
    
    //! Put the class name that you want to use
    // Class name that will be added to the navbar element in the "scrolled" state
    const SCROLLED_STATE_CLASS = "sticky";

    //! Use your own ID or selector
    // The id of navigation bar HTML element
    const NAVBAR_ID = elementID;

    // Get the navigation bar element
    const stickyElement = document.getElementById(NAVBAR_ID);

    // OnScroll event handler
    const onScroll = () => {

        // Get scroll value
        const scroll = document.documentElement.scrollTop

        // check if element exists on DOM
        if (stickyElement) {
            // If scroll value is more than 0 - means the page is scrolled, add or remove class based on that
            if (scroll > 0) {
                stickyElement.classList.add(SCROLLED_STATE_CLASS);
            } else {
                stickyElement.classList.remove(SCROLLED_STATE_CLASS)
            }
        }
    }

    // subscribe to scroll event
    return window.addEventListener('scroll', onScroll);

}

export default useWindowScroll;