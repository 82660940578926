import i18next from "i18next";
import { LOADING, SET_CURRENT_WEBSITE, SET_CURRENT_WEBSITE_DATA, SET_CURRENT_WEBSITE_PLAN_DATA } from "../../Actions/Types";
import { DomainDetailsApi } from "../../Api/DomainApi/DomainDetailsApi"
import { getViolationsCountApi, getViolationsListApi } from "../../Api/ViolationsApi";
import parseISODate from "../../Helpers/parseISODate";

// service to get domain details and store
const DomainDetailsService = async (
    token: string, //access token
    websiteID: number, // website Id
    dispatch: Function, // function to store redux data
): Promise<any> => {



    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });


    // API to get Domain Details
    await DomainDetailsApi(token, websiteID).then(async (domainResponse) => {


        // Check Domain Details Res
        if (domainResponse.status === 200) {

            // api to get violations Count
            await getViolationsCountApi(token, websiteID).then(async (scanDataRes) => {

                //console.info("getViolationsCountApi =>", scanDataRes.status);

                // check if api response is okay
                if (scanDataRes.status === 200) {

                    // get Scan Violations list API
                    await getViolationsListApi(token, websiteID, i18next.language, 0).then(async (scanErrorViolationRes) => {

                        // check if api response is ok
                        if (scanErrorViolationRes.status === 200) {

                            // api to get successful violation list
                            await getViolationsListApi(token, websiteID, i18next.language, 1).then(async (scanSuccessViolationRes) => {

                                // check if successful violation api status is ok
                                if (scanSuccessViolationRes.status === 200) {

                                    // Accessibility Score Percentage
                                    let accessibilityScore: number;
                                    let successAccessibilityChecks: number = scanDataRes.data[0].successAccessibilityChecks;
                                    let failingAccessibilityChecks: number = scanDataRes.data[0].failingAccessibilityChecks;

                                    // check if total violation is more than 0
                                    if ((successAccessibilityChecks + failingAccessibilityChecks) > 0) {
                                        accessibilityScore = (parseFloat((((successAccessibilityChecks) / (successAccessibilityChecks + failingAccessibilityChecks)) * 100).toFixed(2)))
                                    } else {
                                        accessibilityScore = 0;
                                    }

                                    // Violations Remediated Percentage
                                    let violationsRemediatedPercentage: number;
                                    let successViolations: number = scanSuccessViolationRes.total_counts;
                                    let failedViolations: number = scanErrorViolationRes.total_counts;

                                    // check if total violation is more than 0
                                    if ((successViolations + failedViolations) > 0) {
                                        violationsRemediatedPercentage = (parseFloat((((successViolations) / (successViolations + failedViolations)) * 100).toFixed(2)))
                                    } else {
                                        violationsRemediatedPercentage = 0;
                                    }

                                    // Image Alt Remediated Percentage
                                    let imageAltRemediatedPercentage: number;
                                    let successImageAlt: number = scanDataRes.data[0].total_update_alt_text;
                                    let failedImageAlt: number = scanDataRes.data[0].missing_alt_text;

                                    // check if total image alt text violation is more than 0
                                    if ((successImageAlt + failedImageAlt) > 0) {
                                        imageAltRemediatedPercentage = (parseFloat((((successImageAlt) / (successImageAlt + failedImageAlt)) * 100).toFixed(2)))
                                    } else {
                                        imageAltRemediatedPercentage = 0;
                                    }

                                    // Set Current Website Data
                                    dispatch({
                                        type: SET_CURRENT_WEBSITE_DATA,
                                        payload: {
                                            "accessibilityScore": accessibilityScore,
                                            "violationsRemediatedPercentage": violationsRemediatedPercentage,
                                            "successViolations": successViolations,
                                            "failedViolations": failedViolations,
                                            "imageAltRemediatedPercentage": imageAltRemediatedPercentage,
                                            "successImageAlt": successImageAlt,
                                            "failedImageAlt": failedImageAlt,
                                            "successViolationsList": scanSuccessViolationRes.Data,
                                            "failedViolationsList": scanErrorViolationRes.Data,
                                        }
                                    });

                                    // Set Current Website Plan Details

                                    let isExpiredFlag: boolean = false;
                                    let isTrialFlag: boolean = false;
                                    let isInitFlag: boolean = false;
                                    let isFreeWidget: boolean = false;
                                    let isProPlanFlag: boolean = false;
                                    let isCanceledPlanFlag: boolean = false;
                                    let tempPlanName: string | null = null;
                                    let tempPlanExpiredData: string | null = null;
                                    let tempPlanType: string | null = null;

                                    // check if domain data exists
                                    if (domainResponse.Data && domainResponse.Data.length > 0) {
                                        // check if plan is expired
                                        if (domainResponse.Data[0].is_expired === 1) {

                                            // check if expired plan details available
                                            if (domainResponse.Data[0].expired_package_detail) {
                                                isExpiredFlag = true;
                                                isTrialFlag = false;
                                                isInitFlag = false;
                                                isFreeWidget = false;
                                                isCanceledPlanFlag = false;
                                                isProPlanFlag = false;
                                                tempPlanName = domainResponse.Data[0].expired_package_detail[0].name;
                                                tempPlanType = domainResponse.Data[0].expired_package_detail[0].platforms;
                                                tempPlanExpiredData = parseISODate(domainResponse.Data[0].expired_package_detail[0].end_date);
                                            }

                                        }
                                        // check if plan is a trial plan
                                        else if ((domainResponse.Data[0].is_expired === 3) && (domainResponse.Data[0].current_package_only && domainResponse.Data[0].current_package_only.length > 0)) {
                                            isExpiredFlag = false;
                                            isTrialFlag = true;
                                            isCanceledPlanFlag = false;
                                            isProPlanFlag = false;
                                            isFreeWidget = false;
                                            isInitFlag = (successViolations === 0 && failedViolations === 0) ? true : false;
                                            tempPlanName = domainResponse.Data[0].current_package_only[0].name;
                                            tempPlanType = domainResponse.Data[0].current_package_only[0].platforms;
                                            tempPlanExpiredData = parseISODate(domainResponse.Data[0].current_package_only[0].end_date);

                                        }
                                        // check if plan is cancelled
                                        else if (domainResponse.Data[0].canceled_package_only && domainResponse.Data[0].canceled_package_only.length > 0) {

                                            isExpiredFlag = false;
                                            isTrialFlag = false;
                                            isInitFlag = ((successViolations === 0 && failedViolations === 0) ? true : false);
                                            isFreeWidget = false;
                                            isProPlanFlag = false;
                                            isCanceledPlanFlag = true;
                                            tempPlanName = domainResponse.Data[0].canceled_package_only[0].name;
                                            tempPlanType = domainResponse.Data[0].canceled_package_only[0].platforms;
                                            tempPlanExpiredData = parseISODate(domainResponse.Data[0].canceled_package_only[0].end_date);

                                        }
                                        else {

                                            // check if current plan package data is available
                                            if (domainResponse.Data[0].current_package_only && domainResponse.Data[0].current_package_only.length > 0) {

                                                // check if plan is free widget
                                                if (domainResponse.Data[0].current_package_only[0].is_free_widget === 1) {

                                                    // Free Plan Widget
                                                    isExpiredFlag = false;
                                                    isTrialFlag = false;
                                                    isInitFlag = ((successViolations === 0 && failedViolations === 0) ? true : false);
                                                    isFreeWidget = true;
                                                    isProPlanFlag = false;
                                                    isCanceledPlanFlag = false;
                                                    tempPlanName = domainResponse.Data[0].current_package_only[0].name;
                                                    tempPlanType = domainResponse.Data[0].current_package_only[0].platforms;
                                                    tempPlanExpiredData = parseISODate(domainResponse.Data[0].current_package_only[0].end_date);

                                                } else {

                                                    /* console.info("Pro Widget"); */
                                                    // Pro Plan Widget
                                                    isExpiredFlag = false;
                                                    isTrialFlag = false;
                                                    isInitFlag = ((successViolations === 0 && failedViolations === 0) ? true : false);
                                                    isFreeWidget = false;
                                                    isCanceledPlanFlag = false;
                                                    isProPlanFlag = true;
                                                    tempPlanName = domainResponse.Data[0].current_package_only[0].name;
                                                    tempPlanType = domainResponse.Data[0].current_package_only[0].platforms;
                                                    tempPlanExpiredData = parseISODate(domainResponse.Data[0].current_package_only[0].end_date);
                                                }

                                            } else {

                                                /* console.info("Null Widget"); */
                                                // No Plan Found
                                                isExpiredFlag = false;
                                                isTrialFlag = false;
                                                isInitFlag = false;
                                                isFreeWidget = false;
                                                isProPlanFlag = false;
                                                isCanceledPlanFlag = false;
                                                tempPlanName = null;
                                                tempPlanExpiredData = null;
                                                tempPlanType = null;
                                            }

                                        }
                                    }
                                    // dispatch current website plan details
                                    dispatch({
                                        type: SET_CURRENT_WEBSITE_PLAN_DATA,
                                        payload: {
                                            isExpired: isExpiredFlag,
                                            isTrial: isTrialFlag,
                                            isInit: isInitFlag,
                                            isFreeWidget: isFreeWidget,
                                            isProPlan: isProPlanFlag,
                                            isCanceledPlan: isCanceledPlanFlag,
                                            planName: tempPlanName,
                                            planType: tempPlanType,
                                            planExpiredData: tempPlanExpiredData
                                        }
                                    });

                                    // Set Current Website Data
                                    dispatch({
                                        type: SET_CURRENT_WEBSITE,
                                        payload: domainResponse.Data[0]
                                    });


                                    // remove Loading
                                    dispatch({
                                        type: LOADING,
                                        payload: false,
                                    });


                                    //console.info("Promise.resolve call");

                                    // Res Msg
                                    /* dispatch({
                                        type: SET_MESSAGE,
                                        payload: {
                                            "toast_message": t('Login Success Msg'),
                                            "toast_type": "success",
                                            "onclose": null
                                        }
                                    }) */
                                }
                            })

                        }

                    })

                }
            })

        } else {


            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            //return Promise.resolve(200);

            // Res Msg
            /* dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Login Fail Msg'),
                    "toast_type": "danger",
                }
            }) */
        }

    })
    return { status: 200 };

}

/* const DomainDetailsWithoutViolationsService = async (
    token: string,
    websiteID: number,
    dispatch: Function,
    t: Function
): Promise<any> => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // Domain Details
    const DomainDetailsApiRes = DomainDetailsApi(token, websiteID).then(async (domainResponse) => {

        // Check Domain Details Res
        if (domainResponse.status === 200) {

            // Set Current Website Data
            dispatch({
                type: SET_CURRENT_WEBSITE,
                payload: domainResponse.Data[0]
            });

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            return { status: 200 }

        } else {

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            return { status: 400 }
        }

    })
    return DomainDetailsApiRes;

} */

export { DomainDetailsService }
