import { Badge, Form } from "react-bootstrap";
import { DraggableProvidedDragHandleProps, DraggableProvidedDraggableProps, DraggableStateSnapshot } from "react-beautiful-dnd";
import { forwardRef } from "react";
import { MenulistPropTypes } from "../../../PropTypes/EnableDisableServicesPropTypes";
import { useTranslation } from "react-i18next";


interface PropTypes {
    item: MenulistPropTypes, // language object
    childrenRef: any, // element reference 
    dragHandleProps: DraggableProvidedDragHandleProps | null | undefined, // drag handle props
    otherProp: DraggableProvidedDraggableProps // draggable props
    snapshot: DraggableStateSnapshot, // draggable snapshot
    handleLanguageStatus: Function, // function to handle language status
    isDisabled: boolean // disabled flag 
}

// component to render language list element
const LanguagesListChildrenElement = forwardRef((prop: PropTypes) => {

    const { t } = useTranslation();
    const { item, childrenRef, otherProp, snapshot, dragHandleProps, handleLanguageStatus, isDisabled } = prop;
   
    return (
        <div className={`menu-box border ${snapshot.isDragging ? `hovering` : ``}`} ref={childrenRef} {...otherProp}>
            <div className={`drag-icon ${isDisabled ? 'opacity-25' : ''}`} style={{ cursor: isDisabled ? 'not-allowed' : 'all-scroll' }} {...dragHandleProps}>
                <i className="material-symbols-outlined" aria-hidden="true">drag_indicator</i>
            </div>
            <div className="menu-name icon-with-name ms-2 me-2">
                <div className="icon border"> {item.slug.length > 2 ? item.slug.slice(-2) : item.slug} </div>
                <div className="name h6 mb-0 ms-2">{item.name} </div>
                {isDisabled ? <Badge bg="primary ms-2">{t("Default")}</Badge> : <></>}
            </div>
            <div className="action-part">
                <Form.Check
                    className="form-check m-0 p-0 d-flex align-items-center"
                    type="checkbox"
                    id={`accessibility-menu-${item.id}`}
                    area-label={`accessibility`}
                    onChange={(e) => handleLanguageStatus(e, item.id)}
                    defaultChecked={item.status === 1 ? true : false}
                    disabled={isDisabled}
                />
            </div>
        </div>
    )
});

export default LanguagesListChildrenElement;