import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { getPDFListApi, getQuotesPDFListApi, directPaymentApi, getPaymentLinkApi, cancelRemediationQuotePDFApi } from "../../Api/PDFLApi";

// service to get pdf list
const getPDFListService = (
    accessToken: string, // access token
    dispatch: Function, // dispatch function to save redux data
    setList: Function, // function to set pdf list
    setTotalRecord: Function, // function to set total record
    setTotalInaccessiblePdfs: Function, // function to set inaccessible pdf list
    setTotalRemediatedPdfs: Function, // function to set remediated pdf list
    offset: number, // record offset
    limit: number, // limit per page
    website_id: number, // website Id
    setPrePurchasePDFTotalCount: Function, // function to set pre-purchased pdf total
    setPrePurchasePDFRemainingCount: Function, // function to set pre-purchased remaining pdf total
    filterValue?: string, // filter by string
    sortBy?: string, // sort by string
) => {
    // set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // api to get pdf list
    getPDFListApi(accessToken, offset, limit, website_id, filterValue, sortBy).then(response => {
        // check if response data is available
        if (response.Data) {
            setPrePurchasePDFTotalCount(response.total_pre_purchased_pages);
            setPrePurchasePDFRemainingCount(response.total_pre_purchased_remaining_pages);
            setList(response.Data);
            setTotalRecord(response.all_records_count);
            setTotalInaccessiblePdfs(response.total_inaccessible_pdfs);
            setTotalRemediatedPdfs(response.total_remediated_pdfs);

            // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
        } else {
            // remove Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
        }

    })
}

// service to get quoted pdf list
const getQuotesPDFListService = (
    accessToken: string, // access token
    website_id: number, // website ID
    setQuotesPDFLoading: Function, // function to set loader
    setQuotesPDFList: Function, // function to set pdf list
    setTotalQuotesPDFs: Function, // function to set total pdf count
    setTotalQuotesPDFsPrice: Function, // function to set price
    setQuotesPDFsPurchaseURL: Function, // function to set purchase url
    remediatedPDFsStatus: number // flag to get remediated pdfs
) => {

    setQuotesPDFLoading(true);

    // api to get quoted pdf list
    getQuotesPDFListApi(accessToken, website_id, remediatedPDFsStatus).then(response => {
        if (response.Data) {
            setQuotesPDFList(response.Data);
            setTotalQuotesPDFs(response.total_record);
            setTotalQuotesPDFsPrice(response.total_price)
            setQuotesPDFsPurchaseURL(response.purchase_url)
        }
        setQuotesPDFLoading(false);
    })
}

// service remediated pdf list
const getRemediatedPDFListService = (
    accessToken: string, // access token
    website_id: number, // website id
    setRemediatedPDFLoading: Function, // function to loading
    setRemediatedPDFList: Function, // function to set pdf remediation list 
    setTotalRemediatedPDFs: Function, // function to set total number
    remediatedPDFsStatus?: number // function to set remediated pdf status
) => {

    setRemediatedPDFLoading(true);

    // API to get pdf list
    getQuotesPDFListApi(accessToken, website_id, remediatedPDFsStatus).then(response => {
        if (response.Data) {
            setRemediatedPDFList(response.Data);
            setTotalRemediatedPDFs(response.total_record);
        }
        setRemediatedPDFLoading(false);
    })
}

// service to redirect to payment for pdf
const directPaymentService = (
    accessToken: string, // access token
    website_id: number, // website id
    pdfIds: string, // pdf id
    setPaymentBtnLoading: Function, // function to set loader
    setSelectPdf: Function, // function to set selected pdfs
    navigate: Function, // function to navigate routes
    setList: Function, // function to set pdf list
    setTotalRecord: Function, // function to set total records
    setTotalInaccessiblePdfs: Function, // function to set total inaccessible pdfs
    setTotalRemediatedPdfs: Function, // function to set total remediated pdfs
    offset: number, // record offset
    limit: number, // limit per page
    setPrePurchasePDFTotalCount: Function, // function to set pre-purchased pdf total count
    setPrePurchasePDFRemainingCount: Function, // function to set pre-purchased remaining pdf count
    flag?: number // flag
) => {

    // set loader
    setPaymentBtnLoading(true);

    // api to handle direct payment
    directPaymentApi(accessToken, website_id, pdfIds, flag).then(response => {

        // check if flag available
        if (flag && flag === 1) {

            // API to get pdf list
            getPDFListApi(accessToken, offset, limit, website_id).then(response => {
                // check if response data is available
                if (response.Data) {
                    setPrePurchasePDFTotalCount(response.total_pre_purchased_pages);
                    setPrePurchasePDFRemainingCount(response.total_pre_purchased_remaining_pages);
                    setList(response.Data);
                    setTotalRecord(response.all_records_count);
                    setTotalInaccessiblePdfs(response.total_inaccessible_pdfs);
                    setTotalRemediatedPdfs(response.total_remediated_pdfs);
                }

            })
        } else {
            // check if purchase token is available
            if (response.purchase_token) {
                //window.open(response.purchase_url, '_blank', 'noreferrer');
                navigate(`/front/pdf-package/${response.purchase_token}`)
            }
        }

        setPaymentBtnLoading(false);
        setSelectPdf([]);
    })
}

// service to get payment link
const getPaymentLinkService = (
    accessToken: string, // access token
    website_id: number, // website id
    pdfIds: string, // pdf id
    setPaymentBtnLoading: Function, // function to set loader
    setSelectPdf: Function // function to select pdfs
) => {
    // set loader
    setPaymentBtnLoading(true);

    // api to get payment link
    getPaymentLinkApi(accessToken, website_id, pdfIds).then(response => {
        // check if purchase url exists
        if (response.purchase_url) {
            window.open(response.purchase_url, '_blank', 'noreferrer');
        }
        setPaymentBtnLoading(false);
        setSelectPdf([]);
    })
}


// function to cancel remediation quote
const cancelRemediationQuotePDF = (
    accessToken: string, // access token
    name: string | null, // name
    email: string, // email
    phone: string | null, // phone
    subject: string, // subject
    website_id: number, // website Id
    plan_id: number, // Plan Id
    is_pdf_remediation: number, // flag if need to cancel remediation quote
    is_quote_removed: number, // flag if quote is removed
    pdfRemoveID: string, // pdf id to remove from quote
    apiRecall: boolean, // flag of api recall
    setRecallAPI: Function, // function to set api recall flag
    setShowModal: Function, // function to show modal
    t: Function, // i18next t function
    dispatch: Function // dispatch function
) => {

    // api to cancel remediation quote
    cancelRemediationQuotePDFApi(accessToken, name, email, phone, subject, website_id, plan_id, is_pdf_remediation, is_quote_removed, pdfRemoveID).then(response => {
        // check if response data exists
        if (response.Data) {
            // hide modal
            setShowModal(false);

            // dispatch success message
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t("PDF quote request cancelled successfully."),
                    "toast_type": "success"
                }
            });

            // set api recall flag
            setRecallAPI(!apiRecall);
        }
        else {
            // dispatch failure message
            dispatch({
                type: SET_MESSAGE,
                //payload: t("Cancelling PDF quote request failed. Please try again later.")
                payload: {
                    "toast_message": t("Cancelling PDF quote request failed. Please try again later."),
                    "toast_type": "danger"
                }
            })
        }

    })
}

export { getPDFListService, getQuotesPDFListService, getRemediatedPDFListService, directPaymentService, getPaymentLinkService, cancelRemediationQuotePDF }