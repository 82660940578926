import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { Accordion, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import Select from 'react-select';
import WidgetIconType from "../WidgetSettingsComponents/WidgetIconType";
import WidgetIconSize from "../WidgetSettingsComponents/WidgetIconSize";
import WidgetIconColor from "../WidgetSettingsComponents/WidgetIconColor";
import WidgetIconPosition from "../WidgetSettingsComponents/WidgetIconPosition";
import WidgetSize from "../WidgetSettingsComponents/WidgetSize";
import WidgetAccessibilityStatementLink from "../WidgetSettingsComponents/AccessibilityStatementLink";
import AnalyticsTrackingCode from "../WidgetSettingsComponents/AnalyticsTracking";
import updateWidgetSettingsService from "../../Services/UpdateWidgetSettingsService";
import WidgetIconSizeMobile from "../WidgetSettingsComponents/WidgetIconSizeMobile";
import voiceSampleText from "../../VoiceSampleText/voiceSampleText.json";


import "../../Assets/scss/pages/widget-settings.scss";
import "../../Assets/scss/components/widget-preview-layout.scss";
import WidgetLayoutMain from "../WidgetPreview";
import useWindowSize from "../../Helpers/useWindowSize";
import { GoogleGetVoicesService } from "../../Services/GoogleTTSService";
import GoogleTTSVoicePropTypes from "../../PropTypes/GoogleTTSVoicePropTypes";
import { GoogleSynthesizeSpeechAPI } from "../../Api/GoogleTTSAPI";
import select2LangPropTypes from "../../PropTypes/select2LangPropTypes";
import { useLocation } from "react-router-dom";

interface voiceSampleTextPropTypes {
  [key: string]: string
}


// component to render widget settings
const WidgetSettings = () => {

  // Redux Data
  const { currentWebsite, accessToken, currentWebsitePlanStatus, languageList, googleVoices } = useSelector((store: StoreProptypes) => store);

  // Functions
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const windowWidth = useWindowSize();

  // State
  const [changeWidgetIconType, setChangeWidgetIconType] = useState<number>(1);
  const [changeWidgetIconSize, setChangeWidgetIconSize] = useState<number>(3);
  const [isChangeWidgetIconCustomSize, setIsChangeWidgetIconCustomSize] = useState<boolean>(false);
  const [changeWidgetCustomIconSize, setChangeWidgetCustomIconSize] = useState<number>(50);
  const [isShowCustomWidgetIconSizeMobile, setIsShowCustomWidgetIconSizeMobile] = useState<boolean>(false); // Not Include in API
  const [changeWidgetIconSizeMobile, setChangeWidgetIconSizeMobile] = useState<number>(3);
  const [isChangeWidgetIconCustomSizeMobile, setIsChangeWidgetIconCustomSizeMobile] = useState<boolean>(false);
  const [changeWidgetIconCustomSizeMobile, setChangeWidgetIconCustomSizeMobile] = useState<number>(20);
  const [changeWidgetIconColor, setChangeWidgetIconColor] = useState<string>('#420083');
  const [changeWidgetIconPosition, setChangeWidgetIconPosition] = useState<string>('bottom_right');

  const [isChangeWidgetIconCustomPosition, setIsChangeWidgetIconCustomPosition] = useState<boolean>(false);
  const [changeWidgetIconCustomLeftPosition, setChangeWidgetIconCustomLeftPosition] = useState<number | null>(null);
  const [changeWidgetIconCustomRightPosition, setChangeWidgetIconCustomRightPosition] = useState<number | null>(0);
  const [changeWidgetIconCustomTopPosition, setChangeWidgetIconCustomTopPosition] = useState<number | null>(null);
  const [changeWidgetIconCustomBottomPosition, setChangeWidgetIconCustomBottomPosition] = useState<number | null>(0);

  const [changeWidgetSize, setChangeWidgetSize] = useState<number>(0);
  const [changeWidgetAccessibilityStatementLink, setChangeWidgetAccessibilityStatementLink] = useState<string>('');
  const [changeGaID, setChangeGaID] = useState<string>('');
  const [changeAdobeID, setChangeAdobeID] = useState<string>('');

  const [selectLangData, setSelectLangData] = useState<select2LangPropTypes[]>();

  const [selectedLangData, setSelectedLangData] = useState<select2LangPropTypes>();


  const [filteredGoogleVoices, setFilteredGoogleVoices] = useState<GoogleTTSVoicePropTypes[]>();
  const [selectedVoiceForPreview, setSelectedVoiceForPreview] = useState<GoogleTTSVoicePropTypes>();
  const [selectedVoice, setSelectedVoice] = useState<GoogleTTSVoicePropTypes>();
  const [loadingVoice, setLoadingVoice] = useState(false);
  const [audioSrc, setAudioSrc] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    // check if language list and default language are available
    if (currentWebsite && currentWebsite.default_language && languageList) {

      // get language object from language list
      const temp = languageList.filter(language => {
        // check if language code is same as default language
        if (language.code === currentWebsite.default_language) {
          return true
        }
      }).map(language => {
        // create object with required format
        return {
          value: language.code,
          label: language.original_name
        }
      })

      // set selected language
      if (temp.length) {
        setSelectedLangData(temp[0]);
      }
    }

  }, [currentWebsite, languageList])

  useEffect(() => {
    // check if default voice and voice list are available
    if (currentWebsite && currentWebsite.default_voice && googleVoices) {

      // get voice object from the voice list
      const temp = googleVoices.filter(voice => {
        if (voice.name === currentWebsite.default_voice) {
          return true
        }
      })

      if (temp.length) {
        setSelectedVoice(temp[0]);
      }
    }

  }, [currentWebsite, googleVoices])

  useEffect(() => {
    // check is language list is available
    if (languageList) {
      //get language object from the list
      const list = languageList.map(lang => {
        return {
          value: lang.code,
          label: lang.original_name
        }
      });
      setSelectLangData(list);
    }
  }, [languageList])

  useEffect(() => {
    // check if selected lang data is not available, and select language data is available
    if (!selectedLangData && selectLangData) {
      setSelectedLangData(selectLangData[0]);
    }
  }, [selectLangData])

  useEffect(() => {

    // check if selected voice and google voices list are available
    if (!selectedVoice && filteredGoogleVoices) {
      setSelectedVoice(filteredGoogleVoices[0]);
    }

    // check if language is changed, set first google voice as selected voice
    if (currentWebsite && selectedLangData && (currentWebsite.default_language !== selectedLangData.value) && filteredGoogleVoices) {
      setSelectedVoice(filteredGoogleVoices[0]);
    }

  }, [filteredGoogleVoices, selectedLangData])

  // function to handle save settings
  const handleSubmitUpdateWidgetSettings = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // check if access token, current website, selected language data and selected voice available
    if (accessToken && currentWebsite && selectedLangData && selectedVoice) {

      // service to update widget settings
      updateWidgetSettingsService(
        accessToken,
        currentWebsite.id,
        dispatch,
        t,
        changeWidgetIconType,
        isChangeWidgetIconCustomSize,
        changeWidgetCustomIconSize,
        isShowCustomWidgetIconSizeMobile,
        changeWidgetIconSizeMobile,
        isChangeWidgetIconCustomSizeMobile,
        changeWidgetIconCustomSizeMobile,
        changeWidgetIconSize,
        changeWidgetIconColor,
        changeWidgetIconPosition,
        isChangeWidgetIconCustomPosition,
        changeWidgetIconCustomLeftPosition,
        changeWidgetIconCustomRightPosition,
        changeWidgetIconCustomTopPosition,
        changeWidgetIconCustomBottomPosition,
        changeWidgetSize,
        changeWidgetAccessibilityStatementLink,
        changeGaID,
        changeAdobeID,
        selectedLangData.value,
        selectedVoice.name,
        setShowSuccessModal
      )
    }

  }




  useEffect(() => {
    // check if google voices list is not available
    if (!googleVoices) {
      // service to get google voices list
      GoogleGetVoicesService(dispatch);
    }
  }, [googleVoices]);

  useEffect(() => {
    // check if google voice list and selected language data is available
    if (googleVoices && selectedLangData) {

      let selectedLangCode = selectedLangData.value;

      // check if lang code is en, then set it to en-US
      if (selectedLangCode === 'en') {
        selectedLangCode = "en-US";
      }

      // filter google voices by matching exact language code
      let temp = googleVoices.filter(voice => {

        const langCode = voice.languageCodes[0];

        // check if language code matches exactly same as google voice language code
        if (selectedLangCode.toLowerCase() === langCode.toLowerCase()) {
          return true
        }
      });

      // check if filtered voice list is empty
      if (!temp.length) {
        
        // filter google voices by matching two letters of google voice language code 
        temp = googleVoices.filter(voice => {
          const langCode = voice.languageCodes[0];
          // check if language code matches first two letter of google voice language code 
          if (selectedLangCode === langCode.slice(0, 2).toLowerCase()) {
            return true
          }
        });
      }


      // check if filtered voice list is still empty
      if (!temp.length) {
        // filter google voices by matching two letters of selected language with two letters of google voice language code 
        temp = googleVoices.filter(voice => {
          const langCode = voice.languageCodes[0];
          // check if first two letters of language code matches first two letter of google voice language code 
          if (selectedLangCode.slice(0, 2) === langCode.slice(0, 2).toLowerCase()) {
            return true
          }
        });
      }

      // check if filtered voice is still empty
      if (!temp.length) {
        // use default en-us voice for that language
        temp = googleVoices.filter(voice => {
          const langCode = voice.languageCodes[0];
          // check if lang code is en-US
          if (langCode === "en-US") {
            return true
          }
        });
      }


      // set filtered google voices list
      temp && setFilteredGoogleVoices(temp);


    }
  }, [googleVoices, selectedLangData])


  // function to handle play
  const handlePlay = async (voice: GoogleTTSVoicePropTypes) => {

    const landCode = voice.languageCodes[0];
    const allText = voiceSampleText as voiceSampleTextPropTypes;
    let selectedText = allText[landCode];

    // check if text for voice is not selected
    if (!selectedText) {
      selectedText = allText[landCode.toLowerCase()]
    }

    // check if text for voice is not selected, set it by slicing language code
    if (!selectedText) {
      selectedText = allText[landCode.slice(0, 2)]
    }

    // check if text for voice is not selected, set it by slicing language code with lowercase
    if (!selectedText) {
      selectedText = allText[landCode.toLowerCase().slice(0, 2)]
    }

    // check if text for voice is not selected, use en-US as fallback
    if (!selectedText) {
      selectedText = allText["en-US"];
    }
    //console.log("selectedText", selectedText);

    // sets selected voice for preview
    setSelectedVoiceForPreview(voice);

    // sets loader on play button
    setLoadingVoice(true);

    try {
      // get voice data from google TTS API
      const data = await GoogleSynthesizeSpeechAPI(selectedText, voice.name, voice.languageCodes[0]);
      const audioContent = data.audioContent;
      
      // create audio URL
      const blob = new Blob([new Uint8Array(atob(audioContent).split("").map(c => c.charCodeAt(0)))], { type: 'audio/mp3' });
      const audioUrl = URL.createObjectURL(blob);

      // set audio source
      setAudioSrc(audioUrl);
      // remove loader
      setLoadingVoice(false);
      // set play
      setIsPlaying(true);
    } catch (err) {
      console.error('Error playing audio:', err);
    }
  };

  // function to stop audio play
  const handleStop = () => {
    if (audioRef.current) {
      audioRef.current.pause(); // Pause the audio
      audioRef.current.currentTime = 0; // Reset playback position
      setIsPlaying(false); // Set isPlaying to false
      setAudioSrc(null); // Reset audio source
    }
  };



  return (
    <>
      <div className="aioa_dashboard-widget-settings aioa_dashboard-settings-with-preview-wrapper">
        <Row>

          <Col className="aioa-dashboard-widget-preview-settings-col aioa_dashboard-widget-settings-wrapper">
            <Accordion defaultActiveKey={windowWidth.windowSize > 580 ? ['0', '1', '2', '3', '4', '5', '6', '7', '8'] : [`0`]} alwaysOpen>


              <Accordion.Item eventKey="0">
                <Accordion.Header className="h4">{t("Select Widget Icon Type")}</Accordion.Header>
                <Accordion.Body>
                  <WidgetIconType setChangeWidgetIconType={setChangeWidgetIconType} changeWidgetIconColor={changeWidgetIconColor} />
                </Accordion.Body>
              </Accordion.Item>




              <Accordion.Item eventKey="1">
                <Accordion.Header>{isShowCustomWidgetIconSizeMobile ? t('Select Widget Icon Size For Desktop') : t("Select Widget Icon Size")}</Accordion.Header>
                <Accordion.Body>
                  <WidgetIconSize
                    setChangeWidgetIconSize={setChangeWidgetIconSize}
                    changeWidgetIconColor={changeWidgetIconColor}
                    setIsChangeWidgetIconCustomSize={setIsChangeWidgetIconCustomSize}
                    setChangeWidgetCustomIconSize={setChangeWidgetCustomIconSize}
                    isShowCustomWidgetIconSizeMobile={isShowCustomWidgetIconSizeMobile}
                    setIsShowCustomWidgetIconSizeMobile={setIsShowCustomWidgetIconSizeMobile}
                  />
                </Accordion.Body>
              </Accordion.Item>

              {(!currentWebsitePlanStatus.isFreeWidget && isShowCustomWidgetIconSizeMobile) && (
                <>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>{t("Select Widget Icon Size For Mobile")}</Accordion.Header>
                    <Accordion.Body>
                      <WidgetIconSizeMobile
                        setChangeWidgetIconSizeMobile={setChangeWidgetIconSizeMobile}
                        setIsChangeWidgetIconCustomSizeMobile={setIsChangeWidgetIconCustomSizeMobile}
                        setChangeWidgetIconCustomSizeMobile={setChangeWidgetIconCustomSizeMobile}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </>
              )}

              <Accordion.Item eventKey="2">
                <Accordion.Header>{t("Select Widget Color")}</Accordion.Header>
                <Accordion.Body>
                  <WidgetIconColor setChangeWidgetIconColor={setChangeWidgetIconColor} changeWidgetIconColor={changeWidgetIconColor} />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>{t("Select Widget Position")}</Accordion.Header>
                <Accordion.Body>
                  <WidgetIconPosition
                    setChangeWidgetIconPosition={setChangeWidgetIconPosition}
                    setIsChangeWidgetIconCustomPosition={setIsChangeWidgetIconCustomPosition}
                    setChangeWidgetIconCustomLeftPosition={setChangeWidgetIconCustomLeftPosition}
                    setChangeWidgetIconCustomRightPosition={setChangeWidgetIconCustomRightPosition}
                    setChangeWidgetIconCustomTopPosition={setChangeWidgetIconCustomTopPosition}
                    setChangeWidgetIconCustomBottomPosition={setChangeWidgetIconCustomBottomPosition}
                  />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>{t("widget size label")}</Accordion.Header>
                <Accordion.Body>
                  <WidgetSize setChangeWidgetSize={setChangeWidgetSize} />
                </Accordion.Body>
              </Accordion.Item>

              {!currentWebsitePlanStatus.isFreeWidget ? <>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>{t("Select Widget Language")}</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col lg={8}>

                        <Form.Label htmlFor='language_select' aria-hidden={true}>{t('Select default language for the widget')}</Form.Label>
                        <Select
                          id="language_select"
                          onChange={(e) => { setSelectedLangData({ label: e ? e.label : "", value: e ? e.value : "" }) }}
                          options={selectLangData}
                          defaultValue={selectedLangData}
                          value={selectedLangData}
                          closeMenuOnSelect={true}
                          backspaceRemovesValue={true}
                          isClearable={false}
                          isSearchable={true}
                          placeholder={`${t('Select default language for the widget')}...`}
                          className="aioa_dashboard-custom-language-select"
                          aria-label={t('Select default language for the widget')}
                          classNamePrefix="lang-select"
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: '#d9cce6',
                              primary: '#420083',
                            },
                          })}
                        />
                      </Col>
                    </Row>

                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                  <Accordion.Header>{t("Select Screen Reader Voice")}</Accordion.Header>
                  <Accordion.Body>

                    {filteredGoogleVoices ? <>
                      <div className="voice-selection-table-wrapper">
                        {audioSrc && (
                          <audio ref={audioRef} src={audioSrc} onEnded={() => setIsPlaying(false)} autoPlay />
                        )}
                        <Table striped>
                          <thead>
                            <tr>
                              <th scope="column">{t("Language")}</th>
                              <th scope="column">{t("Name")}</th>
                              <th scope="column">{t("Gender")}</th>
                              <th scope="column" className="text-center">{t("Preview")}</th>
                              <th scope="column" className="text-center"> </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredGoogleVoices?.map(voice => {
                              return (
                                <tr key={voice.name}>
                                  <td>{/* {selectedLangData?.label} */}{voice.languageCodes[0]}</td>
                                  <td>{voice.name}</td>
                                  <td>{voice.ssmlGender}</td>
                                  <td className="text-center">
                                    {(selectedVoiceForPreview?.name === voice.name) ? <>


                                      {isPlaying ? <Button variant="link" aria-label={t('Stop VoiceName', { voiceName: voice.name })} onClick={() => handleStop()} disabled={loadingVoice}>
                                        <span className="material-symbols-outlined">stop_circle</span>
                                      </Button> :
                                        <Button variant="link" aria-label={t('Play VoiceName', { voiceName: voice.name })} onClick={() => handlePlay(voice)} disabled={loadingVoice}>
                                          <span className="material-symbols-outlined">play_circle</span>
                                        </Button>
                                      }

                                    </> : <>
                                      <Button variant="link" aria-label={t('Play VoiceName', { voiceName: voice.name })} onClick={() => handlePlay(voice)} disabled={loadingVoice}>
                                        <span className="material-symbols-outlined">play_circle</span>
                                      </Button>
                                    </>}



                                  </td>
                                  <td className="text-center">
                                    <Form.Check
                                      type="radio"
                                      id={voice.name}
                                      aria-label={voice.name}
                                      name="voice-selection"
                                      onChange={() => setSelectedVoice(voice)}
                                      checked={selectedVoice === voice}
                                    />
                                  </td>
                                </tr>
                              )
                            })}

                          </tbody>
                        </Table>
                      </div>
                    </> : <></>}


                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header>{t("accessibility statement link label")}</Accordion.Header>
                  <Accordion.Body>
                    <WidgetAccessibilityStatementLink setChangeWidgetAccessibilityStatementLink={setChangeWidgetAccessibilityStatementLink} />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                  <Accordion.Header>{t("Analytics Tracking")}</Accordion.Header>
                  <Accordion.Body>
                    <AnalyticsTrackingCode setChangeAdobeID={setChangeAdobeID} setChangeGaID={setChangeGaID} />
                  </Accordion.Body>
                </Accordion.Item>

              </> : <></>}



              {((!currentWebsitePlanStatus.isExpired) || (currentWebsitePlanStatus.planExpiredData !== null || currentWebsitePlanStatus.planName !== null)) && (
                <>
                  <div className="aioa_dashboard-widget-settings-screen-btns">
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      className="mb-0"
                      onClick={(e) => handleSubmitUpdateWidgetSettings(e)}
                      disabled={currentWebsitePlanStatus.isExpired}
                    >
                      {t("Save Settings")}
                    </Button>

                    <Button
                      variant="secondary"
                      size="lg"
                      className={`widget-preview-btn d-xl-none`}
                    //onClick={widgetPreviewPopupShow}
                    >
                      {t(`Widget Preview`)}
                    </Button>
                  </div>
                </>
              )}

            </Accordion>
          </Col>
          {(windowWidth.windowSize > 1199.98 && location.pathname !== "/front/web-widget-settings") && (
            <>
              <Col className="aioa-dashboard-widget-preview-col">
                <WidgetLayoutMain
                  changeWidgetIconType={changeWidgetIconType}
                  changeWidgetIconSize={changeWidgetIconSize}
                  isChangeWidgetIconCustomSize={isChangeWidgetIconCustomSize}
                  changeWidgetCustomIconSize={changeWidgetCustomIconSize}
                  changeWidgetIconColor={changeWidgetIconColor}
                  changeWidgetIconPosition={changeWidgetIconPosition}
                  isChangeWidgetIconCustomPosition={isChangeWidgetIconCustomPosition}
                  changeWidgetIconCustomLeftPosition={changeWidgetIconCustomLeftPosition}
                  changeWidgetIconCustomRightPosition={changeWidgetIconCustomRightPosition}
                  changeWidgetIconCustomTopPosition={changeWidgetIconCustomTopPosition}
                  changeWidgetIconCustomBottomPosition={changeWidgetIconCustomBottomPosition}
                  changeWidgetSize={changeWidgetSize}
                  isShowIconPreview={true}
                  selectedLangData={selectedLangData}
                />
              </Col>
            </>
          )}
        </Row>
      </div>

      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Widget Settings Updated!")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('widget settings update success msg')}</p>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default WidgetSettings;