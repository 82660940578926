import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { acceptOfferApi, getSurveyDataApi, packageCancelSubscriptionApi } from "../../Api/SurveyDataApi";
import { DomainDetailsService } from "../DomainService/DomainDetails";
import ReactGA from "react-ga4";
import { getUserDetailsService } from "../UserAction/LoginActionService";

// service to get survey reasons list
const getSurveyDataService = (
    setSurveyData: Function // function to set reasons list
) => {
    // api to get survey reasons list
    getSurveyDataApi().then(response => {
        // check if response data is available
        if (response.Data && response.Data.length > 0) {
            setSurveyData(response.Data);
        }
    })
}

// service to accept offer on cancellation
const acceptOfferService = (
    accessToken: string, // access token
    trialPlanID: number, // trial plan id
    websiteID: number, // website id
    handleCloseSurveyFormModal: Function, // function to close survey form
    dispatch: Function, // function to store redux data
    t: Function, // i18next t function
    navigate: Function // function to navigate url
) => {

    // set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // API to accept offer on cancellation
    acceptOfferApi(accessToken, trialPlanID, websiteID).then(async (response) => {
        // check if response is ok
        if (response.status === 200) {

            // service to get user details
            getUserDetailsService(accessToken, dispatch).then(async (userRes) => {
                // check if response is ok
                if (userRes.status === 200) {
                    // service to get domain details
                    DomainDetailsService(accessToken, websiteID, dispatch).then(async (res) => {

                        // check if response is ok
                        if (res.status === 200) {

                            handleCloseSurveyFormModal();

                            // navigate to manage plan page
                            navigate('/front/manage-plan');

                            // remove Loading
                            dispatch({
                                type: LOADING,
                                payload: false,
                            });

                            dispatch({
                                type: SET_MESSAGE,
                                payload: {
                                    "toast_message": t("Subscription successfully updated.", { returnObjects: true }),
                                    "toast_type": "success"
                                }
                            });

                        }
                    });

                }
            });
        }
    })
}


// service to cancel plan
const planCancelSubscriptionService = (
    accessToken: string, // access token
    trialPlanID: number, // trial plan id
    websiteID: number, // website id
    dispatch: Function, // function to store redux data
    t: Function, // i18next t function
    surveyFormReason: number, // selected reasons to cancel subscription
    surveyFormReasonText: string, // user inputted reason to cancel subscription
    userPackageId?: number // package id
) => {
    dispatch({
        type: LOADING,
        payload: true,
    });

    // api to cancel plan
    packageCancelSubscriptionApi(accessToken, trialPlanID, websiteID, surveyFormReason, surveyFormReasonText, userPackageId).then(async (response) => {
        // check if response is ok
        if (response.status === 200) {
            ReactGA.event({
                category: "event",
                action: "click",
                label: "Cancel Subscription Added - All In One Accessibility", // optional
            }, {
                params: {
                    page_title: 'Cancel Subscription Save - All In One Accessibility',
                    website_id: websiteID,
                    page_location: '/front/',
                    page_path: '/front/',
                    send_to: 'G-85C9EMDGMJ'
                }
            });


            // service to get user details
            getUserDetailsService(accessToken, dispatch).then(async (userRes) => {
                // check if response is ok
                if (userRes.status === 200) {

                    // service to get domain details
                    DomainDetailsService(accessToken, websiteID, dispatch).then(async (res) => {
                        // check if response is ok
                        if (res.status === 200) {

                            dispatch({
                                type: SET_MESSAGE,
                                payload: {
                                    "toast_message": t('Plan subscription canceled Success Msg'),
                                    "toast_type": 'success'
                                }
                            })

                            dispatch({
                                type: LOADING,
                                payload: false,
                            });

                        }
                    });

                }
            });

        } else {

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Plan subscription canceled Failed Msg'),
                    "toast_type": 'danger'
                }
            })

            dispatch({
                type: LOADING,
                payload: false,
            });
        }
    })
}

export { getSurveyDataService, acceptOfferService, planCancelSubscriptionService }