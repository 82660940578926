const baseURL = `${process.env.REACT_APP_BASE_URL}/api/websites`

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

// API to update widget settings
const updateWidgetSettingsApi = async (
    token: string, // access token
    websiteID: number, // website ID
    changeWidgetIconType: number, // changed icon type
    isChangeWidgetIconCustomSize: boolean, // flag to change widget icon size to custom
    changeWidgetCustomIconSize: number, // changed custom icon size
    isShowCustomWidgetIconSizeMobile: boolean, // flag to show custom widget icon size - for mobile
    changeWidgetIconSizeMobile: number, // changed icon size - for mobile
    isChangeWidgetIconCustomSizeMobile: boolean, //flag to change widget icon size to custom - for mobile
    changeWidgetIconCustomSizeMobile: number, // changed custom icon size
    changeWidgetIconSize: number, // changed widget icon size for desktop
    changeWidgetIconColor: string, // changed widget icon color for desktop
    changeWidgetIconPosition: string, // changed widget icon position
    isChangeWidgetIconCustomPosition: boolean, // flag to use custom icon position
    changeWidgetIconCustomLeftPosition: number | null, // left position
    changeWidgetIconCustomRightPosition: number | null, // right position
    changeWidgetIconCustomTopPosition: number | null, // top position
    changeWidgetIconCustomBottomPosition: number | null, // bottom position
    changeWidgetSize: number, // widget size
    changeWidgetAccessibilityStatementLink: string, // accessibility statement link
    changeGaID: string, // GA Id
    changeAdobeID: string, // adobe Id
    defaultLanguage: string, // default language for widget
    defaultVoice: string, // default screen reader voice
): Promise<any> => {

    const url = `${baseURL}/widget-setting-update`;

    const data = new FormData();

    data.append("id", websiteID.toString());
    data.append("widget_icon_type", changeWidgetIconType.toString());
    data.append("widget_icon_size", changeWidgetIconSize.toString());
    data.append("is_widget_custom_size", (isChangeWidgetIconCustomSize ? `1` : `0`));


    isChangeWidgetIconCustomSize && data.append("widget_icon_size_custom", changeWidgetCustomIconSize.toString());

    data.append("is_mobile_icon", (isShowCustomWidgetIconSizeMobile ? `1` : `0`));

    // check if showing custom size for mobile
    if (isShowCustomWidgetIconSizeMobile) {
        data.append("widget_icon_size_mobile", changeWidgetIconSizeMobile.toString())
        data.append("is_widget_custom_size_mobile", (isChangeWidgetIconCustomSizeMobile ? `1` : `0`))

        // check if custom size flag for mobile is available
        if (isChangeWidgetIconCustomSizeMobile) {
            data.append("widget_icon_size_custom_mobile", changeWidgetIconCustomSizeMobile.toString())
        }
    }
    
    data.append("widget_color_code", changeWidgetIconColor);
    data.append("widget_position", changeWidgetIconPosition);
    data.append("is_widget_custom_position", (isChangeWidgetIconCustomPosition ? `1` : `0`));
    data.append("widget_position_top", changeWidgetIconCustomTopPosition !== null ? changeWidgetIconCustomTopPosition.toString() : ``);
    data.append("widget_position_bottom", changeWidgetIconCustomBottomPosition !== null ? changeWidgetIconCustomBottomPosition.toString() : ``);
    data.append("widget_position_right", changeWidgetIconCustomRightPosition !== null ? changeWidgetIconCustomRightPosition.toString() : ``);
    data.append("widget_position_left", changeWidgetIconCustomLeftPosition !== null ? changeWidgetIconCustomLeftPosition.toString() : ``);
    data.append("widget_size", changeWidgetSize.toString());
    data.append("statement_link", changeWidgetAccessibilityStatementLink);
    data.append("ga_key", changeGaID);
    data.append("adobe_key", changeAdobeID);
    data.append("default_language", defaultLanguage);
    data.append("default_voice", defaultVoice);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...headers,
                "Authorization": "Bearer " + token
            },
            body: data
        });
        return await response.json();
    } catch (error) {
        return Promise.reject;
    }
}

export { updateWidgetSettingsApi }