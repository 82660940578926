import { Button, Form } from "react-bootstrap";
import UserActionPagesTemplate from "../../Template/UserActionPagesTemplate";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { autoLoginService } from "../../Services/UserAction/AutoLoginService";

const baseURL = `${process.env.REACT_APP_BASE_URL}`

// page to handle auto login
const AutoLoginPage = () => {

    // Redux Data
    const { activeLanguage } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useParams(); //get access token from parameters

    // Auto Login UseEffect
    useEffect(() => {
        if(token) {
            // call auto login service on component mount
            autoLoginService(token, dispatch, navigate, t, activeLanguage?.code);
        }
    },[])

    return (
        <>
            <UserActionPagesTemplate>
                <>
                    <h1>{t('login form title')}</h1>
                    <Form>
                        <Form.Group className="form-group" controlId="user_email">
                            <Form.Label>{t('form email filed label')}</Form.Label>
                            <Form.Control type="email" size="lg" />
                        </Form.Group>
                        <Form.Group className="form-group" controlId="user_password">
                            <Form.Label>{t('form password filed label')}</Form.Label>
                            <Form.Control type="Password" size="lg" />
                            <Form.Text as={`div`} id="passwordHelpBlock" className="aioa_form-password-info-text">{t('valid password msg')}</Form.Text>
                        </Form.Group>
                        <Button variant="primary" type="submit" size="lg" className="w-100">{t('submit button label')}</Button>
                    </Form>

                    <div className="aioa_dashboard-login-link">
                        <Link to="/front/forget-password" className="aioa_forgot-password-link"> {t("lost password label")}</Link>
                        <Link to={`${baseURL}/trial-subscription`} className="aioa_free-trial-link">{t('free trial btn label')}</Link>
                    </div>
                </>
            </UserActionPagesTemplate>

        </>
    )
}

export default AutoLoginPage;